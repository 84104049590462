import React from 'react';
import {
  Wrapper,
  Container,
  StyledCardSkeleton,
  StyledCardSkeletonTitle,
  WrapperTitleSkeleton,
  WrapperDashboardSkeleton,
  StyledCardSkeletonDashboard,
  WrapperFooterDashboard,
  WrapperSecondTitle,
  WrapperTitle,
} from './style';

const DashboardSkeleton = () => {
  return (
    <Wrapper>
      <StyledCardSkeleton width="88px" height="100%" />
      <Container>
        <WrapperTitle>
          <WrapperTitleSkeleton>
            <StyledCardSkeletonTitle width="60%" height="32px" />
            <StyledCardSkeletonTitle width="100%" height="24px" />
          </WrapperTitleSkeleton>
          <WrapperSecondTitle>
            <StyledCardSkeletonTitle width="60%" height="32px" />
            <StyledCardSkeletonTitle width="100%" height="24px" />
          </WrapperSecondTitle>
        </WrapperTitle>
        <WrapperDashboardSkeleton>
          <StyledCardSkeletonDashboard width="100%" height="50vh" />
          <StyledCardSkeletonDashboard width="100%" height="50vh" />
        </WrapperDashboardSkeleton>
        <WrapperFooterDashboard>
          <StyledCardSkeletonDashboard width="100%" height="35vh" />
        </WrapperFooterDashboard>
      </Container>
    </Wrapper>
  );
};

export default DashboardSkeleton;
