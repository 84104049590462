import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 3.8rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border: 1px solid #f6f6f6;
  border-radius: 12px;
  box-shadow: 0px 12px 22px rgba(22, 37, 77, 0.04);
  background: #fff;
  padding: 0 1rem;
  margin-top: 1rem;

  justify-items: start;
  align-items: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.hover};
  }
`;

export const Label = styled.span`
  font-size: 0.625rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.translucentText};
`;

export const NumberContent = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.inactiveText};
`;

export const ColorContent = styled.div`
  background: ${({ color }) => color};
  width: 3rem;
  height: 1.125rem;
`;

export const Icons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  cursor: pointer;
  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;
