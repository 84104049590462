import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Assets
import { ReactComponent as Close } from '@assets/svg/icons/close.svg';

export const Wrapper = styled(motion.div)`
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  position: absolute;
  z-index: 3;
`;

export const WrapperTop = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 20px;
`;

export const Title = styled.span`
  font-size: 25px;
  font-weight: bolder;
  color: ${({ theme }) => theme.colors.mainText};
`;

export const CloseButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

export const CloseIcon = styled(Close)`
  & {
    width: 12px;
    height: 12px;
  }
`;

export const WrapperOption = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0;
`;

export const LineTitle = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 10px;
  text-decoration: none;

  svg path {
    stroke: ${(props) => props?.stroke};
    fill: ${(props) => props?.fill};
  }
`;

export const TitleOption = styled.p`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 700;
  margin-left: 15px;
`;

export const WrapperSubSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 5px;
`;

export const TitleSubSection = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 600;
  margin-left: 20px;
`;

export const Point = styled.div`
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.colors.mainText};
  border-radius: 100%;
`;
