import React from 'react';

// Styles
import { Container } from './style';

const MenuButton = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

export default MenuButton;
