import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

// Style
import {
  Wrapper,
  BlurredBackground,
  StyledCloseIcon,
  CloseButton,
  WrapperTitle,
} from './style';

const RightDrawer = ({
  open,
  handleClose,
  componentTitle,
  children,
  isFromUdps,
  mapComponent,
  isMobile,
  openMap,
  notBlurred,
}) => {
  return (
    <>
      {open && !notBlurred && (
        <BlurredBackground onClick={handleClose} open={open} />
      )}
      <AnimatePresence>
        {open && (
          <Wrapper
            key="rightDrawer"
            initial={{ x: 400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 400, opacity: 0 }}
            style={{
              // eslint-disable-next-line no-nested-ternary
              width: isMobile ? '100%' : isFromUdps ? 'auto' : '500px',
              display: isFromUdps ? 'flex' : '',
            }}
          >
            {openMap && !isMobile && mapComponent}
            <div
              style={{
                height: '100%',
              }}
            >
              <WrapperTitle>
                <CloseButton onClick={handleClose}>
                  <StyledCloseIcon />
                </CloseButton>
                {componentTitle}
              </WrapperTitle>
              {children}
            </div>
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
};

RightDrawer.defaultProps = {
  open: false,
  handleClose: () => {},
  title: 'Sem título',
  mapComponent: false,
  openMap: false,
};

export default RightDrawer;
