/* eslint-disable import/no-unresolved */
import * as togeojson from '@mapbox/togeojson';
import rewind from '@mapbox/geojson-rewind';

export const formatNumberToString = (number) => {
  let newNumber = number;

  if (typeof newNumber === 'string' && newNumber.includes(',')) {
    newNumber = newNumber.replace(/,/g, '.');
  }

  newNumber = Number(newNumber).toFixed(2);

  if (newNumber === 'NaN') {
    return number;
  }
  return newNumber;
};

export const formatStringToNumber = (string) => {
  if (string.includes(',')) {
    return Number(string.replace(',', '.')).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return Number(string).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatNumber = (number, isSoilFertilizer = false) => {
  let newNumber = isSoilFertilizer ? number.toString() : number;

  newNumber = newNumber.replace(/\./g, '');
  newNumber = newNumber.replace(/,/g, '.');

  return newNumber;
};

export const nameFormat = (name) => {
  let newName = name;

  newName = name
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, (capitalize) => capitalize.toUpperCase());

  return newName;
};

export const editFormat = (number) => {
  let newNumber = number;
  newNumber = newNumber.toString();

  if (newNumber.includes('.')) {
    return newNumber.replace('.', ',');
  }
  return newNumber;
};

export const formatErrors = (error) => {
  if (typeof error?.response?.data === 'string') return error.response.data;

  return Object.keys(error?.response?.data?.errors).map((key) => [
    key,
    error.response.data.errors[key],
  ]);
};

export const formatObjectForInputRange = ({ object, keyValue, keyLabel }) => {
  return {
    value: object[keyValue || 'id'],
    label: object[keyLabel || 'nome'],
  };
};

export const formatArrayForInputRange = ({ array, keyValue, keyLabel }) => {
  if (!array) return [];

  return array.map((item) => {
    return formatObjectForInputRange({ object: item, keyValue, keyLabel });
  });
};

export const formatMessagesErrors = (error) => {
  const errors = formatErrors(error);

  if (error.message === 'Request failed with status code 500') {
    const formatError = errors.map((item) => item[1]);
    return [formatError.toString().replace(/,/g, '')];
  }

  const formatError =
    typeof errors === 'string'
      ? [errors]
      : errors.map((item) => item[1].message || item[1]);

  return formatError;
};

export const getFileExtension = (file) => {
  const { name } = file;
  const lastDot = name.lastIndexOf('.');
  return name.substring(lastDot + 1);
};

export const parseXmlToKml = (xml) => {
  const dom = new DOMParser().parseFromString(xml, 'text/xml');
  const geojsonConverted = togeojson.kml(dom);
  rewind(geojsonConverted, false);
  return geojsonConverted;
};
