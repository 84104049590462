import React, { useState, useCallback } from 'react';

// Theme
import theme from '@styles/theme';

// Styles
import * as S from './style';

const Select = ({
  height,
  width,
  label,
  fontSize,
  fontSizeLabel,
  error,
  helperText,
  type,
  background,
  hasBorder,
  endIcon,
  endIconWidth,
  endIconHeight,
  onClick,
  data,
  hasLabel,
  dataName,
  isAnalysisTable,
  isYearsAndPhase,
  isCollectionsResume,
  isNone,
  ...props
}) => {
  return (
    <S.Wrapper width={width}>
      {hasLabel && (
        <S.Label fontSizeLabel={fontSizeLabel}>
          {label}{' '}
          {error && (
            <S.Error fontSizeLabel={fontSizeLabel}>*{helperText}</S.Error>
          )}
        </S.Label>
      )}
      <S.SelectWrapper>
        <S.StyledSelect
          {...props}
          background={background}
          height={height}
          fontSize={fontSize}
          hasBorder={hasBorder}
          isCollectionsResume={isCollectionsResume}
        >
          {isNone && (
            <option key={1} value={0}>
              Selecione um cliente
            </option>
          )}
          {isAnalysisTable && (
            <option key={1} value={0}>
              TODOS
            </option>
          )}
          {data.map((item, idx) => {
            return (
              <option
                key={`${item.nome}-${item.id}` || idx + 2}
                value={item.id || item.fase}
              >
                {item.nome || item.name || item.fase || item.ano || item.zone}
              </option>
            );
          })}
        </S.StyledSelect>

        {endIcon && (
          <S.EndIconWrapper
            endIconWidth={endIconWidth}
            endIconHeight={endIconHeight}
            type="button"
            onClick={onClick}
          >
            {endIcon}
          </S.EndIconWrapper>
        )}
      </S.SelectWrapper>
    </S.Wrapper>
  );
};

Select.defaultProps = {
  height: '3.5rem',
  // width: '22.5rem',
  label: 'Senha',
  fontSize: '1rem',
  fontSizeLabel: '1rem',
  error: false,
  helperText: '',
  type: 'text',
  background: theme.colors.white,
  hasBorder: true,
  endIcon: false,
  endIconWidth: '16px',
  endIconHeight: '16px',
  hasLabel: true,
  isCollectionsResume: false,
  isNone: false,
  onClick: () => {},
};

export default Select;
