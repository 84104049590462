import React from 'react';
import Lottie from 'react-lottie-player';

// Assets
import WhiteLoading from '@assets/lottie/white-loading.json';

// Theme
import theme from '@styles/theme';

// Styles
import { Button } from './style';

const ButtonGradient = ({
  children,
  height,
  width,
  fontSize,
  colorGradient1,
  colorGradient2,
  fontColor,
  fontWeight,
  isLoading,
  ...props
}) => {
  return (
    <Button
      colorGradient1={colorGradient1}
      colorGradient2={colorGradient2}
      height={height}
      width={width}
      fontSize={fontSize}
      fontColor={fontColor}
      fontWeight={fontWeight}
      {...props}
    >
      {isLoading ? (
        <Lottie
          loop
          animationData={WhiteLoading}
          play
          speed={2.8}
          style={{ width: '40px', height: '40px' }}
        />
      ) : (
        children
      )}
    </Button>
  );
};

ButtonGradient.defaultProps = {
  fontWeight: '700',
  fontColor: theme.colors.white,
  boxShadow: 'rgba(255, 46, 0, 0.23)',
  colorGradient1: theme.colors.soilOrange,
  colorGradient2: theme.colors.darkYellow,
  height: '3.5rem',
  width: '22.5rem',
  fontSize: '1.125rem',
};

export default ButtonGradient;
