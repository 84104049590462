import React, { useRef, useEffect, useState } from 'react';

import { Wrapper, StyledInput, Label, Error, WrapperInputBox } from './style';

const KEY_CODE = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  DELETE: 46,
};

const InputCodeVerification = ({
  numberOfDigits,
  autoFocus,
  allowArrowsKeys,
  width,
  height,
  fontSize,
  value: string,
  handleChange,
  fontSizeLabel,
  label,
  error,
  helperText,
  ...props
}) => {
  const ref = useRef([]);
  const [activeIdx, setActiveIdx] = useState(0);

  const getRef = (idx) => ref.current[idx];

  const changeLetterFromIndex = (idx, value) => {
    const newString = [...string];
    newString[idx] = value;
    handleChange(props.id, newString);
  };

  const changeFocus = (newIdx, isBackspaceCommand = false) => {
    if (newIdx < 0 || newIdx > numberOfDigits - 1) return;

    // Blur actual input
    getRef(activeIdx).blur();

    // Go to newIdx input
    const input = getRef(newIdx);
    input.focus();

    if (isBackspaceCommand) {
      const backup = input.value;
      input.value = '';
      input.value = backup;
      input.setSelectionRange(0, input.value.length);
    }

    setActiveIdx(newIdx);
  };

  const goBackIfInputIsEmpty = (idx) => {
    if (idx <= 0 || idx > numberOfDigits - 1) return;

    changeLetterFromIndex(idx, '');
    changeFocus(idx - 1, true);
  };

  const goToNextInput = (value, idx) => {
    changeLetterFromIndex(idx, value);

    if (idx + 1 > numberOfDigits - 1) return;
    changeFocus(idx + 1);
  };

  const handleKeyDown = ({ key, keyCode }) => {
    switch (keyCode) {
      case KEY_CODE.ARROW_RIGHT:
        if (allowArrowsKeys) {
          changeFocus(activeIdx + 1);
        }
        break;
      case KEY_CODE.ARROW_LEFT:
        if (allowArrowsKeys) {
          changeFocus(activeIdx - 1);
        }
        break;
      case KEY_CODE.BACKSPACE:
        if (getRef(activeIdx).value === '') {
          goBackIfInputIsEmpty(activeIdx);
        } else {
          changeLetterFromIndex(activeIdx, '');
        }
        break;
      default:
        break;
    }
  };

  const handleChangeCode = (idx) => (e) => {
    if (e.target.value !== '') {
      goToNextInput(e.target.value, idx);
    }
  };

  useEffect(() => {
    if (autoFocus && getRef(0)) {
      getRef(0).focus();
    }
  }, []);

  return (
    <Wrapper>
      <Label fontSizeLabel={fontSizeLabel}>
        {label}{' '}
        {error && (
          <Error fontSizeLabel={fontSizeLabel}>
            *{helperText[helperText.length - 1]}
          </Error>
        )}
      </Label>
      <WrapperInputBox>
        {Array(numberOfDigits)
          .fill()
          .map((item, idx) => (
            <StyledInput
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              ref={(refElement) => {
                ref.current[idx] = refElement;
              }}
              value={string[idx]}
              type="text"
              maxLength={1}
              onChange={handleChangeCode(idx)}
              onKeyDown={handleKeyDown}
              onBlur={() => getRef(activeIdx).blur()}
              onClick={() => {
                setActiveIdx(idx);
              }}
              onFocus={(e) => {
                e.currentTarget.setSelectionRange(
                  0,
                  e.currentTarget.value.length
                );
              }}
              height={height}
              width={width}
              fontSize={fontSize}
              {...props}
            />
          ))}
      </WrapperInputBox>
    </Wrapper>
  );
};

InputCodeVerification.defaultProps = {
  numberOfDigits: 6,
  autoFocus: true,
  allowArrowsKeys: false,
  width: '60px',
  height: '60px',
  fontSize: '1.5rem',
  value: '',
  handleChange: () => {},
  label: 'Código',
  error: false,
  helperText: '',
  fontSizeLabel: '1rem',
};
export default InputCodeVerification;
