import React from 'react';

// Styles
import {
  Wrapper,
  StyledInput,
  Label,
  Error,
  InputWrapper,
  StyledPicture,
  TextInput,
  Image,
  ImageName,
} from './style';

const Input = ({
  height,
  width,
  placeholder,
  fontSize,
  fontSizeLabel,
  error,
  helperText,
  type,
  background,
  hasBorder,
  id,
  name,
  value,
  imageUrl,
  onChange,
  acceptRange,
  InputText,
  ...props
}) => {
  const handleChange = (e) => {
    onChange(e.target.files[0] || value);
  };

  return (
    <Wrapper width={width}>
      {error && (
        <Label fontSizeLabel={fontSizeLabel}>
          <Error fontSizeLabel={fontSizeLabel}>*{helperText}</Error>
        </Label>
      )}
      <InputWrapper htmlFor={id}>
        {value?.name && <ImageName>{value.name}</ImageName>}
        {imageUrl ? <Image src={imageUrl} /> : <StyledPicture />}
        <StyledInput
          background={background}
          fontSize={fontSize}
          placeholder={placeholder}
          hasBorder={hasBorder}
          type="file"
          id={id}
          name={name}
          onChange={handleChange}
          accept={acceptRange || 'image/png, image/jpeg'}
          {...props}
        />
        <TextInput>
          {InputText || 'clique aqui ou arraste para enviar uma foto'}
        </TextInput>
      </InputWrapper>
    </Wrapper>
  );
};

export default Input;
