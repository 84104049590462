import React, { createContext, useState } from 'react';
import { area } from '@turf/turf';
import { useSnackbar } from 'notistack';
import apiRecommendations from '@services/apiRecommendations';

import api from '@services/api';

import useRecommendation from '@hooks/useRecommendation';
import { formatMessagesErrors } from '@utils/index';

export const CustomizedRecommendationsContext = createContext();

const CustomizedRecommendationsProvider = ({ children }) => {
  const [culture, setCulture] = useState();
  const [productivity, setProductivity] = useState();
  const [newRecommendation, setNewRecommendation] = useState();
  const [step2Data, setStep2Data] = useState([]);
  const [step3Data, setStep3Data] = useState([]);
  const [clientSelected, setClientSelected] = useState('');
  const [typeSelected, setTypeSelected] = useState('');

  const [supply1Price, setSupply1Price] = useState(0);
  const [supply2Price, setSupply2Price] = useState(0);
  const [gessoPrice, setGessoPrice] = useState(0);

  const [step4Data, setStep4Data] = useState([]);

  const [sum, setSum] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const [clientData, setClientData] = useState();

  // const [valuesCaMgForRow, setValuesCaMgForRow] = useState({});

  const {
    alvoCa,
    alvoMg,
    valuesCaMgForRow,
    valueInsumo1,
    valueInsumo2,
    valueGesso,
  } = useRecommendation();

  const calculateArea = (value) => {
    const result = area(value);
    const resultInAcre = result / 10000;
    return resultInAcre.toFixed(2);
  };

  const handleGetClient = async () => {
    try {
      const { data } = await api.get(`/Client/GetById/${clientSelected}`);
      setClientData(data);

      return data;
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    }
    return [];
  };

  const submitValuesStep4 = async () => {
    try {
      const clientInfo = await handleGetClient();
      const insumos = [];
      step3Data.forEach((item) => {
        insumos.push({
          id: item.id,
          nome: `${item.nome}`,
          tipo: 'corretive',
        });
      });

      const objectTeste = {
        idCliente: Number(clientSelected),
        nome: clientInfo.nome,
        tipo: clientInfo.tipoPessoa,
        data: clientInfo.dataCriacao,
        targets: [
          {
            elemento: 'Ca',
            valor: alvoCa,
          },
          {
            elemento: 'Mg',
            valor: alvoMg,
          },
        ],
        idCultura: null,
        cultura: null,
        safra: null,
        insumos,
        zonas: step2Data.map((item) => ({
          id: item.geoJson[0]?.properties.idZonaManejo,
          nome: item.geoJson[0]?.properties.zonaManejo,
          idFazenda: item.geoJson[0]?.properties.idFazenda,
          fazenda: item.geoJson[0]?.properties.fazenda,
          idTalhao: null,
          talhao: null,
          area: item.area.toString(),
          resultadoAnalises: {
            data: item.dts,
            profundidades: item?.selectedDepth?.map((depth) => ({
              id: depth.id,
              prof: depth.nome,
            })),
          },
          recomendacaoResultados: {
            insumosResultadoAnalises: step3Data.map((step, idx) => {
              return {
                id: 0,
                nome: step.nome,
                tipo: step3Data[0]?.type,
                valor: 0,
                dose: 0,
                resultados: [
                  {
                    elemento: 'ca',
                    valor: valueInsumo1,
                  },
                  {
                    elemento: 'mg',
                    valor: valueInsumo2,
                  },
                ],
              };
            }),
          },
        })),
        totais: {
          insumosTotal: step3Data.map((item, idx) => {
            return {
              id: item.id,
              nome: item.nome,
              tipo: step3Data[0]?.type,
              valorTonelada: idx + 1 === 1 ? supply1Price : supply2Price,
              quantidade: idx + 1 === 1 ? valueInsumo1 : valueInsumo2,
            };
          }),
          valorTotal: sum,
        },
      };
      const { data } = await api.post(
        '/RecommendationsAnalysis/Create',
        objectTeste
      );
      if (data) {
        enqueueSnackbar('Salvo com sucesso', {
          variant: 'success',
        });
      }

      return true;
    } catch (error) {
      return false;
    }
  };
  return (
    <CustomizedRecommendationsContext.Provider
      value={{
        clientSelected,
        setClientSelected,
        culture,
        setCulture,
        productivity,
        setProductivity,
        newRecommendation,
        setNewRecommendation,
        setStep2Data,
        step2Data,
        setStep3Data,
        step3Data,
        calculateArea,
        supply1Price,
        setSupply1Price,
        supply2Price,
        setSupply2Price,
        gessoPrice,
        setGessoPrice,
        sum,
        setSum,
        step4Data,
        setStep4Data,
        submitValuesStep4,
        typeSelected,
        setTypeSelected,
      }}
    >
      {children}
    </CustomizedRecommendationsContext.Provider>
  );
};

export default CustomizedRecommendationsProvider;
