import styled, { css } from 'styled-components';
// eslint-disable-next-line import/no-cycle
import { Modal } from '@components/index';
import Button from '../Button';

export const Wrapper = styled.form`
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10px;
  padding: 20px;
  padding-top: 90px;
  @media (max-width: 480px) {
    height: calc(100% - 70px);
  }
`;

export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  ${(props) =>
    props.isColorPicker &&
    css`
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    `}
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 700;
  align-self: center;
  font-size: 1.2rem;
`;

export const ListOfScales = styled.div`
  margin-top: 0.5rem;
`;

export const ButtonAdd = styled(Button)`
  margin-top: 1rem;
`;

export const StyledModal = styled(Modal)`
  @media (max-width: 860px) {
    width: 100vw;
    left: 55%;
  }
  @media (max-width: 640px) {
    left: 56%;
  }
  @media (max-width: 580px) {
    width: 75vw;
    left: 57%;
  }
  @media (max-width: 480px) {
    top: 45%;
    width: 80vw;
    left: 50%;
  }
`;
