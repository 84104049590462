import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.fadedText};
  font-size: ${(props) => props.fontSizeLabel};
  font-weight: 400;
`;

export const StyledInput = styled.input`
  background: ${({ theme }) => theme.colors.mediumGray};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  text-align: center;
  font-size: ${(props) => props.fontSize};
  color: ${({ theme }) => theme.colors.border};
  margin-top: 12px;
  transition: 0.2s linear;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.gray};
  }

  @media (max-width: 720px) {
    height: 50px;
    width: 50px;
  }

  @media (max-width: 475px) {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media (max-width: 380px) {
    &:not(:last-child) {
      margin-right: 4px;
    }
    height: 40px;
    width: 40px;
  }

  @media (max-width: 290px) {
    &:not(:last-child) {
      margin-right: 2px;
    }
    height: 30px;
    width: 30px;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.soilOrange};
  font-size: ${(props) => props.fontSizeLabel};
  margin-bottom: 10px;
  font-weight: 500;
`;

export const WrapperInputBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
