import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { SnackbarProvider } from 'notistack';

// Context
import CollectionsProvider from '@context/Collections';
import AuthContext from '@context/Auth';
import RecommendationProvider from '@context/Recommendation';
import CustomizedRecommendationsProvider from '@context/customizedRecommendations';
import SoilAnalysisIdProvider from '@context/SoilAnalysisId';
import SuppliesProvider from '@context/Supplies';
import { TalhaoProvider } from './context/Talhao';

import App from './App';

// Router

const Root = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <SnackbarProvider maxSnack={3}>
        <AuthContext>
          <TalhaoProvider>
            <RecommendationProvider>
              <CustomizedRecommendationsProvider>
                <CollectionsProvider>
                  <SoilAnalysisIdProvider>
                    <SuppliesProvider>
                      <Routes location={location} key={location.pathname}>
                        <Route path="*" element={<App />} />
                      </Routes>
                    </SuppliesProvider>
                  </SoilAnalysisIdProvider>
                </CollectionsProvider>
              </CustomizedRecommendationsProvider>
            </RecommendationProvider>
          </TalhaoProvider>
        </AuthContext>
      </SnackbarProvider>
    </AnimatePresence>
  );
};

export default Root;
