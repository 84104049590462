import styled from 'styled-components';

import { ReactComponent as DetailPercentage } from '@assets/svg/icons/green_polygon.svg';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WrapperPorcentage = styled.div`
  display: flex;
  align-items: center;
`;

export const SubtitleContent = styled.div`
  display: flex;
  gap: 6px;
`;

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.fadedText};
`;

export const StyledDetailPorcentage = styled(DetailPercentage)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  transform: ${({ percentage }) =>
    percentage < 0 ? 'rotate(180deg)' : 'none'};

  path {
    fill: ${({ percentage, theme }) =>
      percentage < 0 ? 'red' : theme.colors.mountainGreen};
  }
`;

export const TextPercentage = styled.p`
  color: ${({ theme, percentage }) =>
    percentage < 0 ? 'red' : theme.colors.mountainGreen};
  font-size: 1.5rem;
  font-weight: bolder;
  letter-spacing: -1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
