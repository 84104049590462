import React, { useEffect } from 'react';

// Theme
import theme from '@styles/theme';

// Components
// eslint-disable-next-line import/no-cycle
import { Input, Button } from '@components/index';

// Styles
import { ButtonsWrapper } from './style';

export const ModalSearch = ({
  handleSearch,
  handleClose,
  handleLoadNewData,
  handleResetListOfData,
  setIsSearching,
  isSearching,
  search,
  setSearch,
}) => {
  const handleChange = (e) => {
    const newString = search + e.target.value;
    setSearch(newString);
  };

  const handleSubmitSearch = async () => {
    if (search.length > 0) {
      setIsSearching(true);
      await handleSearch();
      handleClose();
    } else if (isSearching) {
      handleResetListOfData();
      setIsSearching(false);
      handleLoadNewData();
    }
  };

  useEffect(() => {
    return () => setSearch('');
  }, []);

  return (
    <>
      <Input
        hasBorder={false}
        background={theme.colors.lightGray}
        height="40px"
        width="100%"
        label=""
        placeholder="pesquise por cidade, nome, CNPJ..."
        value={search}
        onChange={handleChange}
      />

      <ButtonsWrapper>
        <Button width="100%" height="40px" onClick={handleSubmitSearch}>
          Pesquisar
        </Button>
      </ButtonsWrapper>
    </>
  );
};
