import React, { useRef, useState, useEffect } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from '@services/MapBox';
import { StyledMap } from './style';
import Card from '../Card';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const MapDrawComponent = ({
  handleChange,
  getMapDrawFunctions,
  handleOpenTalhaoNameModal,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  const mapDraw = new MapboxDraw({
    controls: {
      point: false,
      line_string: true,
      polygon: true,
      trash: true,
      combine_features: true,
      uncombine_features: true,
    },
    userProperties: true,
  });

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/thesoilcompany/cl1cxlp02000216p8pno155y7',
      center: [lng, lat],
      zoom,
    });

    getMapDrawFunctions(mapDraw);

    map.current.addControl(mapDraw, 'top-left');
    map.current.addControl(
      new MapboxGeocoder({
        accessToken:
          'pk.eyJ1IjoidGhlc29pbGNvbXBhbnkiLCJhIjoiY2wxYzhka3Z2MDUyZDNqcDRtMDJxazNtMSJ9.JolTtrDqRlYrxYOLqe_TCg',
        mapboxgl,
      })
    );
  }, []);

  useEffect(() => {
    map.current.on('draw.create', (e) => {
      const { features } = mapDraw.getAll();

      handleChange(features);
      handleOpenTalhaoNameModal();
    });

    map.current.on('draw.combine', () => {
      const { features } = mapDraw.getAll();
      handleChange(features);
    });

    map.current.on('draw.uncombine', (e) => {
      const { features } = mapDraw.getAll();

      handleChange(features);
    });

    map.current.on('draw.delete', () => {
      const { features } = mapDraw.getAll();

      handleChange(features);
    });
  }, []);

  return (
    <Card
      width="100%"
      height="calc(100% - 10px)"
      style={{ marginBottom: '0' }}
      padding="0px"
    >
      <StyledMap ref={mapContainer} height="100%" />
    </Card>
  );
};

MapDrawComponent.defaultProps = {
  handleChange: () => {},
  getMapDrawFunctions: () => {},
  handleOpenTalhaoNameModal: () => {},
};

export default MapDrawComponent;
