import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${({ widthContainer }) => widthContainer};
  display: flex;
  flex-direction: column-reverse;
  transform: ${({ isVertical }) => (isVertical ? 'rotate(90deg)' : '')};
  transform-origin: center left;
`;

export const ULStyled = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 7px;
`;

export const LIStyled = styled.li`
  display: inline-block;
  color: ${({ theme }) => theme.colors.inactiveText};
  font-weight: bold;
  white-space: nowrap;
  transform-origin: center left;
  margin-top: ${({ isVertical }) => (!isVertical ? '5px' : '0')};
  margin-left: ${({ isVertical }) => (isVertical ? '5px' : '0')};
  width: ${({ isVertical }) => (isVertical ? '10px' : 'unset')};
`;

export const LIStyledText = styled.p`
  transform: ${({ isVertical }) => (isVertical ? 'rotate(-90deg)' : '0')};
`;

export const StyledRange = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 15px;

  background: white;
  border-radius: 8px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.soilOrange};
    cursor: pointer;
  }
`;
