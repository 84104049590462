/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { Select, Wrapper, SelectOptionsBox, OptionCard } from './style';

export const MultiSelect = ({
  optionData,
  valueData,
  setValueData,
  setValueIDs,
  height,
  width,
  label,
  placeholder,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState('');
  const spaceTop = height + 25;

  const handleSelect = (optionValue) => {
    if (valueData.includes(optionValue) && valueData.length > 1) {
      setValueData((prevState) =>
        prevState.filter((currentValue) => currentValue !== optionValue)
      );
    } else if (!valueData.includes(optionValue)) {
      setValueData((prevState) => [...prevState, optionValue]);
    }
  };

  const handleOpenSelect = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const verifyIndexPosition = (index) => {
    if (optionData.length === 1) {
      return 'unique-child';
    }
    if (index === 0) {
      return 'first-child';
    } else if (index === optionData.length - 1) {
      return 'last-child';
    }
  };

  useEffect(() => {
    if (optionData) {
      if (!valueData.length && !placeholder) {
        setCurrentLabel('Label');
      } else if (valueData.length) {
        const getValues = valueData.map((option) => option.label);
        setCurrentLabel(getValues.join(', '));
      } else if (placeholder) {
        setCurrentLabel(placeholder);
      }
    }
  }, [optionData, valueData]);

  useEffect(() => {
    if (valueData) {
      setValueIDs(valueData.map((data) => data.value));
    }
  }, [valueData]);

  // useEffect(() => {
  //   if (valueData && valueData.length) {

  //   }
  // }, []);

  return (
    <Wrapper width={width}>
      <abbr title={label || 'Label'} className="topLabel">
        {label || 'Label'}
      </abbr>
      <Select
        className={modalIsOpen && 'focusedSelect'}
        height={height}
        onClick={() => handleOpenSelect()}
      >
        <abbr title={currentLabel} className="selectedValues">
          {currentLabel}
        </abbr>
        <RiArrowDropDownLine size="25px" />
      </Select>
      {modalIsOpen && (
        <SelectOptionsBox spaceTop={spaceTop}>
          {optionData.length > 0 &&
            optionData.map((option, index) => {
              const isActive = valueData?.includes(option);
              return (
                <OptionCard
                  isActive={isActive}
                  onClick={() => handleSelect(option)}
                  className={verifyIndexPosition(index)}
                >
                  {option.label}
                </OptionCard>
              );
            })}
        </SelectOptionsBox>
      )}
    </Wrapper>
  );
};
