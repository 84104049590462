import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import * as S from './style';

const ColorPicker = ({ color, handleChange, width }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <S.Container width={width}>
      <S.Label>Cor</S.Label>
      <S.Swatch type="button" onClick={handleClick}>
        <S.Color color={color} />
      </S.Swatch>
      {displayColorPicker ? (
        <S.Popover isScale>
          <S.Cover type="button" onClick={handleClose} />
          <SketchPicker
            color={color}
            onChange={(colorValue) => handleChange(colorValue)}
          />
        </S.Popover>
      ) : null}
    </S.Container>
  );
};

export default ColorPicker;
