import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 500;
  font-size: 1rem;
`;

export const Bar = styled.div`
  width: 100px;
`;
