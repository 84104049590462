import { geoIdentity, geoPath } from 'd3-geo';

export const generateSvgFromGeoJson = (geojson) => {
  const { fill } = geojson.properties;

  const width = 80;
  const height = 80;
  const config = geoIdentity().reflectY(true);

  config.fitSize([width, height], geojson);

  const path = geoPath().projection(config);

  const pathGenerated = path(geojson);

  return {
    path: pathGenerated,
    width,
    height,
    fill,
  };
};
