import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Buffer } from 'buffer';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

// Context
import useAuth from '@hooks/Auth';

// Routes
import { DashboardSkeleton } from '@components/index';
import { DASHBOARD, DONT_REQUIRE_TOKEN, LOGIN } from './routes';

// Router
const TokenRequiredRouter = lazy(() => import('./routes/tokenRequired'));
const TokenNotRequired = lazy(() => import('./routes/tokenNotRequired'));

const App = () => {
  const location = useLocation();
  const { userInfo, setUserInfo, getFromLocalStorage, handleLogout } =
    useAuth();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const authValues = getFromLocalStorage();

    if (authValues) {
      const dateNow = new Date();

      const expireIn = JSON.parse(
        Buffer.from(authValues?.accessToken.split('.')[1], 'base64')
      ).exp;

      if (expireIn < dateNow.getTime() / 1000) {
        handleLogout();
      } else {
        setUserInfo(authValues);
      }
    }

    setAuthenticated(true);
  }, []);

  if (!authenticated) {
    return <DashboardSkeleton />;
  }

  // Se o usuário autenticado estiver tentando acessar uma página que não precisa de token
  // o mesmo é redirecionado para a dashboard
  if (userInfo?.accessToken && DONT_REQUIRE_TOKEN[location?.pathname]) {
    return <Navigate to={DASHBOARD} />;
  }

  if (!userInfo?.accessToken && !DONT_REQUIRE_TOKEN[location?.pathname]) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <Suspense fallback={<DashboardSkeleton />}>
      {userInfo?.accessToken ? <TokenRequiredRouter /> : <TokenNotRequired />}
    </Suspense>
  );
};

export default App;
