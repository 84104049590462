/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/default */
/* eslint-disable import/namespace */
import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { nameFormat, formatMessagesErrors } from '@utils';
import Tooltip from '@mui/material/Tooltip';
import useAuth from '@hooks/Auth';
import api from '@services/api';
import { useSnackbar } from 'notistack';

// Assets
import { ReactComponent as Notification } from '@assets/svg/icons/notification.svg';
import { ReactComponent as Settings } from '@assets/svg/icons/settings.svg';
import { ReactComponent as Account } from '@assets/svg/icons/account.svg';
import { ReactComponent as Farm } from '@assets/svg/icons/farm.svg';
import { ReactComponent as UDP } from '@assets/svg/icons/udp.svg';
import { ReactComponent as SearchIcon } from '@assets/svg/icons/search.svg';
import { ReactComponent as ArrowIcon } from '@assets/svg/arrow.svg';

// Theme
import theme from '@styles/theme';

// Component
import RightDrawer from '../RightDrawer';
import Title from '../Title';
import Subtitle from '../Subtitle';
import MyUser, { HeaderMyUser } from '../MyUser';

// Style
import {
  Wrapper,
  LeftWrapper,
  RightWrapper,
  IconButton,
  ButtonsWrapper,
  Details,
  FarmIcon,
  UDPIcon,
  TextDetails,
  StyledInput,
  WrapperOverTitle,
  DetailsWrapper,
  ImageWrapper,
  FarmDetails,
} from './style';

const Header = ({
  title,
  farm,
  hasInput,
  overTitle,
  marginBottom,
  farmUdps,
  farmArea,
  toFormatTitle = true,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [visibilityRightDrawer, setVisibilityRightDrawer] = useState(false);
  const [urlPicture, setUrlPicture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useAuth();

  const loadUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(`/Users/GetById/${userInfo?.id}`);

      setUrlPicture(data.linkLogotipo);
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const handleRightDrawer = () =>
    setVisibilityRightDrawer((prevState) => !prevState);

  const handleSearch = () => {};

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <>
      <Wrapper marginBottom={marginBottom} farm={farm}>
        <LeftWrapper farm={farm}>
          {overTitle && (
            <WrapperOverTitle>
              <Subtitle fontSize="1rem">{overTitle.first}</Subtitle>
              <ArrowIcon />
              <Subtitle fontSize="1rem" fontWeight="bold">
                {overTitle.second}
              </Subtitle>
            </WrapperOverTitle>
          )}
          {title && (
            <Title
              width={farm ? 'calc(100% - 220px)' : 'auto'}
              letterSpacing="0"
            >
              {toFormatTitle ? nameFormat(title) : title}
            </Title>
          )}
          {farm === true && (
            <DetailsWrapper>
              <Details>
                <FarmIcon>
                  <Farm />
                </FarmIcon>
                <FarmDetails>{farmArea} ha</FarmDetails>
              </Details>
              <Details>
                <UDPIcon>
                  <UDP />
                </UDPIcon>
                <FarmDetails>{farmUdps}</FarmDetails>
              </Details>
            </DetailsWrapper>
          )}
        </LeftWrapper>
        <RightWrapper>
          {hasInput && (
            <StyledInput
              hasBorder={false}
              label=""
              placeholder="pesquise aqui"
              endIcon={<SearchIcon />}
              onClick={handleSearch}
            />
          )}
          <ButtonsWrapper>
            {/* <IconButton>
              <Notification />
            </IconButton> */}
            <IconButton onClick={handleRightDrawer}>
              {urlPicture === null || urlPicture === undefined ? (
                <Account />
              ) : (
                <ImageWrapper src={urlPicture} />
              )}
            </IconButton>
          </ButtonsWrapper>
        </RightWrapper>
      </Wrapper>

      <RightDrawer
        componentTitle={<HeaderMyUser />}
        open={visibilityRightDrawer}
        handleClose={handleRightDrawer}
      >
        <MyUser
          width="100%"
          handleOpenModal={handleOpenModal}
          handleClose={handleRightDrawer}
        />
      </RightDrawer>
    </>
  );
};

Header.defaultProps = {
  title: '',
  marginBottom: '32px',
};

export default Header;
