import React, { useState } from 'react';
import { useFormik } from 'formik';
import Lottie from 'react-lottie-player';

// Assets
import WhiteLoading from '@assets/lottie/white-loading.json';

// Theme
import theme from '@styles/theme';

import ColorPicker from '../../../ColorPicker';

// Components
import Input from '../../../Input';

// Style
// eslint-disable-next-line import/no-cycle
import {
  Wrapper,
  StyledTitle,
  WrapperButtons,
  StyledButton,
  Container,
  ItemContainer,
  NumberContent,
  Label,
  ColorContent,
  InputWrapper,
} from './style';

const ModalEdit = ({
  onCancel,
  onConfirm,
  isLoading,
  escala,
  selectedElement,
}) => {
  const selectedElementFromEscala = escala.find(
    (_, i) => i === selectedElement
  );
  const [colorPicked, setColorPicked] = useState(selectedElementFromEscala.cor);
  const [graphInit, setGraphInit] = useState(
    selectedElementFromEscala.inicioGrafico
  );

  const [graphEnd, setGraphEnd] = useState(
    selectedElementFromEscala.fimGrafico
  );

  const handleChangeColorPicked = (color) => {
    setColorPicked(color.hex);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      inicioGrafico: selectedElementFromEscala.inicioGrafico,
      cor: selectedElementFromEscala.cor,
      fimGrafico: selectedElementFromEscala.fimGrafico,
    },
  });
  return (
    <Wrapper>
      <StyledTitle fontSize="1.4rem">Edite a escala</StyledTitle>
      <InputWrapper>
        <Input
          id="min"
          name="min"
          value={graphInit}
          onChange={(e) => setGraphInit(e.target.value)}
          label="Min"
          width="35%"
          placeholder="Digite o valor mínimo..."
        />
        <Input
          id="max"
          name="max"
          value={graphEnd}
          onChange={(e) => setGraphEnd(e.target.value)}
          label="Max"
          width="35%"
          placeholder="Digite o valor máximo..."
        />
        <ColorPicker
          color={colorPicked}
          handleChange={handleChangeColorPicked}
          width="30%"
          isScale
        />
      </InputWrapper>
      <WrapperButtons>
        <StyledButton
          border="none"
          width="100%"
          boxShadow="none"
          borderHover="0"
          onClick={onCancel}
          backgroundColor={theme.colors.darkerGray}
          type="button"
        >
          Cancelar
        </StyledButton>
        <StyledButton
          border="none"
          width="100%"
          boxShadow="none"
          borderHover="0"
          onClick={() => onConfirm(graphInit, graphEnd, colorPicked)}
          type="button"
        >
          {isLoading ? (
            <Lottie
              loop
              animationData={WhiteLoading}
              play
              speed={2.5}
              style={{ width: '40px', height: '40px' }}
            />
          ) : (
            'Finalizar'
          )}
        </StyledButton>
      </WrapperButtons>
    </Wrapper>
  );
};

export default ModalEdit;
