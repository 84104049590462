import React, { createContext, useState, useMemo } from 'react';
import { AES, enc } from 'crypto-js';
import { formatMessagesErrors } from '@utils/index';
import { useSnackbar } from 'notistack';

// Routes
import { LOGIN } from '@routes';

// Api
import api from '@services/api';

import { KEY, LOCAL_STORAGE_AUTH } from './constants';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const saveOnLocalStorage = (values) => {
    const encryptedValues = AES.encrypt(JSON.stringify(values), KEY).toString();
    localStorage.setItem(LOCAL_STORAGE_AUTH.USER_INFO, encryptedValues);
  };

  const getFromLocalStorage = () => {
    const values = localStorage.getItem(LOCAL_STORAGE_AUTH.USER_INFO);

    if (!values) return null;

    const decryptedValues = AES.decrypt(values, KEY).toString(enc.Utf8);

    return JSON.parse(decryptedValues);
  };

  const handleLogin = async (values) => {
    try {
      const { email, password } = values;

      const { data } = await api.post('/Account/Token', {
        email,
        password,
      });

      setUserInfo(data);
      saveOnLocalStorage(data);
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    }
  };

  const handleLogout = () => {
    setUserInfo(null);
    localStorage.removeItem(LOCAL_STORAGE_AUTH.USER_INFO);
    window.location = LOGIN;
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        setUserInfo,
        handleLogin,
        handleLogout,
        saveOnLocalStorage,
        getFromLocalStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
