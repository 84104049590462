import styled from 'styled-components';

import Title from '../Title';
import Card from '../Card';

export const Container = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.fadedText};
  opacity: 0.6;
`;

export const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.fadedIcon};
  font-size: 2rem;
  font-weight: 500;
`;

export const StyledCard = styled(Card)`
  margin-right: 0;
  margin-top: 10px;
`;
