import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.fadedText};
  font-size: ${(props) => props.fontSizeLabel};
  font-weight: 400;
`;

export const Swatch = styled.button`
  padding: 5px 10px;
  width: 100%;
  height: 3.5rem;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  cursor: pointer;
`;

export const Popover = styled.div`
  position: absolute;
  ${(props) =>
    props.isScale &&
    css`
      left: 10px;
    `}
  z-index: 5;

  .sketch-picker {
    z-index: 1000;
  }
`;

export const Cover = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
`;

export const Color = styled.div`
  width: 100%;
  height: 70%;
  border-radius: 2px;
  background: ${(props) => props.color};
`;
