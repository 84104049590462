import React, { createContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { formatMessagesErrors } from '@utils/index';
import { v4 as uuid } from 'uuid';
import api from '@services/api';

export const CollectionsContext = createContext();

const CollectionsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [perPage, setPerPage] = useState(22);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');
  const [farms, setFarms] = useState([]);
  const [zones, setZones] = useState([]);
  const [emptyData, setEmptyData] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [producerAndFarm, setProducerAndFarm] = useState();
  const [material, setMaterial] = useState();
  const [lab, setLab] = useState();
  const [analysisPackage, setAnalysisPackage] = useState();
  const [stepNumber, setStepNumber] = useState(1);
  const [partnerId, setPartnerId] = useState(0);
  const [culturesIds, setCulturesIds] = useState([]);
  const [talhoesIds, setTalhoesIds] = useState([]);

  const [collections, setCollections] = useState([]);

  const handleResetFarms = () => {
    setCurrentPage(1);
    setPerPage(15);
    setFarms([]);
  };

  const handleSearchFarms = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        const { data } = await api.get(`/Farm/GetAll?Search=${search}`);

        handleResetFarms();
        setFarms(data);
        setEmptyData(false);
      }
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadNewFarms = async () => {
    try {
      if (!isLoading && currentPage <= totalPages) {
        setIsLoading(true);
        const { data } = await api.get(
          `/Farm/GetAll?PageNumber=${currentPage}&PageSize=${perPage}`
        );

        if (data.data.length === 0) {
          handleResetFarms();
          setFarms([]);
          setEmptyData(true);
        } else {
          setEmptyData(false);
          setFarms((prevState) => [...prevState, ...data.data]);
          setCurrentPage((prevPage) => prevPage + 1);
          setTotalPages(data.totalPages);
          setHasMore(data.hasMore);
        }
      }
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addProducerAndFarm = (content) => {
    if (producerAndFarm !== undefined && producerAndFarm.id === content.id) {
      setProducerAndFarm();
    } else {
      setProducerAndFarm({ ...content, step: 1 });
    }
  };

  const addMaterial = (content) => {
    if (material !== undefined && material.id === content.id) {
      setMaterial();
    } else {
      setMaterial({ ...content, step: 2 });
    }
  };

  // const addLab = (content) => {
  //   if (lab !== undefined && lab.id === content.id) {
  //     setLab();
  //   } else {
  //     setLab({ ...content, step: 3 });
  //   }
  // };

  const addAnalysisPackage = (content) => {
    if (analysisPackage !== undefined && analysisPackage.id === content.id) {
      setAnalysisPackage();
    } else {
      setAnalysisPackage({ ...content, step: 3 });
    }
  };

  const addZones = (content) => {
    let hasError = false;

    setZones((prevState) => {
      if (!prevState.find((zone) => zone.id === content.id)) {
        return [...prevState, content];
      }

      hasError = true;
      return [...prevState];
    });

    if (hasError) {
      enqueueSnackbar('Não é permitido selecionar a mesma zona duas vezes', {
        variant: 'error',
      });
    }
  };

  const removeZones = (content) => {
    setZones(content);
  };

  const getPartnerId = async (idClient) => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        const { data } = await api.get(`/Client/GetById/${idClient}`);

        setPartnerId(data.parceiroId);
      }
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const packageName = () => {
    if (analysisPackage.comboPacote === 'Combos Química Biológica Textura') {
      return `Quim-Bio-Tex ${analysisPackage.qtdeProfundidades}`;
    }
    if (analysisPackage.comboPacote === 'Combos Química Biológica') {
      return `Quim-Bio ${analysisPackage.qtdeProfundidades}`;
    }
    if (analysisPackage.comboPacote === 'Combos Química') {
      return `Quimica ${analysisPackage.qtdeProfundidades}`;
    }
    if (analysisPackage.comboPacote === 'Combos Físico-Hídrica') {
      return `Fis-Hidr ${analysisPackage.qtdeProfundidades}`;
    }
    if (analysisPackage.comboPacote === 'Combos Tecido Vegetal') {
      return 'Tecido Vegetal';
    }
    return '-';
  };

  const getTalhoesNames = () => {
    let names = [];

    zones.map(
      (item) =>
        !names.find((name) => name === item.talhao) && names.push(item.talhao)
    );

    names = names.toString().replace(/,/g, ', ');
    return names;
  };

  const addAllData = () => {
    const auxArr = [];

    getPartnerId(producerAndFarm.clienteId);

    const talhoesNames = getTalhoesNames();

    const header = {
      id: uuid(),
      type: 'header',
      columns: [
        {
          id: uuid(),
          title: 'Produtor',
          value: producerAndFarm.cliente,
        },
        {
          id: uuid(),
          title: 'Fazenda',
          value: producerAndFarm.nome,
        },
        {
          id: uuid(),
          title: 'Talhões',
          value: talhoesNames,
        },
        {
          id: uuid(),
          title: 'Município',
          value: `${producerAndFarm.cidade} - ${producerAndFarm.estado}`,
        },
        {
          id: uuid(),
          title: 'Laboratório',
          value: 'Laborsolo do Brasil S/S LTDA',
        },
        {
          id: uuid(),
          title: 'Pacote de análise',
          value: packageName(),
        },
        {
          id: uuid(),
          title: 'Bioma',
          value: 'Cerrado',
        },
        {
          id: uuid(),
          title: 'Ordem Solo',
          value: 'Latossolo',
        },
      ],
    };

    const content = {
      id: uuid(),
      type: 'samples',
      samples: [],
    };

    zones.map((item) => {
      const numberFormated = Number(item.area.replace(',', '.'));

      const valuePercentage =
        analysisPackage.desconto > 0 &&
        analysisPackage.valor * (analysisPackage.desconto / 100);
      const withDescount =
        analysisPackage.desconto > 0 && analysisPackage.valor - valuePercentage;

      const valueFormated =
        analysisPackage.desconto > 0 ? withDescount : analysisPackage.valor;

      return content.samples.push({
        id: uuid(),
        area: numberFormated,
        price: valueFormated,
        columns: [
          {
            id: uuid(),
            title: 'Zona',
            value: item.zonaManejo,
          },
          {
            id: uuid(),
            title: 'Área (ha)',
            value: item.area,
          },
          {
            id: uuid(),
            title: 'Pacote',
            value: packageName(),
          },
          {
            id: uuid(),
            title: 'Talhão',
            value: item.talhao,
          },
          {
            id: uuid(),
            title: 'Cultura ant.',
            value: '',
          },
        ],
      });
    });

    auxArr.push(header);
    auxArr.push(content);
    setCollections(auxArr);
  };

  const sendData = async () => {
    const arrRequest = [];

    zones.map((zone, index) => {
      const registeredZone = arrRequest.find(
        (item) => item.idTalhao === zone.idTalhao
      );

      if (registeredZone) {
        return registeredZone.coletaAmostra.push({
          zonaManejoId: zone.id,
          culturaId: culturesIds[index],
        });
      }

      return arrRequest.push({
        idParceiro: partnerId,
        idTalhao: Number(zone.idTalhao),
        tipoServico: analysisPackage.tipoServico,
        idCliente: producerAndFarm.clienteId,
        idFazenda: producerAndFarm.id,
        idPacote: analysisPackage.id,
        coletaAmostra: [
          {
            zonaManejoId: zone.id,
            culturaId: culturesIds[index],
          },
        ],
      });
    });

    try {
      if (!isLoading) {
        setIsLoading(true);

        const { data } = await api.post('/Collect/Create', arrRequest);

        enqueueSnackbar('Coleta cadastrada com sucesso!', {
          variant: 'success',
        });

        navigate('/');
      }
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    if (stepNumber < 4) {
      setStepNumber((prevState) => prevState + 1);
    } else {
      setStepNumber((prevState) => prevState + 1);
      addAllData();
    }
  };

  const resetForwardData = (id) => {
    if (id === 1) {
      setMaterial();
      // setLab();
      setAnalysisPackage();
      setCollections([]);
      setZones([]);
    } else if (id === 2) {
      // setLab();
      setAnalysisPackage();
      setCollections([]);
      setZones([]);
    } else if (id === 3) {
      //   setAnalysisPackage();
      //   setCollections([]);
      // } else if (id === 4) {
      setAnalysisPackage();
      setCollections([]);
    }
  };

  const backStep = () => {
    if (stepNumber === 1) {
      setStepNumber(1);
      resetForwardData(1);
    } else {
      resetForwardData(stepNumber - 1);
      setStepNumber((prevState) => prevState - 1);
    }
  };

  const canContinue = () => {
    if (stepNumber === 1) {
      return producerAndFarm !== undefined;
    }
    if (stepNumber === 2) {
      return material !== undefined;
    }
    if (stepNumber === 3) {
      //   return lab !== undefined;
      // }
      // if (stepNumber === 4) {
      return zones.length !== 0;
    }
    if (stepNumber === 4) {
      return analysisPackage !== undefined;
    }
    return false;
  };

  return (
    <CollectionsContext.Provider
      value={{
        farms,
        isLoading,
        hasMore,
        perPage,
        search,
        setSearch,
        producerAndFarm,
        addProducerAndFarm,
        material,
        addMaterial,
        lab,
        analysisPackage,
        addAnalysisPackage,
        stepNumber,
        setStepNumber,
        nextStep,
        backStep,
        canContinue,
        resetForwardData,
        handleResetFarms,
        handleSearchFarms,
        handleLoadNewFarms,
        collections,
        zones,
        setZones,
        addZones,
        removeZones,
        emptyData,
        sendData,
        culturesIds,
        setCulturesIds,
        talhoesIds,
        setTalhoesIds,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};

export default CollectionsProvider;
