import React, { createContext, useState, useEffect } from 'react';
import api from '@services/api';

export const TalhaoContext = createContext();

export const TalhaoProvider = ({ children }) => {
  const [selectedTalhao, setSelectedTalhao] = useState(null);
  const [zonasTalhao, setZonasTalhao] = useState([]);
  const [fazendaTalhao, setFazendaTalhao] = useState(null);
  const [clienteTalhao, setClienteTalhao] = useState(null);
  const [parceiroTalhao, setParceiroTalhao] = useState(null);
  const [currentEditGeoJson, setCurrentEditGeoJson] = useState([]);

  const clearContext = () => {
    setSelectedTalhao(null);
    setZonasTalhao([]);
    setFazendaTalhao(null);
    setClienteTalhao(null);
    setParceiroTalhao(null);
  };

  const selectParceiro = async (parceiroId) => {
    await api
      .get(`/Partner/GetById/${parceiroId}`)
      .then((res) => setParceiroTalhao(res.data))
      .catch((err) => console.error(err));
  };

  const selectClient = async (clientId) => {
    await api
      .get(`/Client/GetById/${clientId}`)
      .then((res) => {
        const { data } = res;
        const { parceiroId } = data;

        setClienteTalhao(data);
        selectParceiro(parceiroId);
      })
      .catch((err) => console.error(err));
  };

  const selectFarm = async (farmId) => {
    await api
      .get(`/Farm/GetById/${farmId}`)
      .then((res) => {
        const { data } = res;
        const { clienteId } = data;

        setFazendaTalhao(data);
        selectClient(clienteId);
      })
      .catch((err) => console.error(err));
  };

  const selectTalhao = async (id) => {
    await api
      .get(`/Talhao/GetById/${id}`)
      .then((res) => {
        const { data } = res;
        const { zonaManejos, fazendaId } = data;

        setSelectedTalhao(res.data);
        const filteredZonaManejos = zonaManejos.map(
          (zona) => zona?.features[0]
        );
        setZonasTalhao(filteredZonaManejos);
        selectFarm(fazendaId);
      })
      .catch((err) => console.error(err));
  };

  return (
    <TalhaoContext.Provider
      value={{
        selectedTalhao,
        zonasTalhao,
        fazendaTalhao,
        clienteTalhao,
        parceiroTalhao,
        currentEditGeoJson,
        setZonasTalhao,
        selectTalhao,
        clearContext,
        setCurrentEditGeoJson,
      }}
    >
      {children}
    </TalhaoContext.Provider>
  );
};
