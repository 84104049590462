import styled from 'styled-components';
import CardSkeleton from '../CardSkeleton';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Container = styled.div`
  margin-left: 120px;

  @media (max-width: 480px) {
    margin-left: 16px;
  }
`;

export const StyledCardSkeleton = styled(CardSkeleton)`
  position: absolute;
  left: 0;

  @media (min-width: 480px) {
    top: 0;
  }

  @media (max-width: 480px) {
    bottom: 0;
    width: 100%;
    height: 70px;
  }
`;

export const StyledCardSkeletonTitle = styled(CardSkeleton)`
  margin-top: 8px;
`;

export const StyledCardSkeletonDashboard = styled(CardSkeleton)`
  border-radius: 12px;
  margin-right: 16px;
  margin-top: 16px;

  @media (max-width: 480px) {
    height: 40vh;
  }
`;

export const WrapperTitleSkeleton = styled.div`
  width: 40%;

  @media (max-width: 699px) {
    width: 70%;
  }
`;

export const WrapperDashboardSkeleton = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 2fr 1fr;
  padding-right: 16px;
`;

export const WrapperFooterDashboard = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 16px;
  margin-bottom: 16px;
`;

export const WrapperSecondTitle = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const WrapperTitle = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding-right: 16px;
`;
