import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: ${(props) => (props.width ? props.width : '3.5rem')};
  position: relative;

  .topLabel {
    color: #aeaeae;
    font-size: 1rem;
    font-weight: 400;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  .focusedSelect {
    outline: none;
    background: #ffffff;
    border: 1px solid #ff6b00;
  }
`;

export const Select = styled.div`
  height: ${(props) => (props.height ? props.height : '3.5rem')};
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  font-size: 1rem;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .selectedValues {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  :hover {
    filter: brightness(0.95);
  }
`;

export const SelectOptionsBox = styled.ul`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.spaceTop ? props.spaceTop : '5.1rem')};
  left: 0;
  z-index: 99998;
  list-style: none;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  max-height: 200px;
  overflow-y: scroll;

  .first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .unique-child {
    border-radius: 8px;
  }
`;

export const OptionCard = styled.li`
  background-color: ${(props) => (props.isActive ? '#1e90ff' : 'white')};
  color: ${(props) => props.isActive && 'white'};
  cursor: pointer;
  text-align: center;

  :hover {
    background-color: #80baf3;
  }
`;
