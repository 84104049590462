import React from 'react';

// Components
import { ReactComponent as Arrow } from '@assets/svg/secondary_arrow.svg';

// Style
import {
  ScoreContainer,
  ScoreTextWrapper,
  ScoreText,
  ScoreBarWrapper,
  ScoreBarScheleton,
  ScoreBarProgress,
} from './style';

const Score = ({ text, progress, onClick, color, isFarmProducer }) => {
  return (
    <ScoreContainer isFarmProducer={isFarmProducer} onClick={onClick}>
      <ScoreTextWrapper>
        <Arrow width="12" height="12" />
        <ScoreText>{text}</ScoreText>
      </ScoreTextWrapper>

      <ScoreBarWrapper>
        <ScoreBarScheleton />
        <ScoreBarProgress progress={progress} color={color} />
      </ScoreBarWrapper>
    </ScoreContainer>
  );
};

export default Score;
