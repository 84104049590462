import React, { useContext } from 'react';
import { SuppliesContext } from '@context/Supplies';

const useSupplies = () => {
  const context = useContext(SuppliesContext);

  return context;
};

export default useSupplies;
