import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.soilOrange : 'transparent'};
  cursor: pointer;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.soilOrange};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
