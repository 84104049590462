import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
`;

export const Text = styled.span`
  font-size: 1.4rem;
  text-align: center;
`;
