import React from 'react';

// Assets
import { ReactComponent as CalendarIcon } from '@assets/svg/icons/calendar.svg';

// Theme
import theme from '@styles/theme';

// Components
import Title from '../Title';

// Style
import { StyledCard, GraphHeader, CardCalendar, DataText } from './style';

const CardGraph = ({
  children,
  refGraph,
  title,
  hasDate,
  dateValue,
  fontSizeTitle,
  fontWeightTitle,
  fontColorTitle,
  width,
  height,
  hasSubtitle,
  valueSubtitle,
  marginBottomGraphHeader,
  ...props
}) => {
  return (
    <StyledCard width={width} ref={refGraph} height={height} {...props}>
      <GraphHeader marginBottomGraphHeader={marginBottomGraphHeader}>
        <Title
          fontSize={fontSizeTitle}
          color={fontColorTitle}
          fontWeight={fontWeightTitle}
        >
          {title}
        </Title>
        {!!hasDate && (
          <CardCalendar>
            <CalendarIcon />
            <DataText>{dateValue}</DataText>
          </CardCalendar>
        )}
      </GraphHeader>
      {!!hasSubtitle && <Title>{valueSubtitle}</Title>}
      {children}
    </StyledCard>
  );
};

CardGraph.defaultProps = {
  title: 'Área Cadastrada x Monitorada',
  fontSizeTitle: '1.3rem',
  fontWeightTitle: 'normal',
  fontColorTitle: theme.colors.fadedText,
  hasDate: true,
  dateValue: '26/08/20 até 26/08/21',
  refGraph: null,
  hasSubtitle: true,
  valueSubtitle: 'R$22.556,56',
  width: '100%',
  height: '100%',
  marginBottomGraphHeader: '0px',
};

export default CardGraph;
