import styled from 'styled-components';

// Motion
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  /* margin-right: 24px; */
  padding-right: 24px;
  min-height: 100vh;
  overflow: ${(props) => (props.hasScroll ? 'scroll' : 'hidden')};

  @media (max-width: 480px) {
    height: calc(100% - 80px);
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
