import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Assets
import { ReactComponent as Close } from '@assets/svg/icons/close.svg';
import { ReactComponent as OrangeArrow } from '@assets/svg/orange_arrow.svg';

export const Wrapper = styled(motion.div)`
  height: 100vh;
  width: 302px;

  border-right: 1px solid ${({ theme }) => theme.colors.darkWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  position: absolute;
  z-index: 3;
`;

export const WrapperTop = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 70px;
  padding-right: 20px;
`;

export const Title = styled.span`
  font-weight: 700;
  color: #2e314e;
`;

export const CloseButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

export const CloseIcon = styled(Close)`
  & {
    width: 12px;
    height: 12px;
  }
`;

export const WrapperMiddle = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  margin-top: 12px;
`;

export const WrapperSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  padding-bottom: 0;
`;

export const LineTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;

  svg {
    width: 20px;
    height: 20px;

    :not(:last-child) path {
      stroke: ${(props) => props?.stroke};
    }
  }
`;

export const TitleSection = styled.p`
  color: ${({ theme }) => theme.colors.soilOrange};
  font-weight: 700;
  margin-left: 15px;

  &::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 3px;
    background: ${({ theme }) => theme.colors.soilOrange};
    position: absolute;
    bottom: -7px;
    border-radius: 4px;
  }
`;

export const WrapperSubSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 5px;
`;

export const TitleSubSection = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 600;
  margin-left: 20px;
`;

export const TitleSubSectionSettings = styled.button`
  border: none;
  background: none;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 600;
  margin-left: 20px;
`;

export const Point = styled.div`
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.colors.mainText};
  border-radius: 100%;
`;

export const StyledOrangeArrow = styled(OrangeArrow)`
  margin-left: 15px;

  & {
    width: 12px;
    height: 12px;
  }
`;
