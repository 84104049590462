import React from 'react';

// Theme
import theme from '@styles/theme';

// Styles
import {
  Wrapper,
  StyledSelect,
  Label,
  Error,
  InputWrapper,
  StyledOptions,
} from './style';

const Input = ({
  height,
  width,
  label,
  placeholder,
  fontSize,
  fontSizeLabel,
  error,
  helperText,
  type,
  background,
  hasBorder,
  endIcon,
  endIconWidth,
  endIconHeight,
  onClick,
  onChange: onChangeInput,
  options,
  ...props
}) => {
  return (
    <Wrapper width={width}>
      <Label fontSizeLabel={fontSizeLabel}>
        {label}{' '}
        {error && <Error fontSizeLabel={fontSizeLabel}>*{helperText}</Error>}
      </Label>
      <InputWrapper>
        <StyledSelect
          {...props}
          background={background}
          height={height}
          fontSize={fontSize}
          placeholder={placeholder}
          hasBorder={hasBorder}
          onChange={onChangeInput}
        >
          {options.map((item) => {
            return (
              <StyledOptions key={item.value} value={item.value}>
                {item.text}
              </StyledOptions>
            );
          })}
        </StyledSelect>
      </InputWrapper>
    </Wrapper>
  );
};

Input.defaultProps = {
  height: '3.5rem',
  width: '22.5rem',
  label: 'Senha',
  placeholder: 'Input',
  fontSize: '1rem',
  fontSizeLabel: '1rem',
  error: false,
  helperText: '',
  type: 'text',
  background: theme.colors.white,
  hasBorder: true,
  endIcon: false,
  endIconWidth: '16px',
  endIconHeight: '16px',
  onClick: () => {},
  options: [
    {
      value: 1,
      text: 'teste',
    },
  ],
};

export default Input;
