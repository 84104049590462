import styled from 'styled-components';
import { motion } from 'framer-motion';

// Assets
import { ReactComponent as CloseIcon } from '@assets/svg/icons/close.svg';

export const BlurredBackground = styled.div`
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;
export const Wrapper = styled(motion.div)`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  z-index: 2;
  width: ${(props) => props.width};
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  /* right: calc(50% - ${(props) => props.width} / 2);
  top: calc(50% - ${(props) => props.height}px); */
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const Title = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 500;
  letter-spacing: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ButtonWrapper = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

export const WrapperContent = styled.div`
  padding: 30px;
  /* background: red; */
`;

export const StyledCloseIcon = styled(CloseIcon)`
  & {
    width: 12px;
    height: 12px;
  }
`;
