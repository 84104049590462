/* eslint-disable no-nested-ternary */
import React from 'react';
import useAuth from '@hooks/Auth';

// Theme
import Tooltip from '@mui/material/Tooltip';
import theme from '@styles/theme';

// Assets
import { ReactComponent as DeleteIcon } from '@assets/svg/icons/trash.svg';
import { ReactComponent as EditIcon } from '@assets/svg/icons/pencil.svg';
import { ReactComponent as InfoIcon } from '@assets/svg/icons/paper_search.svg';
import farm from '@assets/farm.png';
import managementZone from '@assets/managementZone.png';

// Components
import Card from '../Card';
import Title from '../Title';
import Subtitle from '../Subtitle';

// Styles
import {
  Wrapper,
  UserWrapper,
  UserPhoto,
  TextsWrapper,
  Photo,
  WrapperSubTexts,
  PositionWrapper,
  Divider,
  IconsWrappper,
  Icons,
  WrapperNameTitle,
} from './style';

const UserCompactedCard = ({
  widthCard,
  handleOpenEditUser,
  handleOpenUserInfo,
  handleOpenDeleteUser,
  info,
  idx,
  children,
  isSupplies,
  hasBottomButtons = true,
  hasInfoButton = true,
  hasEditButton = true,
  hasLastLogin = true,
  hasTitle = true,
  hasFlexGrow = true,
  hasHover = false,
  lastLogin,
  name,
  icon,
  selected = false,
  onClick = () => {},
}) => {
  const formatDateLastLogin = new Date(lastLogin).toLocaleDateString('pt-BR');

  const { userInfo } = useAuth();

  return (
    <Card
      style={hasFlexGrow && { flexGrow: 1 }}
      width={widthCard}
      selected={selected}
      onClick={onClick}
      colorWhenSelected={theme.colors.lightGray}
      padding="0"
      hasHover={hasHover}
    >
      <Wrapper hasTitle={hasTitle}>
        {hasTitle && (
          <UserWrapper>
            {!isSupplies && (
              <UserPhoto>
                <Photo
                  src={
                    info?.imageLink ||
                    (icon === 'farm'
                      ? farm
                      : icon === 'managementZone'
                      ? managementZone
                      : 'https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png')
                  }
                />
              </UserPhoto>
            )}

            <TextsWrapper>
              {hasLastLogin && formatDateLastLogin !== 'Invalid Date' && (
                <Subtitle
                  fontSize="0.5rem"
                  letterSpacing="2px"
                  fontWeight="bold"
                  color={theme.colors.grayText}
                >
                  ÚLTIMO LOGIN EM {formatDateLastLogin}
                </Subtitle>
              )}
              <WrapperNameTitle>
                <Title fontSize="1.5rem" letterSpacing="0">
                  {info.nome || name}
                </Title>
              </WrapperNameTitle>
              {!isSupplies && (
                <WrapperSubTexts>
                  {/* <Subtitle fontSize="0.8rem" lineHeight="12px">
                {info.documento1}
              </Subtitle>
              <div /> */}
                  <Subtitle fontSize="0.8rem" lineHeight="12px">
                    {info.cidade}
                    {info.estado ? ',' : ''} {info.estado}{' '}
                    {info.pais ? '- ' : ''}
                    {info.pais}
                  </Subtitle>
                </WrapperSubTexts>
              )}
            </TextsWrapper>
          </UserWrapper>
        )}
        <PositionWrapper>{children}</PositionWrapper>
      </Wrapper>
      {hasBottomButtons && (
        <>
          <Divider />
          <IconsWrappper>
            {hasInfoButton && (
              <Tooltip title="Informações" placement="bottom">
                <Icons onClick={handleOpenUserInfo(idx)}>
                  <InfoIcon />
                </Icons>
              </Tooltip>
            )}
            {userInfo?.userGroup === 'ADMINISTRADOR' && (
              <>
                {hasEditButton && (
                  <Tooltip title="Editar" placement="bottom">
                    <Icons onClick={handleOpenEditUser(idx)}>
                      <EditIcon />
                    </Icons>
                  </Tooltip>
                )}

                <Tooltip title="Deletar" placement="bottom">
                  <Icons onClick={handleOpenDeleteUser(idx)}>
                    <DeleteIcon />
                  </Icons>
                </Tooltip>
              </>
            )}
          </IconsWrappper>
        </>
      )}
    </Card>
  );
};

UserCompactedCard.defaultProps = {
  widthCard: 'auto',
  // children: 'conteúdo',
  lastLogin: '26/06/2021 ÁS 15:35',
  icon: 'user',
  handleOpenEditUser: () => {},
  handleOpenUserInfo: () => {},
  handleOpenDeleteUser: () => {},
};

export default UserCompactedCard;
