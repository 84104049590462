import React from 'react';

// Theme
import theme from '@styles/theme';

const CustomizedAxisXTick = ({ x, y, unit, payload }) => {
  return (
    <g>
      <text x={x} y={y + 20} textAnchor="end" fill={theme.colors.fadedIcon}>
        {payload.value}
        {unit}
      </text>
    </g>
  );
};

CustomizedAxisXTick.defaultProps = {
  x: 0,
  y: 0,
  unit: '',
  payload: {
    value: '',
  },
};

export default CustomizedAxisXTick;
