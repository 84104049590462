import styled from 'styled-components';

export const CardContent = styled.div`
  background: ${(props) =>
    props.selected ? props.colorWhenSelected : '#ffffff'};
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  box-shadow: 0px 20px 32px ${({ theme }) => theme.colors.darkBlue};
  border-radius: 12px;
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin-right: 8px;
  margin-bottom: 8px;

  cursor: ${({ hasHover }) => hasHover && 'pointer'};
  transition: background-color 0.5s;

  :not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    ${({ hasHover, theme, selected, colorWhenSelected }) =>
      hasHover &&
      `background: ${!selected ? theme.colors.lightGray : colorWhenSelected};`};
  }
`;
