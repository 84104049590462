import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

// Theme
import theme from '@styles/theme';

// Components
import CustomizedAxisYTick from './components/CustomizedAxisYTick';
import CustomizedAxisXTick from './components/CustomizedAxisXTick';

const LineGraph = ({
  width,
  height,
  lineName,
  data,
  hasLegend,
  legendProps,
  showVerticalGridCartesian,
  showHorizontalGridCartesian,
  cartesianGridProps,
  xDataKey,
  xPadding,
  xUnit,
  xDomain,
  xAxisProps,
  yScale,
  yUnit,
  yDomain,
  yAxisProps,
  dataKey,
  typeLine,
  strokeLineWidth,
  dot,
  hasYAxis,
  hasXAxis,
  tooltipProps,
  lineChartProps,
  lineProps,
}) => {
  return (
    <LineChart height={height} width={width} data={data} {...lineChartProps}>
      <CartesianGrid
        vertical={showVerticalGridCartesian}
        horizontal={showHorizontalGridCartesian}
        {...cartesianGridProps}
      />

      {!!hasXAxis && (
        <XAxis
          dataKey={xDataKey}
          axisLine={false}
          tickLine={false}
          padding={xPadding}
          tick={<CustomizedAxisXTick unit={xUnit} />}
          domain={xDomain}
          {...xAxisProps}
        />
      )}
      {!!hasYAxis && (
        <YAxis
          scale={yScale}
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisYTick unit={yUnit} />}
          domain={yDomain}
          {...yAxisProps}
        />
      )}
      <Tooltip cursor={{ fill: 'transparent' }} {...tooltipProps} />
      {hasLegend && <Legend {...legendProps} />}
      <Line
        name={lineName}
        type={typeLine}
        dataKey={dataKey}
        stroke={theme.colors.mountainGreen}
        strokeWidth={strokeLineWidth}
        dot={dot}
        {...lineProps}
      />
    </LineChart>
  );
};

LineGraph.defaultProps = {
  width: 1000,
  height: 300,
  lineName: 'Valor',
  xUnit: '',
  xDomain: ['auto', 'auto'],
  xPadding: {
    left: 20,
    right: 20,
  },
  yDomain: [1, 'auto'],
  yUnit: 'ha',
  yScale: 'sequential',
  dataKey: 'pv',
  typeLine: 'natural',
  strokeLineWidth: 4,
  dot: false,
  hasLegend: false,
  showVerticalGridCartesian: false,
  showHorizontalGridCartesian: false,
  hasYAxis: true,
  hasXAxis: true,
  data: [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 100,
      amt: 2210,
    },

    {
      name: 'Page C',
      uv: 4000,
      pv: 700,
      amt: 2100,
    },
  ],
  legendProps: {},
  xAxisProps: {},
  yAxisProps: {},
  tooltipProps: {},
  lineChartProps: {},
  lineProps: {},
};

export default LineGraph;
