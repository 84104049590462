import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Assets
import { ReactComponent as DetailMenu } from '@assets/svg/icons/detail_menu.svg';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 70px;
  z-index: 4;

  border-top: 1px solid ${({ theme }) => theme.colors.darkWhite};
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding-left: 10%;
  overflow: hidden;
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.colors.darkWhite};
  width: 100%;
  height: 1px;
`;

export const IconButton = styled(Link)`
  text-decoration: none;
  background: transparent;
  border: 0;

  svg {
    width: 25px;
    height: 25px;
  }

  svg path {
    stroke: ${(props) => props?.stroke};
    fill: ${(props) => props?.fill};
  }

  :hover svg path {
    stroke: ${(props) => props?.stroke && props?.hover};
    fill: ${(props) => props?.fill && props?.hover};
  }
`;

export const WrapperMiddleButtons = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const WrapperBottomButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-right: 18vw;
  height: 100%;

  @media (max-width: 280px) {
    padding-right: 15vw;
  }
`;

export const WrapperAnimateDetail = styled(motion.div)`
  display: flex;
  justify-content: center;

  height: 8px;
  position: absolute;
  top: 0;
  align-items: center;
`;

export const StyledDetailMenu = styled(DetailMenu)`
  top: 0;
  transform: rotate(90deg);
`;

export const BlurredBackground = styled(motion.div)`
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  z-index: 2;
`;
