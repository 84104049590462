import React from 'react';

// Theme
import theme from '@styles/theme';

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';

// Components
import CustomizedAxisYTick from './components/CustomizedAxisYTick';
import CustomizedAxisXTick from './components/CustomizedAxisXTick';
import CustomBarShape from './components/Shape';

const BarGraph = ({
  data,
  width,
  height,
  yDomain,
  xDomain,
  xPadding,
  xUnit,
  yUnit,
  barSize,
  barRadius,
  xDataKey,
  yScale,
  barDataKey,
  barName,
  showVerticalGridCartesian,
  showHorizontalGridCartesian,
  hasLegend,
  barChartProps,
  xAxisProps,
  yAxisProps,
  tooltipProps,
  legendProps,
  barProps,
  cartesianGridProps,
  hasYAxis,
  hasXAxis,
  fillBehindBar,
  fillBar,
}) => {
  return (
    <BarChart width={width} height={height} data={data} {...barChartProps}>
      <CartesianGrid
        vertical={showVerticalGridCartesian}
        horizontal={showHorizontalGridCartesian}
        {...cartesianGridProps}
      />
      {hasXAxis && (
        <XAxis
          dataKey={xDataKey}
          axisLine={false}
          tickLine={false}
          padding={xPadding}
          tick={<CustomizedAxisXTick unit={xUnit} />}
          domain={xDomain}
          {...xAxisProps}
        />
      )}
      {!!hasYAxis && (
        <YAxis
          scale={yScale}
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisYTick unit={yUnit} />}
          domain={yDomain}
          {...yAxisProps}
        />
      )}
      <Tooltip cursor={{ fill: 'transparent' }} {...tooltipProps} />
      {hasLegend && <Legend {...legendProps} />}
      <Bar
        name={barName}
        radius={barRadius}
        barSize={barSize}
        dataKey={barDataKey}
        shape={
          <CustomBarShape fillBehindBar={fillBehindBar} fillBar={fillBar} />
        }
        {...barProps}
      />
    </BarChart>
  );
};

BarGraph.defaultProps = {
  width: 1000,
  height: 300,
  hasYAxis: true,
  hasXAxis: true,
  data: [
    {
      name: '1',
      value: 10,
    },
  ],
  yDomain: [1, 'auto'],
  xDomain: ['auto', 'auto'],
  xPadding: {
    left: 20,
    right: 20,
  },
  xUnit: '',
  yUnit: 'ha',
  barSize: 50,
  barRadius: [10, 10, 0, 0],
  xDataKey: 'name',
  yScale: 'sequential',
  barDataKey: 'value',
  barName: 'Valor',
  showVerticalGridCartesian: false,
  showHorizontalGridCartesian: true,
  hasLegend: false,
  barChartProps: {},
  xAxisProps: {},
  yAxisProps: {},
  tooltipProps: {},
  legendProps: {},
  barProps: {},
  cartesianGridProps: {},
  fillBehindBar: theme.colors.darkerGray2,
  fillBar: theme.colors.veryLightGray,
};

export default BarGraph;
