import styled from 'styled-components';
import { motion } from 'framer-motion';

// Assets
import { ReactComponent as CloseIcon } from '@assets/svg/icons/close.svg';

export const BlurredBackground = styled(motion.div)`
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const Wrapper = styled(motion.div)`
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid #eaeaea;
  box-shadow: -16px 4px 64px rgba(0, 0, 0, 0.08);
  z-index: 2;
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #f1f1f1;
  /* width: 100%; */
  padding: 20px;
  position: fixed;
  background: ${({ theme }) => theme.colors.white};
  z-index: 5;
`;

export const CloseButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  & {
    width: 12px;
    height: 12px;
  }
`;
