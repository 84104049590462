import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontSize};
  border: none;
  border-radius: 12px;
  background: linear-gradient(
    92.23deg,
    ${(props) => props.colorGradient1} -1.04%,
    ${(props) => props.colorGradient2} 184.93%
  );
  box-shadow: 0px 8px 32px ${(props) => props.boxShadow};
  color: ${(props) => props.fontColor};
  font-weight: ${(props) => props.fontWeight};
  transition: 0.3s all ease;

  &:hover {
    filter: brightness(1.2);
  }
`;
