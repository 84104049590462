import React from 'react';

// Components
import Card from '../Card';

// Styles
import { Container, Text, StyledTitle, StyledCard } from './style';

const CardDiagnosis = () => {
  return (
    <StyledCard height="calc(100% - 16px)">
      <StyledTitle>Matriz de diagnóstico</StyledTitle>
      <Container>
        <Text>A definir</Text>
      </Container>
    </StyledCard>
  );
};

export default CardDiagnosis;
