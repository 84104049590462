import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

// Theme
import theme from '@styles/theme';

// Style
import { ReactComponent as Menu } from '@assets/svg/icons/menu.svg';
import {
  Wrapper,
  WrapperMiddleButtons,
  Line,
  WrapperAnimateDetail,
  StyledDetailMenu,
  IconButton,
  WrapperBottomButton,
  BlurredBackground,
} from './style';

// Assets

// Component
import OpenedMenu from './components/openedMenu';

const MobileDrawerMenu = ({
  pathname,
  mobileMenuOptions,
  mobileSideMenuOptions,
}) => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const handleMenu = () => setMenuVisible((prevState) => !prevState);

  const bottomOptions = [
    {
      icon: <Menu />,
      colors: {
        fill: isMenuVisible ? theme.colors.soilOrange : theme.colors.fadedIcon,
        hover: theme.colors.soilOrange,
      },
      onClick: handleMenu,
      id: 100,
      title: 'Menu',
    },
  ];

  return (
    <>
      <Wrapper>
        <WrapperMiddleButtons>
          {mobileMenuOptions.map((item) => (
            <Line key={crypto.randomUUID()}>
              <AnimatePresence>
                {pathname === item.to && (
                  <WrapperAnimateDetail
                    key={`leftDrawerIconDetail${item.id}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <StyledDetailMenu />
                  </WrapperAnimateDetail>
                )}
              </AnimatePresence>
              <IconButton to={item.to} {...item.colors}>
                {item.icon}
              </IconButton>
            </Line>
          ))}
        </WrapperMiddleButtons>

        <WrapperBottomButton>
          {bottomOptions.map((item) => (
            <Line key={item.id}>
              <AnimatePresence>
                {pathname === item.to && (
                  <WrapperAnimateDetail
                    key={`leftDrawerIconDetail${item.id}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <StyledDetailMenu />
                  </WrapperAnimateDetail>
                )}
              </AnimatePresence>
              <IconButton onClick={item.onClick} as="button" {...item.colors}>
                {item.icon}
              </IconButton>
            </Line>
          ))}
        </WrapperBottomButton>
      </Wrapper>

      <OpenedMenu
        handleMenu={handleMenu}
        open={isMenuVisible}
        options={mobileSideMenuOptions}
      />

      {isMenuVisible && (
        <BlurredBackground
          onClick={handleMenu}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          open={isMenuVisible}
        />
      )}
    </>
  );
};

export default MobileDrawerMenu;
