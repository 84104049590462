import React from 'react';
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts';

// Styles

const RadialBarGraph = ({
  data,
  value,
  circleSize,
  color,
  domain = [0, 100],
  fontSize = '25',
  startAngle = 270,
  endAngle = -90,
}) => {
  return (
    <RadialBarChart
      width={circleSize / 2}
      height={circleSize / 2}
      cx={circleSize / 4}
      cy={circleSize / 4}
      innerRadius="75%"
      outerRadius="90%"
      barSize={20}
      data={[data]}
      startAngle={startAngle}
      endAngle={endAngle}
    >
      <PolarAngleAxis
        type="number"
        domain={domain}
        angleAxisId={0}
        tick={false}
      />

      <RadialBar
        background
        clockWise
        dataKey="value"
        denominator="value"
        cornerRadius={circleSize / 2}
        fill={`#${color}`}
      />
      <text
        x={circleSize / 4 + 2}
        y={circleSize / 4 + 2}
        textAnchor="middle"
        dominantBaseline="middle"
        className="progress-label"
        fontWeight="900"
        fontSize={fontSize}
      >
        {value || `${data.value}%`}
      </text>
    </RadialBarChart>
  );
};

export default RadialBarGraph;
