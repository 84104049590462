import styled from 'styled-components';
import Button from '../Button';

export const StyledMap = styled.div`
  border-radius: 12px;
  width: 100%;
  height: ${({ height }) => height};
  position: relative;

  & .mapboxgl-canvas {
    height: 100% !important;
    width: 100% !important;
  }
`;

export const ButtonToNewRecommendations = styled(Button)`
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  z-index: 1;
`;

export const ButtonToCollections = styled(Button)`
  position: absolute;
  bottom: 3rem;
  right: calc(4rem + 13%);
  z-index: 1;
`;
