import React from 'react';

// Style
import {
  Wrapper,
  LeftWrapperText,
  RightWrapperText,
  Text,
  LinkText,
} from './style';

const Footer = () => {
  return (
    <Wrapper>
      <LeftWrapperText>
        <LinkText to="#">Política de Privacidade</LinkText>
        <LinkText to="#">Termos de Uso</LinkText>
      </LeftWrapperText>
      <RightWrapperText>
        <Text>2021&copy;The Soil Company - All Rights Reserved</Text>
      </RightWrapperText>
    </Wrapper>
  );
};
export default Footer;
