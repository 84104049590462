/* eslint-disable */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { CPFMask } from '@components/Input/masks';
import usePosition from '@hooks/useLocation';
import bbox from '@turf/bbox';
import mapboxgl from '@services/MapBox';
import { useNavigate } from 'react-router-dom';

import light from '@assets/viewport-options/light.png';
import dark from '@assets/viewport-options/dark.png';
import satelite from '@assets/viewport-options/satelite.png';
import ruas from '@assets/viewport-options/ruas.png';

import theme from '@styles/theme';

import { createGeoJSONCircle } from '@utils/map';

import Card from '../Card';
import {
  StyledMap,
  OptionsWrapper,
  Option,
  OptionImage,
  Description,
  StyledInput,
  ButtonToCollections,
  ButtonToNewRecommendations,
} from './style';

const generateHexColor = () => {
  return `#${parseInt(Math.random() * 0xffffff, 10)
    .toString(16)
    .padStart(6, '0')}`;
};

const rgba2hex = (orig) => {
  let a;
  let isPercent;
  const rgb = orig
    .replace(/\s/g, '')
    .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  const alpha = ((rgb && rgb[4]) || '').trim();
  let hex = rgb
    ? // eslint-disable-next-line no-bitwise
      (rgb[1] | (1 << 8)).toString(16).slice(1) +
      // eslint-disable-next-line no-bitwise
      (rgb[2] | (1 << 8)).toString(16).slice(1) +
      // eslint-disable-next-line no-bitwise
      (rgb[3] | (1 << 8)).toString(16).slice(1)
    : orig;

  if (alpha !== '') {
    a = alpha;
  } else {
    a = 1;
  }
  hex += a;

  return hex;
};

const ZOOM_MAP = 14;

const MapWithLayers = ({
  hasLayer,
  hasCollectionLayer,
  width,
  height,
  geoJson,
  geoJsonPrev,
  handleChange,
  getMapDrawFunctions,
  handleOpenTalhaoNameModal,
  hasSearchInput,
  hasDrawOptions,
  startsWithSattelite,
  styleMap,
  kmlImported,
  // getCurrentMap,
  callBackWhenClickLayer,
  hasViewportOptions,
  paintOnClick,
  deletedZone,
  setDeletedZone,
  hasViewportOptionLight,
  hasViewportOptionDark,
  hasViewportOptionSatellite,
  hasViewportOptionStreet,
  customCoordinates,
  customZoom,
  needToRemoveLayer,
  editMode,
  allowFocusUserPosition = false,
  optionsWrapperStyles,
  updateMap,
  customMessage,
  hasButtonToNewRecommendations,
  hasButtonToCollections,
  hasButtonToSoilAnalysis,
  selectedZones,
  paintAll,
  setPaintAll,
  currentFeatureSelected,
  setCurrentFeatureSelected,
  setUpdateMap,
  setGeoJson,
  ...props
}) => {
  const hasOption =
    hasViewportOptions ||
    hasViewportOptionLight ||
    hasViewportOptionDark ||
    hasViewportOptionSatellite ||
    hasViewportOptionStreet;

  const { positionCurrent } = usePosition();

  const mapContainer = useRef(null);
  const hoverId = useRef(null);
  const clickId = useRef(null);

  const map = useRef(null);
  const arrayLayerIdString = useRef();
  const arraySourceIdString = useRef();

  const [lng, setLng] = useState(customCoordinates.lng);
  const [lat, setLat] = useState(customCoordinates.lat);

  const [zoom, setZoom] = useState(2);
  const [viewportStyle, setViewportStyle] = useState('mapbox://styles/mapbox/satellite-streets-v11');

  const [featuresClicked, setFeaturesClicked] = useState([]);

  const navigate = useNavigate();

  const mapDraw = new MapboxDraw({
    controls: {
      point: false,
      line_string: false,
      polygon: true,
      trash: true,
      combine_features: true,
      uncombine_features: true,
    },
    userProperties: true,
  });

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  function handleZoomByLatLong(latitude, longitude) {
    const sw = new mapboxgl.LngLat(longitude, latitude);
    const ne = new mapboxgl.LngLat(longitude, latitude);

    const bounds = new mapboxgl.LngLatBounds(sw, ne);

    map.current.fitBounds(bounds, {
      zoom: customZoom,
    });
  }

  const handleZoom = () => {
    if (geoJson?.features?.length === 0) {
      return;
    }

    const findTalhaoIndex = geoJson.features.findIndex(
      (item) => item.properties
    );
    const bboxOnMap = bbox({
      type: 'FeatureCollection',
      features: [geoJson.features[findTalhaoIndex]],
    });

    const sw = new mapboxgl.LngLat(bboxOnMap[0], bboxOnMap[1]);
    const ne = new mapboxgl.LngLat(bboxOnMap[2], bboxOnMap[3]);

    const coords = new mapboxgl.LngLatBounds(sw, ne);

    map.current.fitBounds(coords, { padding: 5 });
  };

  const goToNewRecommendations = () => {
    navigate('/novasrecomendacoes');
  };

  const goToCollections = () => {
    navigate('/nova-coleta');
  };

  const goToSoilAnalysis = () => {
    navigate('/soil-analysis');
  };

  // useEffect(() => {
  //   const { latitude, longitude } = positionCurrent;

  //   if (!latitude || !longitude || hasLayer || !allowFocusUserPosition) {
  //     return;
  //   }

  //   handleZoomByLatLong(latitude, longitude);
  // }, [positionCurrent]);

  const handleZoomByGeoJson = () => {
    if (geoJson?.features?.length === 0 || !hasLayer || !map?.current) {
      return;
    }
    const bboxOnMap = bbox(geoJson);
    geoJson;

    const sw = new mapboxgl.LngLat(bboxOnMap[0], bboxOnMap[1]);
    const ne = new mapboxgl.LngLat(bboxOnMap[2], bboxOnMap[3]);

    const coords = new mapboxgl.LngLatBounds(sw, ne);

    map.current.fitBounds(coords, { padding: 5 });
  };

  const handleAddLayers = () => {
    const arrayLayerId = [];
    const arraySourceId = [];

    if (editMode) {
      mapDraw.add(geoJson);
      const { features } = mapDraw.getAll();
      handleChange(features);
    } else {
      geoJson?.features?.forEach((item) => {
        const { idZonaManejo, color } = item.properties;

        const geoJsonPropertyFormatted = {
          type: 'FeatureCollection',
          features: [{ id: item.id, ...item }],
        };

        const layerID = `FeatureLayer${item.id}`;
        arrayLayerId.push(layerID);

        arraySourceId.push(`Feature_${item.id}`);

        map.current.addSource(`Feature_${item.id}`, {
          type: 'geojson',
          data: geoJsonPropertyFormatted,
        });

        if (item && hasLayer && !paintOnClick) {
          map.current.addLayer({
            id: layerID,
            type: 'fill',
            source: `Feature_${item.id}`,
            paint: {
              'fill-color': color || generateHexColor(),
              'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                color ? 1 : 0.5,
              ],
            },
          });
        }

        const isSelected = selectedZones?.find((zone) => zone.id === item.id);

        if (item && paintOnClick && isSelected) {
          map.current.addLayer({
            id: layerID,
            type: 'fill',
            source: `Feature_${item.id}`,
            paint: {
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'clicked'], true],
                color || generateHexColor(),
                '#919191',
              ],
              'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'clicked'], true],
                1,
                0.5,
              ],
              'fill-outline-color': color || generateHexColor(),
            },
          });
        }

        if (item && paintOnClick && !isSelected) {
          map.current.addLayer({
            id: layerID,
            type: 'fill',
            source: `Feature_${item.id}`,
            paint: {
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'clicked'], false],
                color || generateHexColor(),
                '#919191',
              ],
              'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'clicked'], false],
                1,
                0.5,
              ],
              'fill-outline-color': color || generateHexColor(),
            },
          });
        }
      });

      arraySourceIdString.current = arraySourceId;
      arrayLayerIdString.current = arrayLayerId;
    }
    // map.current.on('load', () => {
    //   const arrayLayerId = [];
    //   const arraySourceId = [];

    //   if (geoJson?.features === undefined) {
    //     return;
    //   }

    //   mapDraw.add(geoJson);
    //   const { features } = mapDraw.getAll();

    //   handleChange(features);
    //   geoJson.features.forEach((item) => {
    //     const { idZonaManejo, color } = item.properties;

    //     const geoJsonPropertyFormatted = {
    //       type: 'FeatureCollection',
    //       features: [{ id: item.id, ...item }],
    //     };

    //     const layerID = `FeatureLayer${item.id}`;
    //     arrayLayerId.push(layerID);

    //     arraySourceId.push(`Feature_${item.id}`);

    //     map.current.addSource(`Feature_${item.id}`, {
    //       type: 'geojson',
    //       data: geoJsonPropertyFormatted,
    //     });

    //     if (item && hasLayer && paintOnClick === false) {
    //       map.current.addLayer({
    //         id: layerID,
    //         type: 'fill',
    //         source: `Feature_${item.id}`,
    //         paint: {
    //           'fill-color': '#d9e8ec',
    //           'fill-opacity': 0.5,
    //           'fill-outline-color': '#3bb2d0',
    //         },
    //       });

    //       map.current.addLayer({
    //         id: `line_${layerID}`,
    //         type: 'line',
    //         source: `Feature_${item.id}`,
    //         layout: {},
    //         paint: {
    //           'line-color': '#3bb2d0',
    //           'line-width': 2,
    //         },
    //       });
    //     }

    //     if (item && paintOnClick) {
    //       map.current.addLayer({
    //         id: layerID,
    //         type: 'fill',
    //         source: `Feature_${item.id}`,
    //         paint: {
    //           'fill-color': [
    //             'case',
    //             ['boolean', ['feature-state', 'clicked'], false],
    //             color || generateHexColor(),
    //             '#919191',
    //           ],
    //           'fill-opacity': [
    //             'case',
    //             ['boolean', ['feature-state', 'clicked'], false],
    //             1,
    //             0.5,
    //           ],
    //           'fill-outline-color': color || generateHexColor(),
    //         },
    //       });

    //       map.addLayer({
    //         id: `line_${layerID}`,
    //         type: 'line',
    //         source: `Feature_${item.id}`,
    //         layout: {},
    //         paint: {
    //           'line-color': '#000',
    //           'line-width': 6,
    //         },
    //       });
    //     }
    //   });

    //   arraySourceIdString.current = arraySourceId;
    //   arrayLayerIdString.current = arrayLayerId;
    // });
  };

  const handleAddLayersForKML = () => {
    const arrayLayerId = [];
    const arraySourceId = [];

    if (geoJson?.features === undefined) {
      return;
    }

    geoJson?.features?.forEach((item) => {
      const { idZonaManejo, color, cor, fill } = item.properties;

      const geoJsonPropertyFormatted = {
        type: 'FeatureCollection',
        features: [{ id: item.id, ...item }],
      };

      const layerID = `FeatureLayer${item.id}`;
      arrayLayerId.push(layerID);

      arraySourceId.push(`Feature_${item.id}`);

      map.current.addSource(`Feature_${item.id}`, {
        type: 'geojson',
        data: geoJsonPropertyFormatted,
      });

      map.current.addLayer({
        id: layerID,
        type: 'fill',
        source: `Feature_${item.id}`,
        paint: {
          'fill-color': color || cor || fill || generateHexColor(),
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            color || cor || fill ? 1 : 0.5,
          ],
        },
      });
    });

    arraySourceIdString.current = arraySourceId;
    arrayLayerIdString.current = arrayLayerId;

    handleZoom();

    map.current.on('click', (e) => {
      const currentBbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];

      const selectedFeatures = map.current.queryRenderedFeatures(currentBbox, {
        layers: arrayLayerIdString.current,
      });

      const selectedFeaturesSource = selectedFeatures[0].source;

      // Criando efeito hover
      if (selectedFeatures.length > 0) {
        if (clickId !== null) {
          map.current.setFeatureState(
            { source: selectedFeaturesSource, id: clickId.current },
            { click: false }
          );
        }

        if (paintOnClick) {
          clickId.current = selectedFeatures[0].id;

          setFeaturesClicked((prevState) => [
            ...prevState,
            selectedFeatures[0],
          ]);

          map.current.setFeatureState(
            { source: selectedFeaturesSource, id: selectedFeatures[0].id },
            { clicked: true }
          );
        }
      }

      const fips = selectedFeatures.map((feature) => feature);

      if (fips.length > 0) {
        callBackWhenClickLayer(fips);
      }
    });

    map.current.on('mouseenter', arrayLayerIdString.current, (e) => {
      map.current.getCanvas().style.cursor = 'pointer';

      const features = e.features[0];

      const { fazenda, zonaManejo, name, talhao, elementoValor } =
        features.properties;
      const coordinates = features.geometry.coordinates.slice();

      const sw = new mapboxgl.LngLat(
        coordinates[0][0][0],
        coordinates[0][0][1]
      );

      let message = customMessage
        ? `Zona de manejo:${zonaManejo} <br> Valor: ${elementoValor}`
        : fazenda
        ? `${fazenda}-${zonaManejo}`
        : `Talhão: ${talhao}`;

      if (talhao) {
        message = `Talhão - ${talhao}`;
      } else if (name) {
        message = `Talhão - ${name}`;
      }

      popup.setLngLat(sw).setHTML(message).addTo(map.current);
    });

    map.current.on('mousemove', arrayLayerIdString.current, (e) => {
      const currentBbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];

      const selectedFeatures = map.current.queryRenderedFeatures(currentBbox, {
        layers: arrayLayerIdString.current,
      });

      const selectedFeaturesSource = selectedFeatures[0].source;

      // Criando efeito hover
      if (selectedFeatures.length > 0) {
        if (hoverId !== null) {
          map.current.setFeatureState(
            { source: selectedFeaturesSource, id: hoverId.current },
            { hover: false }
          );
        }

        hoverId.current = selectedFeatures[0].id;

        map.current.setFeatureState(
          { source: selectedFeaturesSource, id: selectedFeatures[0].id },
          { hover: true }
        );
      }
    });

    map.current.on('mouseleave', arrayLayerIdString.current, (e) => {
      popup.remove();
      map.current.getCanvas().style.cursor = '';

      // Removendo efeito hover
      if (hoverId.current !== null) {
        map.current.setFeatureState(
          { source: `Feature_${hoverId.current}`, id: hoverId.current },
          { hover: false }
        );
      }

      hoverId.current = null;
    });

    // map.current.on('styledataloading', () => {
    //   map.current.once('styledata', handleAddLayers);
    // });
  };

  const handleZoomOnFeature = (feature) => {
    if (geoJson?.features?.length === 0) {
      return;
    }
    const bboxOnMap = bbox({
      type: 'FeatureCollection',
      features: [feature],
    });

    const sw = new mapboxgl.LngLat(bboxOnMap[0], bboxOnMap[1]);
    const ne = new mapboxgl.LngLat(bboxOnMap[2], bboxOnMap[3]);

    const coords = new mapboxgl.LngLatBounds(sw, ne);

    map.current.fitBounds(coords, { padding: 5 });
  };

  function handleRemoveLayers() {
    if (!geoJsonPrev || geoJsonPrev?.features === undefined) {
      return;
    }

    geoJsonPrev.features.forEach((item) => {
      const layerID = `FeatureLayer${item.id}`;
      const sourceID = `Feature_${item.id}`;

      if (map.current.getLayer(layerID)) {
        map.current.removeLayer(layerID);
      }

      if (map.current.getSource(sourceID)) {
        map.current.removeSource(sourceID);
      }
    });
  }

  // useEffect(() => {
  //   if (!map.current) {
  //     return;
  //   }

  //   handleRemoveLayers();
  //   handleAddLayers();
  // }, [updateMap, map]);

  useEffect(() => {
    // if (map.current) {
    //   return;
    // }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: viewportStyle,
      center: [lng, lat],
      zoom,
    });

    if (hasDrawOptions) {
      getMapDrawFunctions(mapDraw);

      map.current.addControl(
        new MapboxGeocoder({
          accessToken: process.env.REACT_APP_MAPBOXGL_ACCESSTOKEN,
          mapboxgl,
        })
      );
      map.current.addControl(mapDraw, 'top-left');
    }

    if (hasSearchInput) {
      map.current.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl,
        })
      );
    }
  }, [viewportStyle]);

  useEffect(() => {
    if (kmlImported && map.current.loaded()) {
      return handleAddLayersForKML();
    }

    map.current.on('load', () => {
      handleAddLayers();

      map.current.on('click', (e) => {
        const currentBbox = [
          [e.point.x - 5, e.point.y - 5],
          [e.point.x + 5, e.point.y + 5],
        ];

        const selectedFeatures = map.current.queryRenderedFeatures(
          currentBbox,
          {
            layers: arrayLayerIdString.current,
          }
        );

        const selectedFeaturesSource = selectedFeatures[0].source;

        // Criando efeito hover
        if (selectedFeatures.length > 0) {
          if (clickId !== null) {
            map.current.setFeatureState(
              { source: selectedFeaturesSource, id: clickId.current },
              { click: false }
            );
          }

          if (paintOnClick) {
            clickId.current = selectedFeatures[0].id;

            setFeaturesClicked((prevState) => [
              ...prevState,
              selectedFeatures[0],
            ]);

            map.current.setFeatureState(
              { source: selectedFeaturesSource, id: selectedFeatures[0].id },
              { clicked: true }
            );
          }
        }

        const fips = selectedFeatures.map((feature) => feature);

        if (fips.length > 0) {
          callBackWhenClickLayer(fips);
        }
      });

      map.current.on('mouseenter', arrayLayerIdString.current, (e) => {
        map.current.getCanvas().style.cursor = 'pointer';

        const features = e.features[0];

        const { fazenda, zonaManejo, elementoValor, talhao } =
          features.properties;
        const coordinates = features.geometry.coordinates.slice();

        const sw = new mapboxgl.LngLat(
          coordinates[0][0][0],
          coordinates[0][0][1]
        );

        const message = customMessage
          ? `Zona de manejo:${zonaManejo} <br> Valor: ${elementoValor}`
          : fazenda
          ? `${fazenda}-${zonaManejo}`
          : `Talhão: ${talhao}`;

        popup.setLngLat(sw).setHTML(message).addTo(map.current);
      });

      map.current.on('mousemove', arrayLayerIdString.current, (e) => {
        const currentBbox = [
          [e.point.x - 5, e.point.y - 5],
          [e.point.x + 5, e.point.y + 5],
        ];

        const selectedFeatures = map.current.queryRenderedFeatures(
          currentBbox,
          {
            layers: arrayLayerIdString.current,
          }
        );

        const selectedFeaturesSource = selectedFeatures[0].source;

        // Criando efeito hover
        if (selectedFeatures.length > 0) {
          if (hoverId !== null) {
            map.current.setFeatureState(
              { source: selectedFeaturesSource, id: hoverId.current },
              { hover: false }
            );
          }

          hoverId.current = selectedFeatures[0].id;

          map.current.setFeatureState(
            { source: selectedFeaturesSource, id: selectedFeatures[0].id },
            { hover: true }
          );
        }
      });

      map.current.on('mouseleave', arrayLayerIdString.current, (e) => {
        popup.remove();
        map.current.getCanvas().style.cursor = '';

        // Removendo efeito hover
        if (hoverId.current !== null) {
          map.current.setFeatureState(
            { source: `Feature_${hoverId.current}`, id: hoverId.current },
            { hover: false }
          );
        }

        hoverId.current = null;
      });

      // map.current.on('styledataloading', () => {
      //   map.current.once('styledata', handleAddLayers);
      // });
      // handleZoomByGeoJson();
    });
  }, [geoJson, updateMap, kmlImported]);

  useEffect(() => {
    if (geoJson?.features?.length === 0 || !hasLayer || !map?.current) {
      return;
    }
    handleZoomByGeoJson();
  }, [map.current, geoJson]);

  useEffect(() => {
    // map draw
    map.current.on('draw.create', (e) => {
      console.log('hi');
      const { features } = mapDraw.getAll();
      console.log(features);
      handleChange(features);
      setGeoJson({ type: 'FeatureCollection', features });
      handleOpenTalhaoNameModal();
    });

    map.current.on('draw.combine', () => {
      const { features } = mapDraw.getAll();
      handleChange(features);
    });

    map.current.on('draw.uncombine', (e) => {
      const { features } = mapDraw.getAll();

      handleChange(features);
    });

    map.current.on('draw.delete', () => {
      const { features } = mapDraw.getAll();

      handleChange(features);
    });

    map.current.on('draw.update', () => {
      const { features } = mapDraw.getAll();

      handleChange(features);
    });
  }, [updateMap]);

  useEffect(() => {
    if (typeof deletedZone === 'number' && paintOnClick) {
      const oldArr = featuresClicked;
      const selectedItem = featuresClicked.find(
        (item) => item.id === deletedZone
      );

      if (!selectedItem) {
        const selectedFeatures = map.current.queryRenderedFeatures({
          layers: arrayLayerIdString.current,
        });

        const arrFeaturesClicked = selectedFeatures.filter(
          (item) => item.layer.paint['fill-opacity'] === 1
        );

        const newSelectedItem = arrFeaturesClicked.find(
          (item) => item.id === deletedZone
        );

        map.current.setFeatureState(
          { source: newSelectedItem.source, id: newSelectedItem.id },
          { clicked: false }
        );
      } else {
        map.current.setFeatureState(
          { source: selectedItem.source, id: selectedItem.id },
          { clicked: false }
        );
        const newArr = oldArr.filter((item) => item.id !== selectedItem.id);

        setFeaturesClicked(newArr);
      }

      setDeletedZone(null);
    }
  }, [deletedZone]);

  useEffect(() => {
    map.current.on('load', () => {
      map.current.addSource('polygon', createGeoJSONCircle([], 0.5));
      map.current.addLayer({
        id: 'polygon',
        type: 'fill',
        source: 'polygon',
        layout: {},
        paint: {
          'fill-color': theme.colors.soilOrange,
          'fill-opacity': 0.2,
        },
      });
    });

    if (map.current.getSource('polygon') && needToRemoveLayer) {
      map.current.getSource('polygon').setData(createGeoJSONCircle([], 1).data);
    } else if (!needToRemoveLayer && map.current.getSource('polygon')) {
      map.current
        .getSource('polygon')
        .setData(
          createGeoJSONCircle([customCoordinates.lng, customCoordinates.lat], 1)
            .data
        );
    }

    handleZoomByLatLong(customCoordinates.lat, customCoordinates.lng);
  }, [customCoordinates, customZoom]);

  const addAllZones = () => {
    const selectedFeatures = map.current.queryRenderedFeatures({
      layers: arrayLayerIdString.current,
    });

    selectedFeatures.map((item) => {
      return map.current.setFeatureState(
        { source: item.source, id: item.id },
        { clicked: true }
      );
    });
  };

  useEffect(() => {
    if (paintAll === true) {
      addAllZones();
      setPaintAll(false);
    }
  }, [paintAll]);

  const cleanMap = async () => {
    await Promise.all(
      arrayLayerIdString.current.map(async (item) => {
        return map.current.removeLayer(item);
      })
    );

    await Promise.all(
      arraySourceIdString.current.map(async (item) => {
        return map.current.removeSource(item);
      })
    );

    arrayLayerIdString.current = [];
    arraySourceIdString.current = [];
  };

  const handleCheckOption = async (option) => {
    if (viewportStyle !== option) {
      console.log('hi');
      setViewportStyle(option);
      // cleanMap();
      let getPrevFeatures = [];
      if (geoJson.features && geoJson.features.length) {
        getPrevFeatures = [...geoJson?.features];
      }
      await map.current.setStyle(option, { diff: false });
      setGeoJson({
        type: 'FeatureCollection',
        features: getPrevFeatures,
      });
      setUpdateMap((prevState) => !prevState);
    }
  };

  useEffect(() => {
    if (currentFeatureSelected) {
      handleZoomOnFeature(currentFeatureSelected);
      setCurrentFeatureSelected(null);
    }
  }, [currentFeatureSelected]);

  // useEffect(() => {
  //   if (geoJson) {
  //     console.log('mapWithLayers geojson: ', geoJson);
  //   }
  // }, [geoJson]);

  return (
    <Card
      width={width}
      height={height}
      style={{ marginBottom: '0' }}
      padding="0px"
      {...props}
    >
      {/* {hasOption && (
        <OptionsWrapper id="options" style={{ ...optionsWrapperStyles }}>
          {(hasViewportOptions || hasViewportOptionLight) && (
            <Option>
              <OptionImage
                checked={
                  viewportStyle ===
                  'mapbox://styles/thesoilcompany/cl1cxlp02000216p8pno155y7/'
                }
                image={light}
              >
                <StyledInput
                  id="light"
                  type="radio"
                  name="rtoggle"
                  value="light"
                  checked={
                    viewportStyle ===
                    'mapbox://styles/thesoilcompany/cl1cxlp02000216p8pno155y7/'
                  }
                  onClick={() =>
                    handleCheckOption(
                      'mapbox://styles/thesoilcompany/cl1cxlp02000216p8pno155y7/'
                    )
                  }
                />
              </OptionImage>
              <Description htmlFor="light">light</Description>
            </Option>
          )}

          {(hasViewportOptions || hasViewportOptionDark) && (
            <Option>
              <OptionImage
                checked={viewportStyle === 'mapbox://styles/mapbox/dark-v10'}
                image={dark}
              >
                <StyledInput
                  id="dark-v10"
                  type="radio"
                  name="rtoggle"
                  value="dark"
                  checked={viewportStyle === 'mapbox://styles/mapbox/dark-v10'}
                  onClick={() =>
                    handleCheckOption('mapbox://styles/mapbox/dark-v10')
                  }
                />
              </OptionImage>
              <Description htmlFor="dark-v10">dark</Description>
            </Option>
          )}

          {(hasViewportOptions || hasViewportOptionSatellite) && (
            <Option>
              <OptionImage
                checked={
                  viewportStyle ===
                  'mapbox://styles/mapbox/satellite-streets-v11'
                }
                image={satelite}
              >
                <StyledInput
                  id="satellite-streets-v11"
                  type="radio"
                  name="rtoggle"
                  value="satellite"
                  checked={viewportStyle === 'satellite-streets-v11'}
                  onClick={() =>
                    handleCheckOption(
                      'mapbox://styles/mapbox/satellite-streets-v11'
                    )
                  }
                />
              </OptionImage>
              <Description htmlFor="satellite-streets-v11">
                satélite
              </Description>
            </Option>
          )}

          {(hasViewportOptions || hasViewportOptionStreet) && (
            <Option>
              <OptionImage
                checked={viewportStyle === 'mapbox://styles/mapbox/streets-v11'}
                image={ruas}
              >
                <StyledInput
                  id="streets-v11"
                  type="radio"
                  name="rtoggle"
                  value="streets"
                  checked={
                    viewportStyle === 'mapbox://styles/mapbox/streets-v11'
                  }
                  onClick={() =>
                    handleCheckOption('mapbox://styles/mapbox/streets-v11')
                  }
                />
              </OptionImage>
              <Description htmlFor="streets-v11">ruas</Description>
            </Option>
          )}
        </OptionsWrapper>
      )} */}
      <StyledMap ref={mapContainer} height="100%" style={styleMap} />
      {hasButtonToCollections && (
        <ButtonToCollections
          type="button"
          borderHover="0"
          border="none"
          width="8%"
          onClick={goToCollections}
        >
          Coletas
        </ButtonToCollections>
      )}
      {hasButtonToSoilAnalysis && (
        <ButtonToCollections
          type="button"
          borderHover="0"
          border="none"
          width="13%"
          onClick={goToSoilAnalysis}
        >
          Análise de Solo
        </ButtonToCollections>
      )}
      {hasButtonToNewRecommendations && (
        <ButtonToNewRecommendations
          type="button"
          borderHover="0"
          border="none"
          width="13%"
          onClick={goToNewRecommendations}
        >
          Novas Recomendações
        </ButtonToNewRecommendations>
      )}
    </Card>
  );
};

MapWithLayers.defaultProps = {
  hasLayer: false,
  hasCollectionLayer: false,
  handleChange: () => {},
  getMapDrawFunctions: () => {},
  handleOpenTalhaoNameModal: () => {},
  width: '100%',
  height: 'calc(100% - 10px)',
  geoJson: {},
  hasSearchInput: false,
  hasDrawOptions: false,
  styleMap: {},
  paintOnClick: false,
  deletedZone: null,
  customZoom: 8,
  customCoordinates: {
    lat: -23.2927,
    lng: -51.1732,
  },
  needToRemoveLayer: false,
  setDeletedZone: () => null,
  callBackWhenClickLayer: () => {},
  hasButtonToNewRecommendations: false,
  hasButtonToCollections: false,
  hasButtonToSoilAnalysis: false,
  selectedZones: undefined,
  paintAll: false,
  setPaintAll: () => null,
};

export default MapWithLayers;
