import styled, { css } from 'styled-components';

// Components
import DropDownMenu from '../DropDownMenu';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  display: flex;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const UserPhoto = styled.div``;

export const Photo = styled.img`
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 12px;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  /* max-width: ${({ hasLastLogin }) => (hasLastLogin ? '100%' : '32rem')}; */

  overflow: hidden;
  ${(props) =>
    !props.isCollections &&
    css`
      width: 50%;

      @media (max-width: 1300px) {
        width: 90%;
      }
    `}
`;

export const TextsWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const WrapperNameTitle = styled.div`
  /* margin-top: ${({ hasLastLogin }) => (hasLastLogin ? 'inset' : '-16px')}; */
  display: inline-grid;
`;

export const WrapperSubTexts = styled.div`
  display: flex;
  align-items: center;
  display: inline-grid;

  div {
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.fadedText};
    border-radius: 50%;
    margin: 0 7px;
  }
`;

export const DividerBar = styled.div`
  width: 2px;
  height: 135px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 16px;

  @media (max-width: 1300px) {
    height: 2px;
    top: calc(50% - 1px);
    width: 100%;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.hasThreeDataColumns ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
  align-items: center;
  width: 100%;

  @media (max-width: 800px) {
    grid-template-columns: ${(props) =>
      props.hasThreeDataColumns ? '3fr 2fr 4fr' : '1fr 1fr'};
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;

    ${(props) =>
      props.hasThreeDataColumns &&
      `
        grid-template-columns: 1fr 1fr 1fr;

        div:nth-child(3n){
          grid-row-start: 2;
          grid-column-start: 1;
          grid-column-end: 3;
          justify-self: center;
        }
      `}
  }

  ${(props) =>
    props.hasSvg &&
    css`
      align-items: flex-start;
      margin-top: 1rem;
    `}
`;

export const BoldTextWrapper = styled.div`
  max-width: 300px;
  margin-top: 16px;
  display: inline-grid;

  @media (max-width: 800px) {
    margin-top: 4px;
  }

  ${(props) =>
    props.hasSvg &&
    css`
      height: 80px;
      margin-top: 0;
      display: flex;
      align-items: center;
    `}
`;

export const Content = styled.div`
  padding: 5px;
  margin-left: 16px;
`;

export const WrapperIconDropDownContent = styled.div`
  width: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 800px) {
    height: ${(props) => props.heightPartOfTopCard}px;
    right: 0;
  }

  @media (max-width: 1300px) {
    margin-right: 40px;
  }

  @media (max-width: 800px) {
    height: ${(props) => props.heightPartOfBottomCard}px;
    bottom: 0;
    right: 0;
  }
`;

export const IconContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;

export const StyledDropDown = styled(DropDownMenu)`
  position: absolute;
  top: calc(50% + 25px);
  right: calc(50% - 50px);

  @media (max-width: 800px) {
    right: calc(50% - 48px);
  }

  @media (max-width: 560px) {
    right: calc(50% - 45px);
  }

  @media (max-width: 480px) {
    right: calc(50% - 44px);
  }
`;

export const KMLIcon = styled.img`
  width: 16px;
  height: 16px;
`;
