import React, { forwardRef } from 'react';

// Motion
import { motion } from 'framer-motion';

// Styles
import theme from '@styles/theme';
import { CardContent } from './style';

const Card = forwardRef((props, ref) => {
  const {
    children,
    padding,
    width,
    height,
    hasHover,
    selected,
    colorWhenSelected,
    ...rest
  } = props;

  let hasOverObject = {};

  if (hasHover) {
    hasOverObject = {
      backgroundColor: theme.colors.lightGray,
      borderColor: theme.colors.lightGray,
    };
  }

  return (
    <CardContent
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      as={motion.div}
      transition={{
        delayChildren: 1,
        opacity: { ease: 'linear' },
        layout: { duration: 0.3 },
      }}
      padding={padding}
      width={width}
      height={height}
      selected={selected}
      hasHover={hasHover}
      colorWhenSelected={colorWhenSelected}
      ref={ref}
      {...rest}
    >
      {children}
    </CardContent>
  );
});

Card.defaultProps = {
  padding: '24px',
  width: 'auto',
  height: 'max-content',
  selected: false,
  hasHover: false,
  colorWhenSelected: theme.colors.fadedIcon,
};

export default Card;
