import styled from 'styled-components';

export const StyledMap = styled.div`
  border-radius: 12px;
  /* width: 85vw; */
  height: ${({ height }) => height};
  z-index: 0;

  & .mapboxgl-canvas {
    height: 100% !important;
    width: 100% !important;
  }
`;
