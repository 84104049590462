import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import useSupplies from '@hooks/useSupplies';
import useAuth from '@hooks/Auth';

// Theme
import theme from '@styles/theme';

// Assets
import { ReactComponent as FilterIcon } from '@assets/svg/icons/filter.svg';
import { ReactComponent as CardViewIcon } from '@assets/svg/icons/change_view.svg';
import { ReactComponent as ListViewIcon } from '@assets/svg/icons/menu2.svg';
import { ReactComponent as SearchIcon } from '@assets/svg/icons/search.svg';

// Components
import Input from '../Input';
import Button from '../Button';
import Title from '../Title';
import MenuButton from '../MenuButton';

// Styles
import {
  StyledSubHeader,
  FilterWrapper,
  Bar,
  WrapperIcons,
  WrapperLeftSide,
  WrapperRightSide,
  IconButtonWrapper,
  ContainerButtons,
  ButtonCreatedByAdmin,
  ButtonCreatedByPartner,
} from './style';

const SubHeader = ({
  modeOfView,
  handleView,
  handleRightDrawer,
  onClickSearchButton,
  handleSearch,
  handleLoadNewData,
  handleResetListOfData,
  setIsSearching,
  isSearching,
  search,
  setSearch,
  hasFilter = true,
  hasOtherViewWay = true,
  buttonRightText = 'Adicionar novo',
  isSupplies = false,
  isCollections = false,
  hasNewItemButton = true,
}) => {
  const [timer, setTimer] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const { createdBy, setCreatedBy } = useSupplies();

  const { userInfo } = useAuth();

  const handleChange = (e) => {
    const newString = search + e.target.value;
    setSearch(newString);
  };

  const handleSelect = (type) => {
    if (createdBy === type) {
      setCreatedBy('');
    } else {
      setCreatedBy(type);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      if (search.length > 0) {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }

        setTimer(
          setTimeout(() => {
            setIsSearching(true);
            handleSearch();
          }, 500)
        );
      } else if (isSearching) {
        handleResetListOfData();
        setIsSearching(false);
        handleLoadNewData();
      }
    }
  }, [search]);

  return (
    <StyledSubHeader>
      <WrapperLeftSide>
        {isMobile ? (
          <IconButtonWrapper onClick={onClickSearchButton}>
            <SearchIcon width="20px" height="20px" />
          </IconButtonWrapper>
        ) : (
          <Input
            hasBorder={false}
            background={theme.colors.lightGray}
            height="40px"
            label=""
            placeholder="pesquise por cidade, nome, CNPJ..."
            onClick={handleSearch}
            endIcon={<SearchIcon />}
            value={search}
            onChange={handleChange}
          />
        )}
        {hasFilter && (
          <FilterWrapper>
            <FilterIcon />
            {!isMobile && (
              <Title fontSize="1rem" letterSpacing="0" width="100%">
                Filtros
              </Title>
            )}
          </FilterWrapper>
        )}

        {isSupplies && (
          <ContainerButtons>
            <ButtonCreatedByAdmin
              selected={createdBy === 'admin'}
              onClick={() => handleSelect('admin')}
            >
              Banco Geral
            </ButtonCreatedByAdmin>
            <ButtonCreatedByPartner
              selected={createdBy === 'partner'}
              onClick={() => handleSelect('partner')}
            >
              {userInfo?.userGroup === 'ADMINISTRADOR'
                ? 'Banco Parceiro'
                : 'Meu Banco'}
            </ButtonCreatedByPartner>
          </ContainerButtons>
        )}
      </WrapperLeftSide>
      {!isCollections && (
        <WrapperRightSide>
          {isMobile && hasNewItemButton ? (
            <Button
              border="none"
              width="3rem"
              height="40px"
              boxShadow="none"
              type="submit"
              borderHover="0"
              icon
              onClick={handleRightDrawer}
            />
          ) : (
            hasNewItemButton && (
              <Button
                border="none"
                width="10rem"
                height="40px"
                boxShadow="none"
                type="submit"
                borderHover="0"
                icon
                onClick={handleRightDrawer}
              >
                {buttonRightText}
              </Button>
            )
          )}

          {hasOtherViewWay && !isMobile && hasNewItemButton && <Bar />}
          {isSupplies && isMobile ? (
            <WrapperIcons>
              <MenuButton
                isSelected={modeOfView === 'compact'}
                onClick={() => handleView('compact')}
              >
                <CardViewIcon />
              </MenuButton>
            </WrapperIcons>
          ) : (
            hasOtherViewWay && (
              <WrapperIcons>
                <MenuButton
                  isSelected={modeOfView === 'compact'}
                  onClick={() => handleView('compact')}
                >
                  <CardViewIcon />
                </MenuButton>
                <MenuButton
                  isSelected={modeOfView === 'large'}
                  onClick={() => handleView('large')}
                >
                  <ListViewIcon />
                </MenuButton>
              </WrapperIcons>
            )
          )}
        </WrapperRightSide>
      )}
    </StyledSubHeader>
  );
};

export default SubHeader;
