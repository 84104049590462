import React from 'react';

// Theme
import theme from '@styles/theme';

// Styles
import {
  BarWrapper,
  Bar,
  PorcentageWrapper,
  StepsWrapper,
  Steps,
  Container,
  ActiveStep,
  InactiveStep,
  Point,
  StepItem,
} from './style';

const ProgressBar = ({ width, steps, stepsColor, actualStep }) => {
  const StepsComponents = new Array(steps).fill().map((_, idx) => {
    return { text: `Etapa ${idx + 1}`, step: idx + 1 };
  });

  return (
    <Container>
      <StepsWrapper stepsColor={stepsColor}>
        <Steps stepsColor={stepsColor}>
          {StepsComponents.map((item) =>
            item.step === actualStep ? (
              <StepItem key={item.step}>
                <ActiveStep stepsColor={theme.colors.mainText}>
                  {item.text}
                </ActiveStep>
                {item.step < steps && <Point stepsColor={stepsColor} />}
              </StepItem>
            ) : (
              <StepItem key={item.step}>
                <InactiveStep stepsColor={theme.colors.translucentText}>
                  {item.text}
                </InactiveStep>
                {item.step < steps && <Point stepsColor={stepsColor} />}
              </StepItem>
            )
          )}
        </Steps>
        <PorcentageWrapper>
          <span>{width}</span>
        </PorcentageWrapper>
      </StepsWrapper>
      <BarWrapper>
        <Bar width={width} />
      </BarWrapper>
    </Container>
  );
};

ProgressBar.defaultProps = {
  width: '11%',
  steps: 3,
  stepsColor: theme.colors.mainText,
  actualStep: 1,
};

export default ProgressBar;
