import React from 'react';
import ReactLoading from 'react-loading';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as S from './style';

export const LoadingContainer = ({ color }) => {
  const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: color,
        darker: '#053e85',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
    },
  });
  return (
    <S.Wrapper>
      <S.InvisibleBackground />
      <ThemeProvider theme={theme}>
        <CircularProgress color="primary" />
      </ThemeProvider>
    </S.Wrapper>
  );
};
