import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

// Theme
import Tooltip from '@mui/material/Tooltip';
import theme from '@styles/theme';

// Assets
import { ReactComponent as Menu } from '@assets/svg/icons/menu.svg';
import { ReactComponent as Logout } from '@assets/svg/icons/logout.svg';

// Hooks
import useAuth from '@hooks/Auth';

// Style
import {
  Wrapper,
  LogoLink,
  StyledLogo,
  Divider,
  IconButton,
  WrapperMiddleButtons,
  WrapperBottomButtons,
  StyledDetailMenu,
  Line,
  WrapperAnimateDetail,
  BlurredBackground,
} from './style';

// Component
import OpenedMenu from './components/openedMenu';

const SideBarMenu = ({ pathname, menuOptions, sideMenuOptions }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { handleLogout } = useAuth();

  const handleMenu = () => setMenuVisible((prevState) => !prevState);

  const bottomOptions = [
    {
      icon: <Menu />,
      colors: {
        fill: menuVisible ? theme.colors.soilOrange : theme.colors.fadedIcon,
        hover: theme.colors.soilOrange,
      },
      onClick: handleMenu,
      id: 100,
      title: 'Menu',
    },
    {
      icon: <Logout />,
      colors: {},
      onClick: handleLogout,
      id: 101,
      title: 'Sair',
    },
  ];

  const needFill = (item) => {
    if (
      item.title === 'Insumos' ||
      item.title === 'Parceiros' ||
      item.title === 'Recomendações' ||
      item.title === 'Clientes'
    )
      return true;

    return false;
  };

  return (
    <>
      <Wrapper>
        <LogoLink to="/">
          <StyledLogo alt="logo" />
        </LogoLink>
        <Divider />

        <WrapperMiddleButtons>
          {menuOptions.map((item) => (
            <Tooltip
              title={item?.title}
              key={crypto.randomUUID()}
              placement="right"
            >
              <Line>
                <AnimatePresence>
                  {pathname === item.to && (
                    <WrapperAnimateDetail
                      key={`leftDrawerIconDetail${item.id}`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <StyledDetailMenu />
                    </WrapperAnimateDetail>
                  )}
                </AnimatePresence>
                <IconButton
                  to={item.to}
                  fill={
                    needFill(item) === true ? item.colors.stroke : undefined
                  }
                  {...item.colors}
                >
                  {item.icon}
                </IconButton>
              </Line>
            </Tooltip>
          ))}
        </WrapperMiddleButtons>

        <WrapperBottomButtons>
          {bottomOptions.map((item) => (
            <Tooltip title={item?.title} key={item.id} placement="right">
              <Line key={item.id}>
                <AnimatePresence>
                  {pathname === item.to && (
                    <WrapperAnimateDetail
                      key={`leftDrawerIconDetail${item.id}`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <StyledDetailMenu />
                    </WrapperAnimateDetail>
                  )}
                </AnimatePresence>
                <IconButton onClick={item.onClick} as="button" {...item.colors}>
                  {item.icon}
                </IconButton>
              </Line>
            </Tooltip>
          ))}
        </WrapperBottomButtons>
      </Wrapper>

      <OpenedMenu
        handleMenu={handleMenu}
        open={menuVisible}
        options={sideMenuOptions}
      />

      {menuVisible && (
        <BlurredBackground
          onClick={handleMenu}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          open={menuVisible}
        />
      )}
    </>
  );
};

export default SideBarMenu;
