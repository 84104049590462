import styled from 'styled-components';

// Components
import Card from '../Card';

export const StyledCard = styled(Card)`
  width: ${({ width }) => width};
  margin-right: 0px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottomGraphHeader }) => marginBottomGraphHeader};

  @media (max-width: 728px) {
    flex-direction: column;
  }
`;

// Calendar Header Information

export const CardCalendar = styled.div`
  padding: 6px;
  gap: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 264px;
  height: 40px;
  background: #f8f8f8;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 480px) {
    width: 182px;
  }
`;

export const DataText = styled.h3`
  color: ${({ theme }) => theme.colors.fadedText};
  white-space: nowrap;
  font-size: 1rem;
  margin-top: 3px;
`;
