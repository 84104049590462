import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const StepsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
`;

export const Steps = styled.div`
  max-width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Point = styled.div`
  margin-right: 8px;
  width: 4px;
  height: 4px;
  background-color: ${(props) => props.stepsColor};
  border-radius: 50%;
`;

export const ActiveStep = styled.h3`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${(props) => props.stepsColor};
  white-space: nowrap;
  margin-right: 15px;
`;

export const InactiveStep = styled.h3`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${(props) => props.stepsColor};
  white-space: nowrap;
  margin-right: 15px;
`;

export const StepItem = styled.div`
  display: flex;
  align-items: center;
`;

export const PorcentageWrapper = styled.div`
  background: ${({ theme }) => theme.colors.darkBrown};
  width: 50px;
  height: 25px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: bold;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.soilOrange};
  }
`;

export const BarWrapper = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${({ theme }) => theme.colors.translucentText};
  border-radius: 16px;
`;

export const Bar = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.soilOrange};
  border-radius: 16px;
  transition: 0.5s ease-in-out;
`;
