import React from 'react';

// Theme
import theme from '@styles/theme';

// Styles
import { StyledSubtitle } from './style';

const Subtitle = ({
  children,
  color,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  ...props
}) => {
  return (
    <StyledSubtitle
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      {...props}
    >
      {children}
    </StyledSubtitle>
  );
};

Subtitle.defaultProps = {
  children: 'Subtitle',
  color: theme.colors.fadedIcon,
  fontSize: '0.75rem',
  fontWeight: 'normal',
  letterSpacing: '0',
  lineHeight: '8px',
};

export default Subtitle;
