import React from 'react';
import TopLoader from 'react-top-loader';
import * as S from './style';

const Loading = () => {
  return (
    <S.Wrapper>
      <S.Text>Carregando...</S.Text>
      <S.Bar>
        <TopLoader
          thickness={3}
          backgroundColor="#eee6ff"
          show
          fixed={false}
          color="#EB612C"
          duration={1000}
        />
      </S.Bar>
    </S.Wrapper>
  );
};

export default Loading;
