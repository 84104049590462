/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import useCollections from '@hooks/useCollections';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { ReactSVG } from 'react-svg';

import Tooltip from '@mui/material/Tooltip';

// Assets
import { ReactComponent as MoreInfoIcon } from '@assets/svg/icons/more_info.svg';
import { ReactComponent as DeleteIcon } from '@assets/svg/icons/trash.svg';
import { ReactComponent as EditIcon } from '@assets/svg/icons/pencil.svg';
import { ReactComponent as InfoIcon } from '@assets/svg/icons/paper_search.svg';
import { ReactComponent as BiMapAlt } from '@assets/svg/icons/map.svg';
import KMLImage from '@assets/kml.png';
import farm from '@assets/farm.png';
import managementZone from '@assets/managementZone.png';

// Theme
import theme from '@styles/theme';

// Components
import Card from '../Card';
import Title from '../Title';
import Subtitle from '../Subtitle';

// import DropDownMenu from '../DropDownMenu';

// Styles
import {
  Wrapper,
  UserWrapper,
  UserPhoto,
  TextsWrapper,
  Photo,
  WrapperSubTexts,
  DividerBar,
  InfoWrapper,
  Content,
  BoldTextWrapper,
  IconContent,
  Icon,
  WrapperNameTitle,
  StyledDropDown,
  WrapperIconDropDownContent,
  KMLIcon,
} from './style';

const InfoLargeCard = ({
  hasLastLogin,
  widthCard,
  contentCard,
  handleOpenEditUser,
  handleOpenUserInfo,
  handleOpenDeleteUser,
  handleOpenSoilAnalysisScreen,
  info,
  idx,
  lastLogin,
  isCollections,
  selected,
  mapRoute,
  hasThreeDataColumns,
  hasDropDownReduced,
  icon,
  hasGenerateKML,
  handleGenerateKML,
}) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const refPartTopCard = useRef(null);
  const refPartBottomCard = useRef(null);
  const [heightPartOfTopCard, setheightPartOfTopCard] = useState(0);
  const [heightPartOfBottomCard, setheightPartOfBottomCard] = useState(0);

  const formatDateLastLogin = new Date(lastLogin).toLocaleDateString('pt-BR');

  const { addProducerAndFarm } = useCollections();
  const navigate = useNavigate();
  const handleDropDown = () => {
    setOpenDropDown((prevState) => !prevState);
  };
  const DropDownMenuOption = !isCollections && [
    {
      icon: <EditIcon />,
      label: 'Editar',
      onClick: handleOpenEditUser(idx),
    },
    {
      icon: <DeleteIcon />,
      label: 'Deletar',
      onClick: handleOpenDeleteUser(idx),
    },
    // {
    //   icon: <InfoIcon />,
    //   label: 'Informações',
    //   onClick: handleOpenUserInfo(idx),
    // },
    {
      icon: <BiMapAlt />,
      label: 'Ver no mapa',
      onClick: handleOpenSoilAnalysisScreen(idx),
    },
  ];

  const DropDownMenuOptionReduced = !isCollections && [
    {
      icon: <EditIcon />,
      label: 'Editar',
      onClick: handleOpenEditUser(idx),
    },
    {
      icon: <DeleteIcon />,
      label: 'Deletar',
      onClick: handleOpenDeleteUser(idx),
    },
    hasGenerateKML && {
      icon: <KMLIcon src={KMLImage} alt="KML" />,
      label: 'Gerar KML',
      onClick: handleGenerateKML(info.id, info.nome),
    },
  ];

  const getNewSizeOfCard = () => {
    if (refPartTopCard.current) {
      setheightPartOfTopCard(refPartTopCard.current.clientHeight);
    }

    if (refPartBottomCard.current) {
      setheightPartOfBottomCard(refPartBottomCard.current.clientHeight);
    }
  };

  useEffect(() => {
    getNewSizeOfCard();
  }, []);

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener('resize', getNewSizeOfCard);
  }, []);

  return (
    <Card
      style={{ flexGrow: 1, width: '100%' }}
      width={widthCard}
      padding="0"
      selected={selected}
      hasHover
    >
      <Wrapper>
        <UserWrapper
          ref={refPartTopCard}
          hasLastLogin={hasLastLogin}
          onClick={
            isCollections
              ? () => addProducerAndFarm(info)
              : handleOpenUserInfo(idx)
          }
          isCollections={isCollections}
        >
          <UserPhoto>
            <Photo
              src={
                info?.imageLink ||
                (icon === 'farm'
                  ? farm
                  : icon === 'managementZone'
                  ? managementZone
                  : 'https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png')
              }
            />
          </UserPhoto>
          {!isCollections && (
            <TextsWrapper>
              {hasLastLogin && formatDateLastLogin !== 'Invalid Date' && (
                <Subtitle
                  fontSize="0.6rem"
                  letterSpacing="2px"
                  fontWeight="bold"
                  lineHeight="1rem"
                  color={theme.colors.grayText}
                >
                  ÚLTIMO LOGIN EM {formatDateLastLogin}
                </Subtitle>
              )}
              <WrapperNameTitle hasLastLogin={hasLastLogin}>
                <Title
                  fontSize="1.4rem"
                  letterSpacing="2px"
                  lineHeight={hasLastLogin ? '2.5rem' : '1.8rem'}
                  whiteSpace={hasLastLogin ? 'nowrap' : 'normal'}
                >
                  {info.nome}
                </Title>
              </WrapperNameTitle>
              <WrapperSubTexts>
                <Subtitle fontSize="0.8rem" lineHeight="1rem">
                  {info.cidade}
                  {info.estado ? ',' : ''} {info.estado} {info.pais ? '- ' : ''}
                  {info.pais}
                </Subtitle>
              </WrapperSubTexts>
            </TextsWrapper>
          )}
        </UserWrapper>
        {!isCollections && <DividerBar />}
        <InfoWrapper
          ref={refPartBottomCard}
          hasThreeDataColumns={hasThreeDataColumns}
          hasSvg={info.imagemPreview}
          onClick={
            isCollections
              ? () => addProducerAndFarm(info)
              : handleOpenUserInfo(idx)
          }
        >
          {contentCard.map((item) => (
            <Content key={item.label}>
              <Subtitle
                fontSize="0.8rem"
                color={theme.colors.border}
                lineHeight="1rem"
              >
                {item.label}
              </Subtitle>
              <BoldTextWrapper hasSvg={info.imagemPreview}>
                {item.label === 'Contorno' ? (
                  item.value ? (
                    <ReactSVG src={item.value} />
                  ) : (
                    <Title fontSize="1rem" letterSpacing="0">
                      -
                    </Title>
                  )
                ) : (
                  <Title fontSize="1rem" letterSpacing="0">
                    {item.value}
                  </Title>
                )}
              </BoldTextWrapper>
            </Content>
          ))}
        </InfoWrapper>
        {!isCollections && (
          <WrapperIconDropDownContent
            heightPartOfTopCard={heightPartOfTopCard}
            heightPartOfBottomCard={heightPartOfBottomCard}
          >
            <IconContent onClick={handleDropDown}>
              <Tooltip title="Ações" placement="bottom">
                <Icon>
                  <MoreInfoIcon />
                </Icon>
              </Tooltip>
            </IconContent>
            <AnimatePresence>
              {openDropDown && (
                <StyledDropDown
                  handleClose={handleDropDown}
                  options={
                    hasDropDownReduced
                      ? DropDownMenuOptionReduced
                      : DropDownMenuOption
                  }
                />
              )}
            </AnimatePresence>
          </WrapperIconDropDownContent>
        )}
      </Wrapper>
    </Card>
  );
};

InfoLargeCard.defaultProps = {
  widthCard: 'auto',
  firstLabel: 'CPF',
  lastLogin: '26/06/2021 ÁS 15:35',
  secondLabel: 'Telefone',
  thirdLabel: 'Empresa Vinculada',
  icon: 'user',
  isCollections: false,
  selected: false,
  hasLastLogin: true,
  hasThreeDataColumns: false,
  isListOfUsers: false,
  hasDropDownReduced: false,
  hasGenerateKML: false,
  handleGenerateKML: () => {},
  handleOpenEditUser: () => {},
  handleOpenUserInfo: () => {},
  handleOpenDeleteUser: () => {},
  handleOpenSoilAnalysisScreen: () => {},
};

export default InfoLargeCard;
