import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';

// Styles
import {
  Wrapper,
  BlurredBackground,
  Header,
  Title,
  ButtonWrapper,
  WrapperContent,
  StyledCloseIcon,
} from './style';

const Modal = ({
  open,
  key,
  width,
  title,
  children,
  CloseOnClick,
  hideClose,
  ...props
}) => {
  const [height, setHeight] = useState(300);
  const refModal = useRef(null);

  useEffect(() => {
    if (refModal.current) {
      setHeight(refModal.current.clientHeight);
    }
  }, [open]);

  return (
    <>
      {open && <BlurredBackground onClick={CloseOnClick} />}
      <AnimatePresence
        key={key}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {open && (
          <Wrapper width={width} ref={refModal} height={height / 2} {...props}>
            <Header>
              <Title>{title}</Title>
              {!hideClose && (
                <ButtonWrapper onClick={CloseOnClick}>
                  <StyledCloseIcon />
                </ButtonWrapper>
              )}
            </Header>
            <WrapperContent>{children}</WrapperContent>
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
};

Modal.defaultProps = {
  open: true,
  key: 'teste',
  width: '60vw',
  title: 'Título Aqui...',
  children: <p>conteudo aqui ...</p>,
  hideClose: false,
};

export default Modal;
