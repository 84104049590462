import React, { useState, useEffect } from 'react';

// Assets
import { ReactComponent as DeleteIcon } from '@assets/svg/icons/trash.svg';
import { ReactComponent as EditIcon } from '@assets/svg/icons/pencil.svg';

// Components
// eslint-disable-next-line import/no-unresolved
import { ModalEdit } from '@components/Modal/components/ModalEdit';

// Styles
import { Tooltip } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import * as S from './style';

const ScaleCard = ({
  scale,
  deleteItems,
  handleConfirm,
  handleOpenEditModal,
  handleCloseEditModal,
  openEditModal,
  selectedElement,
}) => {
  const [scales, setScales] = useState(scale);

  useEffect(() => {
    setScales(scale);
  }, [scale]);

  return scales ? (
    scales.map((scaled, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <S.Container key={idx}>
        <S.ItemContainer>
          <S.Label>Min</S.Label>
          <S.NumberContent>
            {scaled.graphInit || scaled.inicioValor}
          </S.NumberContent>
        </S.ItemContainer>

        <S.ItemContainer>
          <S.Label>Max</S.Label>
          <S.NumberContent>
            {scaled.graphEnd || scaled.fimValor}
          </S.NumberContent>
        </S.ItemContainer>

        <S.ItemContainer>
          <S.Label>Cor</S.Label>
          <S.ColorContent color={scaled.color || scaled.cor} />
        </S.ItemContainer>

        <S.ItemContainer>
          <Tooltip title="Editar" placement="bottom">
            <S.Icons onClick={() => handleOpenEditModal(idx)}>
              <EditIcon size={24} />
            </S.Icons>
          </Tooltip>
        </S.ItemContainer>

        <S.ItemContainer>
          <Tooltip title="Deletar" placement="bottom">
            <S.Icons onClick={() => deleteItems(idx)}>
              <DeleteIcon size={24} />
            </S.Icons>
          </Tooltip>
        </S.ItemContainer>
      </S.Container>
    ))
  ) : (
    <p>loading</p>
  );
};

export default ScaleCard;
