import React from 'react';
import { AnimatePresence } from 'framer-motion';

// Style
import {
  Wrapper,
  WrapperTop,
  Title,
  CloseIcon,
  LineTitle,
  TitleOption,
  WrapperOption,
  CloseButton,
} from './style';
import { Divider } from '../style';

const OpenedMenu = ({ open, handleMenu, options }) => {
  return (
    <AnimatePresence>
      {open && (
        <Wrapper
          key="secondRightDrawer"
          initial={{ x: -302, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -302, opacity: 0 }}
          open={open}
        >
          <WrapperTop>
            <Title>Menu</Title>
            <CloseButton onClick={handleMenu}>
              <CloseIcon />
            </CloseButton>
          </WrapperTop>
          <Divider />
          {options.map((item) => (
            <WrapperOption key={item.section}>
              <LineTitle {...item.colors} to={item.to}>
                {item.icon}
                <TitleOption>{item.section}</TitleOption>
              </LineTitle>
              <Divider />
            </WrapperOption>
          ))}
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default OpenedMenu;
