import React from 'react';

// Styles
import { Card } from './style';

const CardSkeleton = ({ width, height, borderRadius, ...props }) => {
  return (
    <Card
      width={width}
      height={height}
      borderRadius={borderRadius}
      {...props}
    />
  );
};

CardSkeleton.defaultProps = {
  width: '300px',
  height: '300px',
  borderRadius: '0px',
};

export default CardSkeleton;
