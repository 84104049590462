import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  display: flex;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const UserPhoto = styled.div``;

export const Photo = styled.img`
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 12px;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  max-width: ${({ hasLastLogin }) => (hasLastLogin ? '100%' : '32rem')};

  overflow: hidden;

  /* width: 50%; usar assim quando o grafico estiver mostrando */
  flex: 1; /* sem o gráfico */

  @media (max-width: 1300px) {
    width: 90%;
  }
`;

export const TextsWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const WrapperNameTitle = styled.div`
  display: inline-grid;
`;

export const WrapperSubTexts = styled.div`
  display: flex;
  align-items: center;
  display: inline-grid;

  div {
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.fadedText};
    border-radius: 50%;
    margin: 0 7px;
  }
`;

export const DividerBar = styled.div`
  width: 2px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 16px;

  @media (max-width: 1300px) {
    height: 2px;
    top: calc(50% - 1px);
    width: 100%;
  }
`;

export const InfoWrapper = styled.div`
  /* width: 100%; usar quando o grafico estiver mostrando */
  width: 30%; /* sem o gráfico */

  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  @media (max-width: 1910px) {
    padding-top: 1rem;
  }

  @media (max-width: 1300px) {
    width: 100%; /* sem o gráfico */
  }
`;

export const BoldTextWrapper = styled.div`
  max-width: 300px;
  margin-top: 16px;
  display: inline-grid;

  @media (max-width: 800px) {
    margin-top: 4px;
  }
`;

export const Content = styled.div`
  padding: 5px;
  margin-left: 16px;

  /* &:last-child {
    flex: 1;
  } usar com o gráfico */
`;
