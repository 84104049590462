import { formatNumberToString } from '@utils/index';
import React from 'react';

// Components
import Card from '../Card';
import Subtitle from '../Subtitle';
import Title from '../Title';

// Style
import {
  SubtitleContent,
  Dot,
  StyledDetailPorcentage,
  TextPercentage,
  Container,
  WrapperInfo,
  WrapperPorcentage,
} from './style';

const UDPSCards = ({ title, range, lastRes, percentage }) => {
  const formatedArea = formatNumberToString(range);
  return (
    <Card style={{ padding: '32px' }}>
      <Container>
        <WrapperInfo>
          <Title
            fontSize="1.5rem"
            lineHeight="auto"
            letterSpacing="0"
            style={{ marginTop: '-14px' }}
          >
            {title}
          </Title>
          <SubtitleContent>
            <Subtitle fontSize="1rem">{formatedArea} ha</Subtitle>
            <Dot />
            <Subtitle fontSize="1rem">{lastRes}</Subtitle>
          </SubtitleContent>
        </WrapperInfo>
        <WrapperPorcentage>
          <StyledDetailPorcentage percentage={percentage} />
          <TextPercentage percentage={percentage}>{percentage}%</TextPercentage>
        </WrapperPorcentage>
      </Container>
    </Card>
  );
};

UDPSCards.defaultProps = {
  title: 'UDP Área do Campo II',
  range: '521ha',
  lastRes: 'Último Res. 12/02/21',
  percentage: 96,
};

export default UDPSCards;
