/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import api from '@services/api';
import { formatMessagesErrors } from '@utils/index';

// Components
import Accordion from '../Accordion';
import Input from '../Input';
import Button from '../Button';
import Select from '../Select';
import ColorPicker from '../ColorPicker';
// eslint-disable-next-line import/no-cycle
import ScaleCard from '../ScaleCard';
// eslint-disable-next-line import/default
import ModalEdit from '../Modal/components/ModalEdit';

// Style
// eslint-disable-next-line import/no-cycle
import {
  InputWrapper,
  Wrapper,
  WrapperHeader,
  Username,
  ListOfScales,
  ButtonAdd,
  StyledModal,
} from './style';

export const HeaderEditElement = () => {
  return (
    <WrapperHeader>
      <Username>Editar Elemento</Username>
    </WrapperHeader>
  );
};

const EditElement = ({
  width,
  element,
  handleOpenModal,
  handleClose,
  handleUpdateListOfElementsAfterAddOrEditElement,
}) => {
  const [open, setOpen] = useState(false);
  const [scales, setScales] = useState(element.elementsRangeValuesResponses);
  const [colorPicked, setColorPicked] = useState('#05036b');
  const [graphInit, setGraphInit] = useState('');
  const [graphEnd, setGraphEnd] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModalEdit, setIsLoadingModalEdit] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedElement, setSelectedElement] = useState(0);
  const handleOpen = () => setOpen((prevState) => !prevState);

  const { enqueueSnackbar } = useSnackbar();

  const getReportType = (reportType) => {
    switch (reportType) {
      case 'SO1':
        return 1;
      case 'SO3':
        return 2;
      case 'SO6':
        return 3;
      case 'TV1':
        return 4;
      default:
        return 1;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: element.id,
      code: element.codigo,
      name: element.nome,
      reportType: getReportType(element.tipoLaudo),
      unity: element.unidade,
      scale: element.elementsRangeValuesResponses,
      color: element.cor,
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const { data } = await api.put('/Element/Alter', {
          id: values.id,
          codigo: values.code,
          nome: values.name,
          tipoLaudo: values.reportType,
          unidade: values.unity,
          elementsRangeValues: values.scale,
          cor: values.color,
        });
        handleClose();
        handleOpenModal();
        handleUpdateListOfElementsAfterAddOrEditElement();

        enqueueSnackbar('Elemento editado com sucesso!', {
          variant: 'success',
        });
      } catch (error) {
        const messages = formatMessagesErrors(error);

        messages.forEach((message) => {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleChangeColor = (color) => {
    formik.setFieldValue('color', color.hex);
  };

  const handleChangeColorPicked = (color) => {
    setColorPicked(color.hex);
  };

  const handleSaveColor = () => {
    const oldScales = [...scales];

    const newScale = {
      // id: Math.floor(Date.now() * Math.random()).toString(36),
      inicioValor: graphInit,
      fimValor: graphEnd,
      cor: colorPicked,
    };

    oldScales.push(newScale);

    const scalesFormik = [];

    oldScales.map((scale) => {
      return scalesFormik.push({
        id: 1,
        inicioValor: scale.graphInit || scale.inicioValor,
        fimValor: scale.graphEnd || scale.fimValor,
        cor: scale.color || scale.cor,
      });
    });

    formik.setFieldValue('scale', scalesFormik);

    setScales(oldScales);
    setGraphInit('');
    setGraphEnd('');
    setColorPicked('#05036b');
  };

  const deleteItems = (id) => {
    const newScales = scales.filter((_, i) => i !== id);
    setScales(newScales);
    formik.setFieldValue('scale', newScales);
  };

  const handleOpenEditModal = (index) => {
    setSelectedElement(index);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleConfirm = (updatedGraphInit, updatedGraphEnd, updatedColor) => {
    const selectFormik = [...scales];
    scales.map((scaled, index) => {
      if (index === selectedElement) {
        selectFormik[index] = {
          ...scaled,
          inicioValor: updatedGraphInit,
          fimValor: updatedGraphEnd,
          cor: updatedColor,
        };
      }
      return selectFormik[index];
    });
    formik.setFieldValue('scale', selectFormik);
    setScales(selectFormik);
    setOpenEditModal(false);
  };

  return (
    <Wrapper onSubmit={formik.handleSubmit}>
      <Accordion
        keyAnimator="accordion_basics_informations"
        title="Informações Básicas"
        width={width}
        open={open}
        handleOpen={handleOpen}
      >
        <InputWrapper>
          <Input
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            label="Nome"
            width="100%"
            placeholder="Digite um nome..."
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            id="code"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            label="Código"
            width="50%"
            placeholder="Digite o código..."
          />
          <Input
            id="unity"
            name="unity"
            value={formik.values.unity}
            onChange={formik.handleChange}
            label="Unidade"
            width="50%"
            placeholder="Digite a unidade..."
          />
        </InputWrapper>
        <InputWrapper>
          <Select
            id="reportType"
            name="reportType"
            value={formik.values.reportType}
            onChange={formik.handleChange}
            label="Tipo do Laudo"
            width="100%"
            data={[
              { id: 1, name: 'Química', value: 'SO1' },
              { id: 2, name: 'Física', value: 'SO3' },
              { id: 3, name: 'Biológica', value: 'SO6' },
              { id: 4, name: 'Tecido Vegetal', value: 'TV1' },
            ]}
          />
        </InputWrapper>
        <InputWrapper />
        <InputWrapper>
          <ColorPicker
            color={formik.values.color}
            handleChange={handleChangeColor}
            width="30%"
          />
        </InputWrapper>
      </Accordion>

      <Accordion
        keyAnimator="accordion_scale"
        title="Escala"
        width={width}
        open={open}
        handleOpen={handleOpen}
      >
        <InputWrapper>
          <Input
            id="min"
            name="min"
            value={graphInit}
            onChange={(e) => setGraphInit(e.target.value)}
            label="Min"
            width="35%"
            placeholder="Digite o valor mínimo..."
          />
          <Input
            id="max"
            name="max"
            value={graphEnd}
            onChange={(e) => setGraphEnd(e.target.value)}
            label="Max"
            width="35%"
            placeholder="Digite o valor máximo..."
          />
          <ColorPicker
            color={colorPicked}
            handleChange={handleChangeColorPicked}
            width="30%"
            isScale
          />
        </InputWrapper>

        <ButtonAdd
          width="100%"
          type="button"
          borderHover="0"
          border="none"
          isLoading={isLoading}
          onClick={handleSaveColor}
        >
          Adicionar
        </ButtonAdd>

        <ListOfScales>
          {scales.length > 0 && (
            <ScaleCard
              scale={scales}
              deleteItems={deleteItems}
              handleConfirm={handleConfirm}
              openEditModal={openEditModal}
              selectedElement={selectedElement}
              handleOpenEditModal={handleOpenEditModal}
              handleCloseEditModal={handleCloseEditModal}
            />
          )}
          {scales.length === 0 && <span>Sem Lista</span>}
        </ListOfScales>
      </Accordion>
      {/* Criando Modal para Edit de escalas */}
      <StyledModal
        open={openEditModal}
        width="25vw"
        title="Editar"
        CloseOnClick={handleCloseEditModal}
      >
        <ModalEdit
          onConfirm={handleConfirm}
          onCancel={handleCloseEditModal}
          isLoading={isLoadingModalEdit}
          escala={scales}
          selectedElement={selectedElement}
        />
      </StyledModal>
      <Button
        width="100%"
        type="submit"
        borderHover="0"
        border="none"
        isLoading={isLoading}
      >
        Salvar
      </Button>
    </Wrapper>
  );
};

export default EditElement;
