/* eslint-disable no-param-reassign */
import React from 'react';

// Components
import { nameFormat } from '@utils';
import Subtitle from '../Subtitle';
import Title from '../Title';

// Styles
import {
  DataWrapper,
  Data,
  DataText,
  InfoWrapper,
  Container,
  ContentSubtitle,
  StyledCard,
} from './style';

const CardDashboardResults = ({ data, title, subtitle, order }) => {
  return (
    <StyledCard width="100%">
      <Container>
        <DataWrapper>
          <Data>
            <DataText>{data}</DataText>
          </Data>
        </DataWrapper>
        <InfoWrapper>
          <Title fontSize="1.5rem">{title && nameFormat(title)}</Title>
          <ContentSubtitle>
            <Subtitle fontSize="1rem">{subtitle}</Subtitle>
            <Subtitle fontSize="1rem">{order}</Subtitle>
          </ContentSubtitle>
        </InfoWrapper>
      </Container>
    </StyledCard>
  );
};

CardDashboardResults.defaultProps = {
  data: '12/15/2022',
  title: 'Adubos PF Indústria LTDA.',
  subtitle: 'Tecido',
  order: '939265',
};

export default CardDashboardResults;
