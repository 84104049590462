import React, { useState, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { formatMessagesErrors } from '@utils/index';

import api from '@services/api';

// Theme
import theme from '@styles/theme';

// Components
import Card from '../Card';
import Title from '../Title';
import Subtitle from '../Subtitle';
import Graph from './Graph';

// Styles
import {
  Wrapper,
  UserWrapper,
  UserPhoto,
  TextsWrapper,
  Photo,
  WrapperSubTexts,
  DividerBar,
  InfoWrapper,
  Content,
  BoldTextWrapper,
  WrapperNameTitle,
} from './style';

const CardFarms = ({ hasLastLogin, widthCard, info, idx, lastLogin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const refPartTopCard = useRef(null);
  const refPartBottomCard = useRef(null);
  const [heightPartOfTopCard, setheightPartOfTopCard] = useState(0);
  const [heightPartOfBottomCard, setheightPartOfBottomCard] = useState(0);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const formatedArea = info.area.toFixed(1);

  const getAddress = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);

        const apiCity = await api.get(`City/GetById/${info.cidade}`);

        setCity(apiCity.data.nomeCidade);
        setState(apiCity.data.nomeEstado);
        setCountry(apiCity.data.nomePais);
      }
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getNewSizeOfCard = () => {
    if (refPartTopCard.current) {
      setheightPartOfTopCard(refPartTopCard.current.clientHeight);
    }

    if (refPartBottomCard.current) {
      setheightPartOfBottomCard(refPartBottomCard.current.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', getNewSizeOfCard);
    if (typeof info.cidade === 'number') {
      getAddress();
    }
  }, []);

  return (
    <Card
      style={{ flexGrow: 1, width: '100%' }}
      width={widthCard}
      padding="0"
      hasHover
    >
      <Wrapper>
        <UserWrapper ref={refPartTopCard} hasLastLogin={hasLastLogin}>
          <UserPhoto>
            <Photo
              src={
                info?.imageLink ||
                'https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png'
              }
            />
          </UserPhoto>
          <TextsWrapper>
            {hasLastLogin && (
              <Subtitle
                fontSize="0.6rem"
                letterSpacing="2px"
                fontWeight="bold"
                lineHeight="1rem"
                color={theme.colors.grayText}
              >
                {lastLogin}
              </Subtitle>
            )}
            <WrapperNameTitle hasLastLogin={hasLastLogin}>
              <Title
                fontSize="1.4rem"
                letterSpacing="2px"
                lineHeight={hasLastLogin ? '2.5rem' : '1.8rem'}
                whiteSpace={hasLastLogin ? 'nowrap' : 'normal'}
              >
                {info.nome}
              </Title>
            </WrapperNameTitle>
            <WrapperSubTexts>
              <Subtitle fontSize="0.8rem" lineHeight="1rem">
                {city !== '' ? city : info.cidade}
                {info.estado ? ',' : ''} {state !== '' ? state : info.estado}{' '}
                {info.pais || country !== '' ? '- ' : ''}
                {country !== '' ? country : info.pais}
              </Subtitle>
            </WrapperSubTexts>
          </TextsWrapper>
        </UserWrapper>
        <DividerBar />
        <InfoWrapper ref={refPartBottomCard}>
          <Content>
            <Subtitle
              fontSize="0.8rem"
              color={theme.colors.border}
              lineHeight="1rem"
            >
              Área Cadastrada
            </Subtitle>
            <BoldTextWrapper>
              <Title fontSize="1rem" letterSpacing="0">
                {info.area === 0 ? info.area : formatedArea}
              </Title>
            </BoldTextWrapper>
          </Content>
          <Content>
            <Subtitle
              fontSize="0.8rem"
              color={theme.colors.border}
              lineHeight="1rem"
            >
              Zona de manejo
            </Subtitle>
            <BoldTextWrapper>
              <Title fontSize="1rem" letterSpacing="0">
                {info.managementZones}
              </Title>
            </BoldTextWrapper>
          </Content>
          {/* <Content>
            <Graph />
          </Content> */}
        </InfoWrapper>
      </Wrapper>
    </Card>
  );
};

CardFarms.defaultProps = {
  widthCard: 'auto',
  lastLogin: 'ÚLTIMO LOGIN EM 26/06/2021 ÁS 15:35',
};

export default CardFarms;
