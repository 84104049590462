import axios from 'axios';
import { AES, enc } from 'crypto-js';

import { KEY, LOCAL_STORAGE_AUTH } from '@context/Auth/constants';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_RECOMMENDATIONS,
});

api.interceptors.request.use(
  (config) => {
    try {
      const values = localStorage.getItem(LOCAL_STORAGE_AUTH.USER_INFO);

      if (values) {
        const decryptedValues = AES.decrypt(values, KEY).toString(enc.Utf8);

        const { tokenType, accessToken } = JSON.parse(decryptedValues);

        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = accessToken
          ? `${tokenType} ${accessToken}`
          : '';
      }

      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear(LOCAL_STORAGE_AUTH.USER_INFO);
      window.location = '/';
    }
    return Promise.reject(error);
  }
);

export default api;
