import styled from 'styled-components';

// Components
// eslint-disable-next-line import/no-cycle
import Title from '../../../Title';
import Button from '../../../Button';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Square = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled(Title)`
  width: 100%;
  text-align: center;
  margin-top: 16px;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;
`;
