import React from 'react';

// Theme
import theme from '@styles/theme';

const getBarShape = (x, y, width, height, radius) => {
  const [tl, tr, bl, br] = radius;
  const d = `M${x},${y + tl}
		a${tl},${tl} 0 0 1 ${tl},${-tl}
		h${width - tl - tr}
		a${tr},${tr} 0 0 1 ${tr},${tr}
		v${height - tr - br}
		a${br},${br} 0 0 1 ${-br},${br}
		h${bl + (br - width)}
		a${bl},${bl} 0 0 1 ${-bl},${-bl}
		z`;
  return ({ fill, fillOpacity, stroke }) => (
    <path d={d} fill={fill} fillOpacity={fillOpacity} stroke={stroke} />
  );
};

getBarShape.defaultProps = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  radius: [0, 0, 0, 0],
};

const Shape = ({ x, y, width, height, fillBehindBar, fillBar }) => {
  const StyledBar = getBarShape(x, y, width, height, [10, 10, 0, 0]);
  return (
    <svg style={{ filter: 'drop-shadow(rgba(0, 0, 0, 0.2) 3px 0px 8px)' }}>
      <path
        d={`
        M${x + width / 2},${y + 5}
        h${width / 2}
        q10,0 10,10
        v${height - 15}
        q0,0 -10,0
        h-${width / 2}
        z
        `}
        fill={fillBehindBar}
      />
      <StyledBar fillOpacity={1} fill={fillBar} />
    </svg>
  );
};

Shape.defaultProps = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  fillBehindBar: theme.colors.darkerGray2,
  fillBar: theme.colors.veryLightGray,
};

export default Shape;
