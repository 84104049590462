import React, { createContext, useState } from 'react';

export const SuppliesContext = createContext();

const SuppliesProvider = ({ children }) => {
  const [createdBy, setCreatedBy] = useState('');

  return (
    <SuppliesContext.Provider
      value={{
        createdBy,
        setCreatedBy,
      }}
    >
      {children}
    </SuppliesContext.Provider>
  );
};

export default SuppliesProvider;
