import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import api from '@services/api';
import { formatMessagesErrors } from '@utils/index';

// Components
import Accordion from '../Accordion';
import Input from '../Input';
import Button from '../Button';

// Style
import * as S from './style';

export const HeaderEditClassification = () => {
  return <S.Title>Editar Classificação</S.Title>;
};

const EditClassification = ({
  width,
  classification,
  handleClose,
  handleOpenModal,
  handleUpdateListOfClassificationsAfterAddOrEditClassification,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen((prevState) => !prevState);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: classification.id,
      name: classification.nome,
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const { data } = await api.put('/SupplyClassification/Alter', {
          id: values.id,
          nome: values.name,
        });

        handleClose();
        handleOpenModal();
        handleUpdateListOfClassificationsAfterAddOrEditClassification();

        enqueueSnackbar('Classificação alterada com sucesso!', {
          variant: 'success',
        });
      } catch (error) {
        const messages = formatMessagesErrors(error);

        messages.forEach((message) => {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <S.Wrapper onSubmit={formik.handleSubmit}>
      <Accordion
        keyAnimator="accordion_basics_informations"
        title="Informações Básicas"
        width={width}
        open={open}
        handleOpen={handleOpen}
      >
        <S.InputWrapper>
          <Input
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            label="Nome"
            width="100%"
            placeholder="Digite o nome..."
          />
        </S.InputWrapper>
      </Accordion>

      <Button
        width="100%"
        type="submit"
        borderHover="0"
        border="none"
        isLoading={isLoading}
      >
        Salvar
      </Button>
    </S.Wrapper>
  );
};

export default EditClassification;
