import styled, { css } from 'styled-components';

export const Wrapper = styled.form`
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10px;
  padding: 20px;
  padding-top: 90px;

  @media (max-width: 480px) {
    height: calc(100% - 70px);
  }
`;

export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  ${(props) =>
    props.isSwitch &&
    css`
      justify-content: space-between;
    `}

  label {
    margin-left: 0;

    .MuiFormControlLabel-label {
      color: ${({ theme }) => theme.colors.fadedText};
      font-size: ${(props) => props.fontSizeLabel};
      font-weight: 400;
    }
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 700;
  align-self: center;
  font-size: 1.2rem;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.mainText};
  margin-top: 0.875rem;
`;

export const Icon = styled.div`
  cursor: pointer;
  &.selected {
    border: 2px solid #eb612c;
  }
`;

export const InputIcon = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.fadedText};
  font-size: ${(props) => props.fontSizeLabel};
  font-weight: 400;
`;
