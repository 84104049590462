/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import api from '@services/api';
import { formatMessagesErrors } from '@utils/index';

// Components
import Accordion from '../Accordion';
import Input from '../Input';
import InputData from '../InputData';
import Button from '../Button';
import Select from '../Select';

// Style
import * as S from './style';

export const HeaderEditHarvest = () => {
  return <S.Title>Editar Safra</S.Title>;
};

const EditHarvest = ({
  width,
  harvest,
  handleClose,
  handleOpenModal,
  handleUpdateListOfHarvestsAfterAddOrEditHarvest,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen((prevState) => !prevState);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: harvest.id,
      description: harvest.descricao,
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const { data } = await api.put('/Harvest/Alter', {
          id: values.id,
          descricao: values.description,
        });

        handleClose();
        handleOpenModal();
        handleUpdateListOfHarvestsAfterAddOrEditHarvest();

        enqueueSnackbar('Safra editada com sucesso!', {
          variant: 'success',
        });
      } catch (error) {
        const messages = formatMessagesErrors(error);

        messages.forEach((message) => {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <S.Wrapper onSubmit={formik.handleSubmit}>
      <Accordion
        keyAnimator="accordion_basics_informations"
        title="Informações Básicas"
        width={width}
        open={open}
        handleOpen={handleOpen}
      >
        <S.InputWrapper>
          <Input
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            label="Descrição"
            width="100%"
            placeholder="Digite a descrição..."
          />
        </S.InputWrapper>
      </Accordion>

      <Button
        width="100%"
        type="submit"
        borderHover="0"
        border="none"
        isLoading={isLoading}
      >
        Salvar
      </Button>
    </S.Wrapper>
  );
};

export default EditHarvest;
