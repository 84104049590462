import styled from 'styled-components';

// Components
// eslint-disable-next-line import/no-cycle
import Button from '../../../Button';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  gap: 20px;
`;

export const StyledButton = styled(Button)``;
