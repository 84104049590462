import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Assets
import { ReactComponent as DetailMenu } from '@assets/svg/icons/detail_menu.svg';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 88px;
  height: 100vh;
  z-index: 4;

  border-right: 1px solid ${({ theme }) => theme.colors.darkWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
`;

export const LogoLink = styled(Link)``;

export const StyledLogo = styled.div`
  background-image: url(../../assets/png/logo.png);
  height: 40px;
  width: 40px;
  margin-top: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
  z-index: 1;
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.colors.darkWhite};
  width: 100%;
  height: 1px;
  margin-bottom: -20px; //margin em baixo da logo
`;

export const IconButton = styled(Link)`
  text-decoration: none;
  background: transparent;
  border: 0;

  svg {
    width: 25px;
    height: 25px;
    margin-top: -50px; //margin entre os icones
  }

  padding: 10px;

  svg path {
    stroke: ${(props) => props?.stroke};
    fill: ${(props) => props?.fill};
  }

  :hover svg path {
    stroke: ${(props) => props?.stroke && props?.hover};
    fill: ${(props) => props?.fill && props?.hover};
  }

  @media (max-height: 768px) and (max-width: 1366px) {
    svg {
      width: 20px;
      height: 20px;
    }

    padding: 3px;
  }

  @media (max-height: 605px) and (max-width: 1366px) {
    svg {
      width: 20px;
      height: 20px;
    }
    margin-bottom: -10px;
    padding: 2px;
  }

  @media (max-height: 480px) and (max-width: 1366px) {
    svg {
      width: 16px;
      height: 16px;
    }

    padding: 1.5px;
  }
`;

export const WrapperMiddleButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const WrapperBottomButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 5px; //margem em baixo do botao sair
  width: 100%;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 16px;
  }

  :first-child {
    margin-top: 30px;
  }
`;

export const WrapperAnimateDetail = styled(motion.div)`
  display: flex;
  align-items: center;
`;

export const StyledDetailMenu = styled(DetailMenu)`
  position: absolute;
  left: 0;
`;

export const BlurredBackground = styled(motion.div)`
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  z-index: 2;
`;
