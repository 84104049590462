import styled from 'styled-components';
// Components
// eslint-disable-next-line import/no-cycle
import Title from '../../../Title';
import Button from '../../../Button';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Square = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled(Title)`
  width: 100%;
  text-align: center;
  margin-top: 16px;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;
`;

export const ListOfScales = styled.div`
  margin-top: 0.5rem;
`;

export const Container = styled.div`
  width: 70%;
  height: 3.8rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border: 1px solid #f6f6f6;
  border-radius: 12px;
  box-shadow: 0px 12px 22px rgba(22, 37, 77, 0.04);
  background: #fff;
  padding: 0 1rem;
  margin-top: 1rem;
  justify-items: left;
  align-items: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.span`
  font-size: 0.625rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.translucentText};
`;

export const NumberContent = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.inactiveText};
`;

export const ColorContent = styled.div`
  background: ${({ color }) => color};
  width: 3rem;
  height: 1.125rem;
`;

export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
