import React from 'react';
import { AnimatePresence } from 'framer-motion';

// Style
import {
  Wrapper,
  WrapperTop,
  Title,
  CloseIcon,
  LineTitle,
  TitleSection,
  TitleSubSection,
  Point,
  WrapperMiddle,
  WrapperSection,
  WrapperSubSection,
  StyledOrangeArrow,
  CloseButton,
  TitleSubSectionSettings,
} from './style';
import { Divider } from '../style';

// Assets

const OpenedMenu = ({ open, handleMenu, options }) => {
  return (
    <AnimatePresence>
      {open && (
        <Wrapper
          key="secondRightDrawer"
          initial={{ x: 0, opacity: 0 }}
          animate={{ x: 80, opacity: 1 }}
          exit={{ x: -302, opacity: 0 }}
          open={open}
        >
          <WrapperTop>
            <Title>Menu</Title>
            <CloseButton onClick={handleMenu}>
              <CloseIcon />
            </CloseButton>
          </WrapperTop>
          <Divider />
          <WrapperMiddle>
            {options.map((item) => (
              <WrapperSection key={item.section}>
                <LineTitle {...item.colors}>
                  {item.icon}
                  <TitleSection>{item.section}</TitleSection>
                  <StyledOrangeArrow />
                </LineTitle>
                {item.subsections.map((subItem) => (
                  <WrapperSubSection key={subItem.name}>
                    <Point />
                    {subItem?.type === 'settings' ? (
                      <TitleSubSectionSettings onClick={subItem.onClick}>
                        {subItem.name}
                      </TitleSubSectionSettings>
                    ) : (
                      <TitleSubSection to={subItem.to}>
                        {subItem.name}
                      </TitleSubSection>
                    )}
                  </WrapperSubSection>
                ))}
              </WrapperSection>
            ))}
          </WrapperMiddle>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

OpenedMenu.defaultProps = {
  open: false,
  handleMenu: () => {},
};

export default OpenedMenu;
