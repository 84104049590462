import styled from 'styled-components';

export const StyledSubtitle = styled.p`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  letter-spacing: ${(props) => props.letterSpacing};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
`;
