import styled from 'styled-components';

// Asset
import { ReactComponent as DetailPercentage } from '@assets/svg/icons/green_polygon.svg';

export const Wrapper = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1.3px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  padding: 20px;

  @media (max-width: 641px) {
    width: 100%;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 670px) {
    margin-bottom: 8px;
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.fadedIcon};
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: -1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 8px;
`;

export const BoxPercentage = styled.div`
  background: ${({ theme }) => theme.colors.whiteSmoke};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-radius: 8px;
`;

export const StyledDetailPorcentage = styled(DetailPercentage)`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  transform: ${({ percentage }) =>
    percentage < 0 ? 'rotate(180deg)' : 'none'};

  path {
    fill: ${({ percentage, theme }) =>
      percentage < 0 ? 'red' : theme.colors.mountainGreen};
  }
`;

export const TextPercentage = styled.p`
  color: ${({ theme, percentage }) =>
    percentage < 0 ? 'red' : theme.colors.mountainGreen};
  font-size: 1rem;
  letter-spacing: -1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px;
  width: 60px;
  z-index: 0;

  ::after {
    content: '';
    border: 5px solid ${({ theme }) => theme.colors.darkWhite};
    border-radius: 12px;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  ::before {
    content: '';
    background: ${({ theme }) => theme.colors.fadedIcon};
    border-radius: 12px;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  svg,
  img {
    position: relative;
    z-index: 2;
  }
`;

export const MainText = styled.p`
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.mainTextDark};
  font-size: 2rem;
  font-weight: bolder;
  /* letter-spacing: -1px; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
