import React, { useContext } from 'react';
import { CollectionsContext } from '@context/Collections';

const useCollections = () => {
  const context = useContext(CollectionsContext);

  return context;
};

export default useCollections;
