import styled from 'styled-components';

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  padding: 1px;
  background-clip: content-box;
  border: 1.5px solid #bbbbbb;
  border-radius: 50%;
  background-color: #e7e6e7;

  &&:checked {
    background-color: #eb612c;
  }

  &&:focus {
    outline: none !important;
  }
`;
