/* eslint-disable no-unneeded-ternary */
import React from 'react';
import Lottie from 'react-lottie-player';

// Assets
import WhiteLoading from '@assets/lottie/white-loading.json';

// Theme
import theme from '@styles/theme';

// Style
// eslint-disable-next-line import/no-cycle
import { Wrapper, StyledTitle, WrapperButtons, StyledButton } from './style';

export const ModalDelete = ({ onCancel, onConfirm, isLoading, title }) => {
  return (
    <Wrapper>
      <StyledTitle fontSize="1.4rem">
        {title ? title : 'Deseja mesmo fazer isso?'}
      </StyledTitle>
      <WrapperButtons>
        <StyledButton
          border="none"
          width="100%"
          boxShadow="none"
          type="submit"
          borderHover="0"
          onClick={onCancel}
          backgroundColor={theme.colors.darkerGray}
        >
          Cancelar
        </StyledButton>
        <StyledButton
          border="none"
          width="100%"
          boxShadow="none"
          type="submit"
          borderHover="0"
          onClick={onConfirm}
        >
          {isLoading ? (
            <Lottie
              loop
              animationData={WhiteLoading}
              play
              speed={2.5}
              style={{ width: '40px', height: '40px' }}
            />
          ) : (
            'Confirmar'
          )}
        </StyledButton>
      </WrapperButtons>
    </Wrapper>
  );
};
