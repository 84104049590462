import styled from 'styled-components';

// Components
import Button from '../Button';

// Assets
import { ReactComponent as Picture } from '../../assets/svg/picture.svg';

export const FileUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;
  width: 100%;
  text-align: center;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.colors.border}`};
  border-radius: 12px;
  margin-bottom: 15px;

  &:hover {
    cursor: pointer;
  }
`;

export const InputFileUpload = styled.input`
  display: none;
`;

export const LabelFileUpdload = styled.label`
  color: ${({ theme, hasfile }) =>
    hasfile ? theme.colors.lightOrange : theme.colors.fadedText};
  font-size: ${(props) => props.fontSizeLabel};
  margin-bottom: 10px;
  font-weight: ${({ hasfile }) => (hasfile ? 900 : 400)};
`;

export const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background: red;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const StyledPicture = styled(Picture)`
  & path {
    fill: ${({ theme, hasfile }) =>
      hasfile ? theme.colors.lightOrange : theme.colors.translucentText};
  }

  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.grayText};
  box-shadow: 0px 8px 25px ${({ theme }) => theme.colors.grayText}; ;
`;
