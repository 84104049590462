/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import api from '@services/api';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { formatMessagesErrors } from '@utils/index';

import {
  GiWheat,
  GiCottonFlower,
  GiCorn,
  GiJellyBeans,
  GiSugarCane,
  GiCoffeeBeans,
} from 'react-icons/gi';

// Components
import Accordion from '../Accordion';
import Input from '../Input';
import Button from '../Button';

// Style
import * as S from './style';

export const HeaderNewCulture = () => {
  return <S.Title>Nova Cultura</S.Title>;
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const NewCulture = ({
  width,
  handleClose,
  handleOpenModal,
  handleUpdateListOfCulturesAfterAddOrEditCulture,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen((prevState) => !prevState);
  const [isLoading, setIsLoading] = useState(false);
  const [Selected, setSelectedIcon] = useState('');

  const icons = [
    { value: 'GiWheat', label: <GiWheat size={30} /> },
    { value: 'GiCottonFlower', label: <GiCottonFlower size={30} /> },
    { value: 'GiCorn', label: <GiCorn size={30} /> },
    { value: 'GiJellyBeans', label: <GiJellyBeans size={30} /> },
    { value: 'GiSugarCane', label: <GiSugarCane size={30} /> },
    { value: 'GiCoffeeBeans', label: <GiCoffeeBeans size={30} /> },
  ];

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      name: '',
      active: true,
      vegetable: true,
      icon: '',
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const { data } = await api.post('/Culture/Create', {
          nome: values.name,
          // ativo: values.active,
          vegetal: values.vegetable,
          icone: Selected,
        });

        handleClose();
        handleOpenModal();
        handleUpdateListOfCulturesAfterAddOrEditCulture();

        enqueueSnackbar('Cultura cadastrada com sucesso!', {
          variant: 'success',
        });
      } catch (error) {
        const messages = formatMessagesErrors(error);

        messages.forEach((message) => {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <S.Wrapper onSubmit={formik.handleSubmit}>
      <Accordion
        keyAnimator="accordion_basics_informations"
        title="Informações Básicas"
        width={width}
        open={open}
        handleOpen={handleOpen}
      >
        <S.InputWrapper>
          <Input
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            label="Nome"
            width="100%"
            placeholder="Digite o nome..."
          />
        </S.InputWrapper>

        <S.InputWrapper isSwitch>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={formik.values.active}
                onChange={() =>
                  formik.setFieldValue('active', !formik.values.active)
                }
              />
            }
            label="Ativo"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={formik.values.vegetable}
                onChange={() =>
                  formik.setFieldValue('vegetable', !formik.values.vegetable)
                }
              />
            }
            label="Cultura Vegetal"
            labelPlacement="start"
          />
        </S.InputWrapper>
        <S.SubTitle>Icone</S.SubTitle>
        <S.InputIcon>
          {icons.map((icon) => (
            <S.Icon
              onClick={() => setSelectedIcon(icon.value)}
              className={Selected === icon.value ? 'selected' : ''}
            >
              {icon.label}
            </S.Icon>
          ))}
        </S.InputIcon>
      </Accordion>

      <Button
        width="100%"
        type="submit"
        borderHover="0"
        border="none"
        isLoading={isLoading}
      >
        Salvar
      </Button>
    </S.Wrapper>
  );
};

export default NewCulture;
