import styled from 'styled-components';

// eslint-disable-next-line import/no-cycle
import { Modal } from '@components/index';
import Button from '../Button';

export const Wrapper = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 30px;
  margin-top: 10px;
  padding: 20px;
  padding-top: 140px;
  @media (max-width: 480px) {
    height: calc(100% - 70px);
  }
`;

export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Username = styled.p`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 700;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* width: min-content;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const ListOfScales = styled.div`
  margin-top: 0.5rem;
`;

export const ButtonAdd = styled(Button)`
  margin-top: 1rem;
`;

export const StyledModal = styled(Modal)`
  @media (max-width: 860px) {
    width: 100vw;
    left: 55%;
  }
  @media (max-width: 640px) {
    left: 56%;
  }
  @media (max-width: 580px) {
    width: 75vw;
    left: 57%;
  }
  @media (max-width: 480px) {
    top: 45%;
    width: 80vw;
    left: 50%;
  }
`;
