import React from 'react';

// Assets
import { ReactComponent as UsersIcon } from '@assets/svg/icons/produtor_rural.svg';
import { ReactComponent as AreaIcon } from '@assets/svg/icons/area.svg';
import { ReactComponent as FarmIcon } from '@assets/svg/icons/farm.svg';
import { ReactComponent as UDPIcon } from '@assets/svg/icons/udp.svg';

// Style
import {
  Wrapper,
  HeaderWrapper,
  Title,
  BottomWrapper,
  BoxPercentage,
  TextPercentage,
  IconWrapper,
  MainText,
  StyledDetailPorcentage,
} from './style';

const CardInfoDashboard = ({ title, percentage, mainText, icon, width }) => {
  const iconSvg =
    (icon === 'area' && <AreaIcon />) ||
    (icon === 'producers' && <UsersIcon />) ||
    (icon === 'farm' && <FarmIcon />) ||
    (icon === 'udps' && <UDPIcon />);

  return (
    <Wrapper width={width}>
      <HeaderWrapper>
        <Title>{title}</Title>
        <BoxPercentage>
          <StyledDetailPorcentage percentage={percentage} />
          <TextPercentage percentage={percentage}>{percentage}%</TextPercentage>
        </BoxPercentage>
      </HeaderWrapper>
      <BottomWrapper>
        <IconWrapper>{iconSvg}</IconWrapper>
        <MainText>{mainText}</MainText>
      </BottomWrapper>
    </Wrapper>
  );
};

CardInfoDashboard.defaultProps = {
  title: 'Área total',
  percentage: 20,
  mainText: '265.655ha',
  icon: 'producers',
  width: '100%',
};

export default CardInfoDashboard;
