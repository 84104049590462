import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.footer`
  background: ${({ theme }) => theme.colors.lightGray};
  width: 100vw;
  height: 70px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  transition: all 0.2s;

  @media (max-width: 850px) {
    padding: 0 20px;
  }
  @media (max-width: 640px) {
    flex-direction: column;
    padding: 10px;
    line-height: 25px;
    overflow-y: auto;
    /* bottom: -20px; */
  }
`;

export const LeftWrapperText = styled.div``;

export const RightWrapperText = styled.div``;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.fadedText};
  font-size: 1em;
`;

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.colors.fadedText};
  font-size: 1em;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;
