import React, { createContext, useState, useContext } from 'react';
import { area } from '@turf/turf';
import apiRecommendations from '@services/apiRecommendations';
import { formatMessagesErrors } from '@utils/index';
import { useSnackbar } from 'notistack';

export const RecommendationContext = createContext();

const RecommendationProvider = ({ children }) => {
  const [alvoCa, setAlvoCa] = useState();
  const [alvoMg, setAlvoMg] = useState();
  const [newRecommendation, setNewRecommendation] = useState();
  const [step2Data, setStep2Data] = useState([]);
  const [step3Data, setStep3Data] = useState([]);
  const [clientSelected, setClientSelected] = useState('');
  const [typeSelected, setTypeSelected] = useState('');

  const [supply1Price, setSupply1Price] = useState(0);
  const [supply2Price, setSupply2Price] = useState(0);
  const [gessoPrice, setGessoPrice] = useState(0);

  const [valueInsumo1, setValueInsumo1] = useState(0);
  const [valueInsumo2, setValueInsumo2] = useState(0);
  const [valueGesso, setValueGesso] = useState(0);

  const [sum, setSum] = useState();

  const [valuesCaMgForRow, setValuesCaMgForRow] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const calculateArea = (value) => {
    const result = area(value);
    const resultInAcre = result / 10000;
    return resultInAcre.toFixed(2);
  };

  const getValuesCaMgForRow = async (idx, valueAlvoCa, valueAlvoMg, prop) => {
    try {
      const request = {
        zonaManejoId: step2Data[idx].geoJson[0].properties.idZonaManejo,
        ano: Number(step2Data[idx].year.ano),
        alvoCa: valueAlvoCa,
        alvoMg: valueAlvoMg,
        profundidades: step2Data[idx].selectedDepths.map((item) => item.nome),
        insumosIds: step3Data[0].supply.map((item) => item.id),
      };

      const { data } = await apiRecommendations.post(
        '/Recommendations/Recommendations',
        request
      );

      setValuesCaMgForRow((prevState) => ({
        ...prevState,
        [prop]: { ...data },
      }));
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    }
  };

  return (
    <RecommendationContext.Provider
      value={{
        clientSelected,
        setClientSelected,
        alvoCa,
        setAlvoCa,
        alvoMg,
        setAlvoMg,
        newRecommendation,
        setNewRecommendation,
        setStep2Data,
        step2Data,
        setStep3Data,
        step3Data,
        calculateArea,
        getValuesCaMgForRow,
        valuesCaMgForRow,
        supply1Price,
        setSupply1Price,
        supply2Price,
        setSupply2Price,
        gessoPrice,
        setGessoPrice,
        valueInsumo1,
        setValueInsumo1,
        valueInsumo2,
        setValueInsumo2,
        valueGesso,
        setValueGesso,
        sum,
        setSum,
        typeSelected,
        setTypeSelected,
      }}
    >
      {children}
    </RecommendationContext.Provider>
  );
};

export default RecommendationProvider;
