import React from 'react';
import Lottie from 'react-lottie-player';

// Lottie File
import CheckLottie from '@assets/lottie/check.json';

// Styles
// eslint-disable-next-line import/no-cycle
import {
  Wrapper,
  Square,
  StyledTitle,
  StyledSubtitle,
  StyledButton,
} from './style';

export const ModalSuccess = ({ onClick }) => {
  return (
    <Wrapper>
      <Square>
        <Lottie
          loop
          animationData={CheckLottie}
          play
          style={{ width: '100%', height: '100%' }}
        />
      </Square>
      <StyledTitle>Pronto!</StyledTitle>
      <StyledSubtitle fontSize="1.1rem" lineHeight="1rem">
        As informações alteradas foram salvas com sucesso!
      </StyledSubtitle>
      <StyledButton
        border="none"
        width="100%"
        boxShadow="none"
        type="submit"
        borderHover="0"
        onClick={onClick}
      >
        Concluído
      </StyledButton>
    </Wrapper>
  );
};
