import styled from 'styled-components';

export const StyledSubHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 481px) and (max-width: 860px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 5rem;
  height: 2.1rem;
  gap: 10px;

  svg {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 480px) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Bar = styled.div`
  width: 2px;
  height: 12px;
  background: ${({ theme }) => theme.colors.mainTextDark};
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px;
`;

export const WrapperIcons = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 480px) {
    margin-left: 10px;
  }
`;

export const WrapperRightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperLeftSide = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    gap: 8px;
  }
`;

export const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 40px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.lightGray};
`;

export const ContainerButtons = styled.div`
  display: flex;
`;

const ButtonTypeData = styled.button`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.fadedIcon};
  padding: 0.5rem;
  margin-left: 0.5rem;

  border-radius: 26px;

  font-size: 0.75rem;
  text-align: center;
  font-weight: 400;

  color: ${({ theme }) => theme.colors.fadedIcon};

  :hover {
    color: #fff;
  }
`;

export const ButtonCreatedByAdmin = styled(ButtonTypeData)`
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.orangeBar};
    background: ${({ theme }) => theme.colors.orangeBar};
  }

  ${({ selected, theme }) =>
    selected &&
    `
    color: #fff;
    border: 1px solid ${theme.colors.orangeBar};
    background: ${theme.colors.orangeBar};
  `}
`;

export const ButtonCreatedByPartner = styled(ButtonTypeData)`
  :hover {
    border: 1px solid #2f80ed;
    background: #2f80ed;
  }

  ${({ selected }) =>
    selected &&
    `
    color: #fff;
    border: 1px solid #2f80ed;
    background: #2f80ed;
  `}
`;
