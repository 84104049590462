import React from 'react';

// Styles
import { Container, Text } from './styles';

const NotFound = ({ search = true, text, description }) => {
  return (
    <Container>
      {search ? (
        <>
          <Text>Nenhum resultado para sua busca foi encontrado</Text>
          <p>Verifique a ortografia e tente novamente</p>
        </>
      ) : (
        <>
          <Text>{text}</Text>
          <p>{description}</p>
        </>
      )}
    </Container>
  );
};

export default NotFound;
