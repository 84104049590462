/* eslint-disable */
import React, { useRef, useState, useEffect, useContext } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { CPFMask } from '@components/Input/masks';
import usePosition from '@hooks/useLocation';
import bbox from '@turf/bbox';
import polygonsplitter from 'polygon-splitter';
import { area } from '@turf/turf';
import { v4 as uuid } from 'uuid';
import { generateSvgFromGeoJson } from '@utils/geojsonToSVG';
import { useSnackbar } from 'notistack';
import { TalhaoContext } from '../../context/Talhao';
import {
  StyledMap,
  OptionsWrapper,
  Option,
  OptionImage,
  Description,
  StyledInput,
} from './style';
import Card from '../Card';
import mapboxgl from '../../services/MapBox';
import light from '../../assets/viewport-options/light.png';
import dark from '../../assets/viewport-options/dark.png';
import satelite from '../../assets/viewport-options/satelite.png';
import ruas from '../../assets/viewport-options/ruas.png';

const generateHexColor = () => {
  return `#${parseInt(Math.random() * 0xffffff, 10)
    .toString(16)
    .padStart(6, '0')}`;
};

const rgba2hex = (orig) => {
  let a;
  let isPercent;
  const rgb = orig
    .replace(/\s/g, '')
    .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  const alpha = ((rgb && rgb[4]) || '').trim();
  let hex = rgb
    ? // eslint-disable-next-line no-bitwise
      (rgb[1] | (1 << 8)).toString(16).slice(1) +
      // eslint-disable-next-line no-bitwise
      (rgb[2] | (1 << 8)).toString(16).slice(1) +
      // eslint-disable-next-line no-bitwise
      (rgb[3] | (1 << 8)).toString(16).slice(1)
    : orig;

  if (alpha !== '') {
    a = alpha;
  } else {
    a = 1;
  }
  hex += a;

  return hex;
};

const ZOOM_MAP = 14;

const MapWithLayersManagementZones = ({
  hasLayer,
  hasCollectionLayer,
  width,
  height,
  geoJson,
  setGeoJson,
  geoJsonPrev,
  handleChange,
  getMapDrawFunctions,
  handleOpenTalhaoNameModal,
  hasSearchInput,
  hasDrawOptions,
  startsWithSattelite,
  styleMap,
  // getCurrentMap,
  callBackWhenClickLayer,
  hasViewportOptions,
  paintOnClick,
  deletedZone,
  setDeletedZone,
  hasViewportOptionLight,
  hasViewportOptionDark,
  hasViewportOptionSatellite,
  hasViewportOptionStreet,
  editMode,
  allowFocusUserPosition = false,
  optionsWrapperStyles,
  updateMap,
  setUpdateMap,
  currentItemZoom,
  setCurrentItemZoom,
  customDrawOptions,
  setCurrentFeatureSelected,
  currentFeatureSelected,
  setSplittedZones,
  currentSplitLine,
  setCurrentSplitLine,
  isEditMode,
  setZonas,
  talhaoToClone,
  setTalhaoToClone,
  ...props
}) => {
  const hasOption =
    hasViewportOptions ||
    hasViewportOptionLight ||
    hasViewportOptionDark ||
    hasViewportOptionSatellite ||
    hasViewportOptionStreet;

  const { positionCurrent } = usePosition();

  const mapContainer = useRef(null);
  const hoverId = useRef(null);
  const clickId = useRef(null);

  const map = useRef(null);
  const arrayLayerIdString = useRef();
  const arraySourceIdString = useRef();

  const [lng, setLng] = useState(-51.1732);
  const [lat, setLat] = useState(-23.2927);

  const [zoom, setZoom] = useState(2);
  const [viewportStyle, setViewportStyle] = useState('mapbox://styles/mapbox/satellite-streets-v11');

  const [featuresClicked, setFeaturesClicked] = useState([]);
  const [needReZoom, setNeedReZoom] = useState(true);
  const [clickedZone, setClickedZone] = useState(null);

  const { selectedTalhao } = useContext(TalhaoContext);
  const { enqueueSnackbar } = useSnackbar();

  const mapDraw = new MapboxDraw({
    // eslint-disable-next-line no-unneeded-ternary
    controls: customDrawOptions,
    displayControlsDefault: false,
    userProperties: true,
  });

  const calculateArea = (value) => {
    const result = area(value);
    const resultInAcre = result / 10000;
    return resultInAcre.toFixed(5);
  };

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  function applyZoom(latitude, longitude) {
    const sw = new mapboxgl.LngLat(longitude, latitude);
    const ne = new mapboxgl.LngLat(longitude, latitude);

    const bounds = new mapboxgl.LngLatBounds(sw, ne);

    map.current.fitBounds(bounds, {
      zoom: ZOOM_MAP,
    });
  }

  useEffect(() => {
    const { latitude, longitude } = positionCurrent;

    if (!latitude || !longitude || hasLayer || !allowFocusUserPosition) {
      return;
    }

    applyZoom(latitude, longitude);
  }, [positionCurrent]);

  const handleColorToSplit = (layerId) => {
    const { layers } = map.current.getStyle();

    layers.forEach((layer) => {
      if (layer.paint['fill-color'] === '#f6885d') {
        map.current.setPaintProperty(layer.id, 'fill-color', '#ddeaed');
      }
    });

    if (layerId) {
      map.current.setPaintProperty(
        `FeatureLayer${layerId}`,
        'fill-color',
        '#f6885d'
      );
    }
  };

  const handleZoomOnFeature = (feature) => {
    if (geoJson?.features?.length === 0 || !hasLayer || !map?.current) {
      return;
    }

    const bboxOnMap = bbox({
      type: 'FeatureCollection',
      features: [feature],
    });

    const sw = new mapboxgl.LngLat(bboxOnMap[0], bboxOnMap[1]);
    const ne = new mapboxgl.LngLat(bboxOnMap[2], bboxOnMap[3]);

    const coords = new mapboxgl.LngLatBounds(sw, ne);

    map.current.fitBounds(coords, { padding: 5 });
  };

  const handleZoom = () => {
    if (
      geoJson?.features?.length === 0 ||
      !hasLayer ||
      !map?.current ||
      !needReZoom
    ) {
      return;
    }
    const findTalhaoIndex = geoJson.features.findIndex(
      (item) => item.properties.talhao
    );

    const bboxOnMap = bbox({
      type: 'FeatureCollection',
      features: [geoJson.features[findTalhaoIndex]],
    });

    const sw = new mapboxgl.LngLat(bboxOnMap[0], bboxOnMap[1]);
    const ne = new mapboxgl.LngLat(bboxOnMap[2], bboxOnMap[3]);

    const coords = new mapboxgl.LngLatBounds(sw, ne);

    map.current.fitBounds(coords, { padding: 5 });
  };

  useEffect(() => {
    if (
      !geoJson?.features ||
      geoJson?.features?.length === 0 ||
      !hasLayer ||
      !map?.current
    ) {
      return;
    }

    handleZoom();
  }, [map.current, geoJson]);

  const handleAddLayers = () => {
    const arrayLayerId = [];
    const arraySourceId = [];

    if (geoJson?.features === undefined) {
      return;
    }
    mapDraw.add(geoJson);
    const { features } = mapDraw.getAll();

    handleChange(features);
    geoJson.features.forEach((item) => {
      const { idZonaManejo, color } = item.properties;

      const geoJsonPropertyFormatted = {
        type: 'FeatureCollection',
        features: [{ id: item.id, ...item }],
      };

      const layerID = `FeatureLayer${item.id}`;
      arrayLayerId.push(layerID);

      arraySourceId.push(`Feature_${item.id}`);

      map.current.addSource(`Feature_${item.id}`, {
        type: 'geojson',
        data: geoJsonPropertyFormatted,
      });

      if (item && hasLayer && paintOnClick === false) {
        map.current.addLayer({
          id: layerID,
          type: 'fill',
          source: `Feature_${item.id}`,
          paint: {
            'fill-color': '#d9e8ec',
            'fill-opacity': 0.5,
            'fill-outline-color': '#3bb2d0',
          },
        });

        map.current.addLayer({
          id: `line_${layerID}`,
          type: 'line',
          source: `Feature_${item.id}`,
          layout: {},
          paint: {
            'line-color': '#3bb2d0',
            'line-width': 2,
          },
        });
      }

      if (item && paintOnClick) {
        map.current.addLayer({
          id: layerID,
          type: 'fill',
          source: `Feature_${item.id}`,
          paint: {
            'fill-color': [
              'case',
              ['boolean', ['feature-state', 'clicked'], false],
              color || generateHexColor(),
              '#919191',
            ],
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'clicked'], false],
              1,
              0.5,
            ],
            'fill-outline-color': color || generateHexColor(),
          },
        });

        map.addLayer({
          id: `line_${layerID}`,
          type: 'line',
          source: `Feature_${item.id}`,
          layout: {},
          paint: {
            'line-color': '#000',
            'line-width': 6,
          },
        });
      }
    });

    arraySourceIdString.current = arraySourceId;
    arrayLayerIdString.current = arrayLayerId;
  };

  function handleRemoveLayers() {
    if (!geoJsonPrev || geoJsonPrev?.features === undefined) {
      return;
    }

    geoJsonPrev.features.forEach((item) => {
      const layerID = `FeatureLayer${item.id}`;
      const sourceID = `Feature_${item.id}`;

      if (map.current.getLayer(layerID)) {
        map.current.removeLayer(layerID);
      }

      if (map.current.getSource(sourceID)) {
        map.current.removeSource(sourceID);
      }
    });
  }

  useEffect(() => {
    // if (map.current) {
    //   return;
    // }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: viewportStyle,
      center: [lng, lat],
      zoom,
    });

    if (hasDrawOptions) {
      getMapDrawFunctions(mapDraw);

      map.current.addControl(
        new MapboxGeocoder({
          accessToken: process.env.REACT_APP_MAPBOXGL_ACCESSTOKEN,
          mapboxgl,
        })
      );
      map.current.addControl(mapDraw, 'top-left');
    }

    if (hasSearchInput) {
      map.current.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl,
        })
      );
    }
  }, [viewportStyle, updateMap]);

  useEffect(() => {
    map.current.on('load', () => {
      handleAddLayers();

      map.current.on('click', (e) => {
        const currentBbox = [
          [e.point.x - 5, e.point.y - 5],
          [e.point.x + 5, e.point.y + 5],
        ];

        const selectedFeatures = map.current.queryRenderedFeatures(
          currentBbox,
          {
            layers: arrayLayerIdString.current,
          }
        );

        const selectedFeaturesSource = selectedFeatures[0]?.source;

        // Criando efeito hover
        if (selectedFeatures.length > 0) {
          if (clickId !== null) {
            map.current.setFeatureState(
              { source: selectedFeaturesSource, id: clickId.current },
              { click: false }
            );
          }

          if (paintOnClick) {
            clickId.current = selectedFeatures[0]?.id;

            setFeaturesClicked((prevState) => [
              ...prevState,
              selectedFeatures[0],
            ]);

            map.current.setFeatureState(
              { source: selectedFeaturesSource, id: selectedFeatures[0]?.id },
              { clicked: true }
            );
          }
        }

        const fips = selectedFeatures.map((feature) => feature);

        if (fips.length > 0) {
          callBackWhenClickLayer(fips);
        }
      });

      map.current.on('mouseenter', arrayLayerIdString.current, (e) => {
        map.current.getCanvas().style.cursor = 'pointer';

        const features = e.features[0];

        const { fazenda, zonaManejo, ZI, SampleID, id, elementoValor, talhao } =
          features.properties;
        const coordinates = features.geometry.coordinates.slice();

        const sw = new mapboxgl.LngLat(
          coordinates[0][0][0],
          coordinates[0][0][1]
        );

        let message = `${fazenda}-${zonaManejo}`;

        if (elementoValor) {
          message += `<br>Resultado: ${elementoValor}`;
        } else if (talhao) {
          message = talhao;
        } else if (zonaManejo) {
          message = `Zona de manejo - ${zonaManejo}`;
        } else if (SampleID) {
          message = `Zona de manejo - ${SampleID}`;
        } else if (id) {
          message = `Zona de manejo - ${id}`;
        } else if (ZI) {
          message = `Zona de manejo - ${ZI}`;
        }

        popup.setLngLat(sw).setHTML(message).addTo(map.current);
      });

      map.current.on('mousemove', arrayLayerIdString.current, (e) => {
        const currentBbox = [
          [e.point.x - 5, e.point.y - 5],
          [e.point.x + 5, e.point.y + 5],
        ];

        const selectedFeatures = map.current.queryRenderedFeatures(
          currentBbox,
          {
            layers: arrayLayerIdString.current,
          }
        );

        const selectedFeaturesSource = selectedFeatures[0].source;

        // Criando efeito hover
        if (selectedFeatures.length > 0) {
          if (hoverId !== null) {
            map.current.setFeatureState(
              { source: selectedFeaturesSource, id: hoverId.current },
              { hover: false }
            );
          }

          hoverId.current = selectedFeatures[0].id;

          map.current.setFeatureState(
            { source: selectedFeaturesSource, id: selectedFeatures[0].id },
            { hover: true }
          );
        }
      });

      map.current.on('mouseleave', arrayLayerIdString.current, (e) => {
        popup.remove();
        map.current.getCanvas().style.cursor = '';

        // Removendo efeito hover
        if (hoverId.current !== null) {
          map.current.setFeatureState(
            { source: `Feature_${hoverId.current}`, id: hoverId.current },
            { hover: false }
          );
        }

        hoverId.current = null;
      });

      // map.current.on('styledataloading', () => {
      //   map.current.once('styledata', handleAddLayers);
      // });

      // handleZoom();
    });
  }, [geoJson, updateMap]);

  useEffect(() => {
    if (
      map.current.loaded() &&
      map.current.getLayer(`FeatureLayer${currentFeatureSelected?.id}`)
    ) {
      handleColorToSplit(currentFeatureSelected.id);
    }
    if (map.current.loaded() && !currentFeatureSelected) {
      handleColorToSplit();
    }
  }, [currentFeatureSelected]);

  useEffect(() => {
    if (currentSplitLine && currentFeatureSelected) {
      console.log({ currentFeatureSelected });
      const splitter = polygonsplitter(
        currentFeatureSelected.geometry,
        currentSplitLine
      );
      if (splitter?.geometry?.coordinates[1]) {
        const updatedMainZone = {
          ...currentFeatureSelected,
          geometry: {
            type: 'Polygon',
            coordinates: splitter?.geometry?.coordinates[0],
          },
        };
        updatedMainZone.ha = calculateArea(updatedMainZone);
        updatedMainZone.properties.area = calculateArea(updatedMainZone);

        const separatedParts = [];
        splitter?.geometry?.coordinates.forEach((part, index) => {
          if (index !== 0) {
            const partArea = {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: part,
              },
              properties: {
                fill: updatedMainZone.properties.fill,
                SampleID: 'N/D',
              },
            };
            const id = `${updatedMainZone.id}_part_${uuid()}`;
            const {
              path,
              width: partWidth,
              height: partHeight,
            } = generateSvgFromGeoJson(partArea);

            separatedParts.push({
              type: 'Feature',
              ha: calculateArea(partArea),
              width: partWidth,
              height: partHeight,
              path,
              id,
              geometry: {
                type: 'Polygon',
                coordinates: part,
              },
              properties: {
                id,
                area: calculateArea(partArea),
                elementoValor: 0,
                fill: updatedMainZone.properties.fill,
                color: updatedMainZone.properties.fill,
                zonaManejo: 'N/D',
              },
            });
          }
        });
        const layers = map.current.getStyle();

        // removendo o layer antigo
        map.current.removeLayer(`FeatureLayer${updatedMainZone.id}`);
        map.current.removeLayer(`line_FeatureLayer${updatedMainZone.id}`);

        map.current.removeSource(`Feature_${updatedMainZone.id}`);

        // adicioando o layer principal
        const geoJsonPropertyFormatted = {
          type: 'FeatureCollection',
          features: [updatedMainZone],
        };
        map.current.addSource(`Feature_${updatedMainZone.id}`, {
          type: 'geojson',
          data: geoJsonPropertyFormatted,
        });
        map.current.addLayer({
          id: `FeatureLayer${updatedMainZone.id}`,
          type: 'fill',
          source: `Feature_${updatedMainZone.id}`,
          paint: {
            'fill-color': '#d9e8ec',
            'fill-opacity': 0.5,
            'fill-outline-color': '#3bb2d0',
          },
        });
        map.current.addLayer({
          id: `line_FeatureLayer${updatedMainZone.id}`,
          type: 'line',
          source: `Feature_${updatedMainZone.id}`,
          layout: {},
          paint: {
            'line-color': '#3bb2d0',
            'line-width': 2,
          },
        });

        // adicionando as partes separadas
        separatedParts.forEach((part) => {
          const geoJsonPartPropertyFormatted = {
            type: 'FeatureCollection',
            features: [part],
          };
          map.current.addSource(`Feature_${part.id}`, {
            type: 'geojson',
            data: geoJsonPartPropertyFormatted,
          });
          map.current.addLayer({
            id: `FeatureLayer${part.id}`,
            type: 'fill',
            source: `Feature_${part.id}`,
            paint: {
              'fill-color': '#d9e8ec',
              'fill-opacity': 0.5,
              'fill-outline-color': '#3bb2d0',
            },
          });
          map.current.addLayer({
            id: `line_FeatureLayer${part.id}`,
            type: 'line',
            source: `Feature_${part.id}`,
            layout: {},
            paint: {
              'line-color': '#3bb2d0',
              'line-width': 2,
            },
          });
        });

        setSplittedZones({ mainZone: updatedMainZone, parts: separatedParts });
        console.log({ updatedMainZone });
        console.log({ separatedParts });
      } else {
        setCurrentSplitLine(null);
      }
    }
  }, [currentFeatureSelected, currentSplitLine]);

  useEffect(() => {
    // map draw
    map.current.on('draw.create', (e) => {
      const { features } = mapDraw.getAll();
      handleChange(features);
      handleOpenTalhaoNameModal();
      if (features.at(-1).geometry.type === 'LineString') {
        setCurrentSplitLine(features.at(-1));
        mapDraw.delete(features.at(-1).id);
      }
    });

    map.current.on('click', (e) => {
      const { features } = mapDraw.getSelected();
      if (features.length && features[0].geometry.type !== 'LineString') {
        handleColorToSplit(features[0].id);
        setCurrentFeatureSelected(features[0]);
      }
    });

    map.current.on('draw.combine', () => {
      const { features } = mapDraw.getAll();
      handleChange(features);
    });

    map.current.on('draw.uncombine', (e) => {
      const { features } = mapDraw.getAll();

      handleChange(features);
    });

    map.current.on('draw.delete', () => {
      const { features } = mapDraw.getAll();

      handleChange(features);
    });

    map.current.on('draw.update', (event) => {
      const { features } = mapDraw.getAll();
      if (Number(event?.features[0]?.properties?.id) === selectedTalhao?.id) {
        const newGeoJson = geoJson.features.map((feature) => {
          if (feature.id === setClickedZone.id) {
            return clickedZone;
          }
          return feature;
        });
        mapDraw.set({ type: 'FeatureCollection', features: newGeoJson });
        enqueueSnackbar('Edição do talhão bloqueada', { variant: 'error' });
      } else {
        handleChange(features);
      }
    });
  }, [updateMap]);

  useEffect(() => {
    if (typeof deletedZone === 'number' && paintOnClick) {
      const oldArr = featuresClicked;
      const selectedItem = featuresClicked[deletedZone];

      map.current.setFeatureState(
        { source: selectedItem.source, id: selectedItem.id },
        { clicked: false }
      );
      const newArr = oldArr.filter((item) => item.id !== selectedItem.id);

      setFeaturesClicked(newArr);
      setDeletedZone(null);
    }
  }, [deletedZone]);

  const cleanMap = async () => {
    await Promise.all(
      arrayLayerIdString.current.map(async (item) => {
        return map.current.removeLayer(item);
      })
    );

    await Promise.all(
      arraySourceIdString.current.map(async (item) => {
        return map.current.removeSource(item);
      })
    );

    arrayLayerIdString.current = [];
    arraySourceIdString.current = [];
  };

  const handleCheckOption = async (option) => {
    if (viewportStyle !== option) {
      setViewportStyle(option);
      // cleanMap();
      const getPrevFeatures = [...geoJson.features];
      await map.current.setStyle(option);
      setGeoJson({ type: 'FeatureCollection', features: getPrevFeatures });
      setUpdateMap((prevState) => !prevState);
    }
  };

  useEffect(() => {
    if (currentItemZoom) {
      handleZoomOnFeature(currentItemZoom);
      setCurrentItemZoom(null);
    }
  }, [currentItemZoom]);

  useEffect(() => {
    map.current.on('style.load', function () {
      if (talhaoToClone) {
        const layerID = `FeatureLayer${talhaoToClone.id}`;
        const geoJsonPropertyFormatted = {
          type: 'FeatureCollection',
          features: [{ id: talhaoToClone.id, ...talhaoToClone }],
        };
        map.current.addSource(`Feature_${talhaoToClone.id}`, {
          type: 'geojson',
          data: geoJsonPropertyFormatted,
        });
        map.current.addLayer({
          id: layerID,
          type: 'fill',
          source: `Feature_${talhaoToClone.id}`,
          paint: {
            'fill-color': '#be3f0c',
            'fill-opacity': 0.5,
            'fill-outline-color': '#3bb2d0',
          },
        });

        //#d9e8ec

        map.current.addLayer({
          id: `line_${layerID}`,
          type: 'line',
          source: `Feature_${talhaoToClone.id}`,
          layout: {},
          paint: {
            'line-color': '#3bb2d0',
            'line-width': 2,
          },
        });
        setTalhaoToClone(null);
      }
    });
  }, [talhaoToClone]);

  return (
    <Card
      width={width}
      height={height}
      style={{ marginBottom: '0' }}
      padding="0px"
      {...props}
    >
      {/* {hasOption && (
        <OptionsWrapper id="options" style={{ ...optionsWrapperStyles }}>
          {(hasViewportOptions || hasViewportOptionLight) && (
            <Option>
              <OptionImage
                checked={
                  viewportStyle ===
                  'mapbox://styles/thesoilcompany/cl1cxlp02000216p8pno155y7/'
                }
                image={light}
              >
                <StyledInput
                  id="light"
                  type="radio"
                  name="rtoggle"
                  value="light"
                  checked={
                    viewportStyle ===
                    'mapbox://styles/thesoilcompany/cl1cxlp02000216p8pno155y7/'
                  }
                  onClick={() =>
                    handleCheckOption(
                      'mapbox://styles/thesoilcompany/cl1cxlp02000216p8pno155y7/'
                    )
                  }
                />
              </OptionImage>
              <Description htmlFor="light">light</Description>
            </Option>
          )}

          {(hasViewportOptions || hasViewportOptionDark) && (
            <Option>
              <OptionImage
                checked={viewportStyle === 'mapbox://styles/mapbox/dark-v10'}
                image={dark}
              >
                <StyledInput
                  id="dark-v10"
                  type="radio"
                  name="rtoggle"
                  value="dark"
                  checked={viewportStyle === 'mapbox://styles/mapbox/dark-v10'}
                  onClick={() =>
                    handleCheckOption('mapbox://styles/mapbox/dark-v10')
                  }
                />
              </OptionImage>
              <Description htmlFor="dark-v10">dark</Description>
            </Option>
          )}

          {(hasViewportOptions || hasViewportOptionSatellite) && (
            <Option>
              <OptionImage
                checked={
                  viewportStyle ===
                  'mapbox://styles/mapbox/satellite-streets-v11'
                }
                image={satelite}
              >
                <StyledInput
                  id="satellite-streets-v11"
                  type="radio"
                  name="rtoggle"
                  value="satellite"
                  checked={viewportStyle === 'satellite-streets-v11'}
                  onClick={() =>
                    handleCheckOption(
                      'mapbox://styles/mapbox/satellite-streets-v11'
                    )
                  }
                />
              </OptionImage>
              <Description htmlFor="satellite-streets-v11">
                satélite
              </Description>
            </Option>
          )}

          {(hasViewportOptions || hasViewportOptionStreet) && (
            <Option>
              <OptionImage
                checked={viewportStyle === 'mapbox://styles/mapbox/streets-v11'}
                image={ruas}
              >
                <StyledInput
                  id="streets-v11"
                  type="radio"
                  name="rtoggle"
                  value="streets"
                  checked={
                    viewportStyle === 'mapbox://styles/mapbox/streets-v11'
                  }
                  onClick={() =>
                    handleCheckOption('mapbox://styles/mapbox/streets-v11')
                  }
                />
              </OptionImage>
              <Description htmlFor="streets-v11">ruas</Description>
            </Option>
          )}
        </OptionsWrapper>
      )} */}
      <StyledMap ref={mapContainer} height="100%" style={styleMap} />
    </Card>
  );
};

MapWithLayersManagementZones.defaultProps = {
  hasLayer: false,
  hasCollectionLayer: false,
  handleChange: () => {},
  getMapDrawFunctions: () => {},
  handleOpenTalhaoNameModal: () => {},
  width: '100%',
  height: 'calc(100% - 10px)',
  geoJson: {},
  hasSearchInput: false,
  hasDrawOptions: false,
  styleMap: {},
  paintOnClick: false,
  deletedZone: null,
  setDeletedZone: () => null,
  callBackWhenClickLayer: () => {},
};

export default MapWithLayersManagementZones;
