import React from 'react';
import Lottie from 'react-lottie-player';

// Assets
import WhiteLoading from '@assets/lottie/white-loading.json';

// Theme
import theme from '@styles/theme';

// Style
// eslint-disable-next-line import/no-cycle
import { Wrapper, StyledButton } from './style';

export const ModalConfirm = ({ onCancel, onConfirm, isLoading }) => {
  return (
    <Wrapper>
      <StyledButton
        border="none"
        width="100%"
        boxShadow="none"
        type="submit"
        borderHover="0"
        onClick={onCancel}
        backgroundColor={theme.colors.darkerGray}
      >
        Não
      </StyledButton>
      <StyledButton
        border="none"
        width="100%"
        boxShadow="none"
        type="submit"
        borderHover="0"
        onClick={onConfirm}
      >
        {isLoading ? (
          <Lottie
            loop
            animationData={WhiteLoading}
            play
            speed={2.5}
            style={{ width: '40px', height: '40px' }}
          />
        ) : (
          'Sim'
        )}
      </StyledButton>
    </Wrapper>
  );
};
