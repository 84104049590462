import React, { useRef, useState, useEffect } from 'react';
import mapboxgl from '@services/MapBox';
import { useNavigate } from 'react-router-dom';
import {
  StyledMap,
  ButtonToNewRecommendations,
  ButtonToCollections,
} from './style';
import Card from '../Card';
import Button from '../Button';

const MapComponent = ({
  width,
  height,
  hasButtonToNewRecommendations = false,
  hasButtonToCollections = false,
  hasButtonToSoilAnalysis = false,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  // const refCard = useRef(null);
  // const [heightCard, setHeightCard] = useState(0);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  const navigate = useNavigate();

  const goToNewRecommendations = () => {
    navigate('/novasrecomendacoes');
  };

  const goToCollections = () => {
    navigate('/nova-coleta');
  };

  const goToSoilAnalysis = () => {
    navigate('/soil-analysis');
  };

  useEffect(() => {
    if (map?.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/thesoilcompany/cl1cxlp02000216p8pno155y7/',
      center: [lng, lat],
      zoom,
    });
  });

  return (
    <Card
      width={width}
      height={height}
      style={{ marginBottom: '0' }}
      padding="0px"
    >
      <StyledMap ref={mapContainer} height="100%" />
      {hasButtonToCollections && (
        <ButtonToCollections
          type="button"
          borderHover="0"
          border="none"
          width="8%"
          onClick={goToCollections}
        >
          Coletas
        </ButtonToCollections>
      )}
      {hasButtonToSoilAnalysis && (
        <ButtonToCollections
          type="button"
          borderHover="0"
          border="none"
          width="13%"
          onClick={goToSoilAnalysis}
        >
          Análise de Solo
        </ButtonToCollections>
      )}
      {hasButtonToNewRecommendations && (
        <ButtonToNewRecommendations
          type="button"
          borderHover="0"
          border="none"
          width="13%"
          onClick={goToNewRecommendations}
        >
          Novas Recomendações
        </ButtonToNewRecommendations>
      )}
    </Card>
  );
};

MapComponent.defaultProps = {
  width: '100%',
  height: 'calc(80vh - 100px',
};

export default MapComponent;
