import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 360px;
`;

export const WrapperScores = styled.div`
  flex: 1;
`;

export const WrapperRadialBar = styled.div`
  width: fit-content;
`;
