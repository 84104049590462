import React from 'react';

import {
  Wrapper,
  StyledRange,
  ULStyled,
  LIStyled,
  LIStyledText,
} from './style';

const InputRange = ({
  min,
  max,
  step,
  isVertical,
  listOptions,
  widthInput,
  heightInput,
  widthContainer,
  setValue,
  defaultValue,
  ...props
}) => {
  return (
    <Wrapper
      widthContainer={widthContainer}
      props={props}
      isVertical={isVertical}
    >
      <StyledRange
        type="range"
        min={min}
        max={max}
        step={step}
        isVertical={isVertical}
        heightInput={heightInput}
        widthInput={widthInput}
        onChange={(e) => setValue(listOptions[e.target.value - 1])}
        defaultValue={defaultValue}
      />
      <ULStyled>
        {listOptions.map((item, idx) => (
          <LIStyled key={item.label} value={idx + 1} isVertical={isVertical}>
            <LIStyledText isVertical={isVertical}>{item.label}</LIStyledText>
          </LIStyled>
        ))}
      </ULStyled>
    </Wrapper>
  );
};

InputRange.defaultProps = {
  widthContainer: 500,
  widthInput: 200,
  heightInput: 50,
  defaultValue: 1,
  min: 1,
  max: 5,
  step: 1,
  isVertical: false,
  listOptions: [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
  ],
};

export default InputRange;
