import styled from 'styled-components';

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => (!props.isFarmProducer ? '15px' : '.8rem')};
  cursor: pointer;
`;

export const ScoreTextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: space-between;
`;

export const ScoreText = styled.span`
  font-weight: 600;
`;

export const ScoreBarWrapper = styled.div`
  height: 7px;
  width: 60%;
`;

export const ScoreBarScheleton = styled.div`
  position: relative;
  float: left;
  height: 7px;
  width: 100%;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.mediumGray};
`;

export const ScoreBarProgress = styled.div`
  position: relative;
  height: 7px;
  width: ${({ progress }) => `calc(100% - (100% - ${progress}))`};
  border-radius: 10px;
  background: ${({ color }) => `#${color}`};
`;
