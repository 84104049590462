import styled, { css } from 'styled-components';
import theme from '@styles/theme';

// Components
import Input from '../Input';
import DropDownMenu from '../DropDownMenu';
import Modal from '../Modal';

export const Wrapper = styled.header`
  width: 100%;
  margin-top: 24px;
  margin-bottom: ${(props) => props.marginBottom};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.farm &&
    css`
      @media (max-width: 620px) {
        flex-direction: column-reverse;
        margin-top: 0;
      }
    `}
`;

export const LeftWrapper = styled.div`
  width: 100%;

  ${(props) =>
    props.farm &&
    css`
      display: flex;
      width: 90%;

      @media (max-width: 990px) {
        flex-direction: column;
      }
    `};
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 620px) {
    flex-direction: column;
    gap: 8px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const WrapperOverTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
    margin-left: 6px;
    width: 16px;
    height: 16px;
  }
`;

export const StyledInput = styled(Input).attrs({
  height: '50px',
  background: theme.colors.lightGray,
})`
  @media (max-width: 480px) {
    height: 40px;
  }
`;

export const IconButton = styled.button`
  border: 0;
  background: transparent;

  svg {
    width: 40px;
    height: 40px;
  }

  svg path {
    stroke: ${(props) => props?.stroke};
    fill: ${(props) => props?.fill};
  }

  :not(:last-child) {
    margin-right: 20px;
  }
`;

export const ImageWrapper = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const ButtonsWrapper = styled.div`
  margin-left: 30px;
  display: flex;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: 1rem;
  min-width: 200px;

  @media (max-width: 990px) {
    margin-left: 0;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5rem;
`;

export const FarmIcon = styled.div`
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const UDPIcon = styled.div`
  svg {
    width: 35px;
    height: 35px;
  }
`;

export const TextDetails = styled.p`
  margin-left: 0.5rem;
  font-size: 1.5rem;
  color: ${theme.colors.fadedIcon};
`;

export const FarmDetails = styled.p`
  font-size: 1rem;
  width: fit-content;
  white-space: nowrap;
  color: ${theme.colors.fadedIcon};
`;
