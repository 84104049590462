import React, { useEffect, useState } from 'react';
import api from '@services/api';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { formatMessagesErrors } from '@utils/index';
import useAuth from '@hooks/Auth';

// Components
import Accordion from '../Accordion';
import Input from '../Input';
import Button from '../Button';

// Style
import { InputWrapper, Wrapper, Username } from './style';

export const HeaderMyUser = () => {
  return <Username>MEUS DADOS</Username>;
};

const MyUser = ({ width, handleOpenModal, handleClose }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen((prevState) => !prevState);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useAuth();
  const [user, setUser] = useState({});
  const [disabled, setDisabled] = useState(true);

  const loadUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(`/Users/GetById/${userInfo?.id}`);
      setUser(data);
    } catch (error) {
      const messages = formatMessagesErrors(error);

      messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: user.id,
      login: user.login,
      name: user.nome,
      email: user.email,
      password: '',
      userGroupId: user.usuariosGrupoIds,
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const { data } = await api.put('/Users/Alter', {
          id: values.id,
          login: values.login,
          senha: values.password,
          nome: values.name,
          email: values.email,
          usuarioGrupoId: values.userGroupId,
        });

        handleClose();
        handleOpenModal();
        enqueueSnackbar('Usuário editado com sucesso!', {
          variant: 'success',
        });
      } catch (error) {
        const messages = formatMessagesErrors(error);

        messages.forEach((message) => {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleEdit = () => {
    setDisabled((prevState) => !prevState);
  };

  const handleBack = () => {
    formik.setFieldValue('login', user.login);
    formik.setFieldValue('name', user.nome);
    formik.setFieldValue('email', user.email);
    formik.setFieldValue('password', '');
    setDisabled((prevState) => !prevState);
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <Wrapper onSubmit={formik.handleSubmit}>
      <Accordion
        keyAnimator="accordion_profile"
        title="Informações Básicas"
        width={width}
        open={open}
        handleOpen={handleOpen}
        startOpen
      >
        <InputWrapper>
          <Input
            label="Nome/Razão Social"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            width="100%"
            placeholder="Digite um nome..."
            disabled={disabled}
          />
        </InputWrapper>

        <InputWrapper>
          <Input
            label="Login"
            width="100%"
            type="login"
            placeholder="Digite um login..."
            id="login"
            name="login"
            value={formik.values.login}
            onChange={formik.handleChange}
            disabled={disabled}
          />
        </InputWrapper>

        <InputWrapper>
          <Input
            label="E-mail"
            width="100%"
            type="email"
            placeholder="Digite um email..."
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            disabled={disabled}
          />
        </InputWrapper>

        <InputWrapper>
          <Input
            label="Senha"
            width="100%"
            type="password"
            placeholder="Digite uma senha..."
            id="password"
            name="password"
            value={disabled ? '*********' : formik.values.password}
            onChange={formik.handleChange}
            disabled={disabled}
          />
        </InputWrapper>
      </Accordion>

      {disabled ? (
        <Button
          width="100%"
          borderHover="0"
          border="none"
          isLoading={isLoading}
          onClick={handleEdit}
        >
          Editar
        </Button>
      ) : (
        <>
          <Button
            width="100%"
            borderHover="0"
            border="none"
            isLoading={isLoading}
            onClick={handleBack}
          >
            Voltar
          </Button>
          <Button
            type="submit"
            width="100%"
            borderHover="0"
            border="none"
            isLoading={isLoading}
          >
            Salvar Alterações
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default MyUser;
