import styled from 'styled-components';
import Card from '../Card';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    flex-direction: row;
  }

  @media (max-width: 378px) {
    flex-direction: column;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 16px;
`;

export const Data = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(174, 174, 174, 0.15);
  border-radius: 8px;
  height: 3rem;
  /* padding: 0.5rem; */

  @media (max-width: 560px) {
    /* padding: 5px; */
    height: 2rem;
  }
`;

export const DataText = styled.h3`
  color: ${({ theme }) => theme.colors.fadedText};
  font-size: 1rem;
  width: 100px;
  text-align: center;

  @media (max-width: 560px) {
    font-size: 0.8rem;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  margin-right: 16px;
`;

export const ContentSubtitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BatchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const BatchText = styled.h3`
  color: ${({ theme }) => theme.colors.fadedText};
  font-weight: 400;
  font-size: 1rem;
  white-space: nowrap;
`;

export const StyledCard = styled(Card)`
  @media (max-width: 560px) {
    /* padding: 16px; */
  }
`;
