import styled, { keyframes } from 'styled-components';

export const CardAnimation = keyframes`
  0% {
    background-color: hsl(0, 0%, 80%);
  }
  100% {
    background-color: hsl(0, 0%, 95%);
  }
`;

export const Card = styled.div`
  opacity: 0.7;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  animation: ${CardAnimation} 1s ease infinite alternate;
`;
