import styled from 'styled-components';

export const Wrapper = styled.form`
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10px;
  padding: 20px;
  padding-top: 90px;

  @media (max-width: 480px) {
    height: calc(100% - 70px);
  }
`;

export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 700;
  align-self: center;
  font-size: 1.2rem;
`;
