export const DASHBOARD = '/';
export const SELLS = '/vendas';

// PRODUTORES RURAIS
export const FARM = '/fazenda';
export const UDP = '/udps';
export const SUPPLIES = '/insumos';
export const COLLECTIONS = '/nova-coleta';
export const TALHOES = '/talhoes';
export const MAPS = '/mapas';
export const FILES = '/arquivos';
export const USERS = '/usuarios';
export const CLIENTS = '/clientes';
export const PARTNERS = '/parceiros';
export const ANALYSIS = '/analises';
export const RECOMMENDATIONS = '/recomendacoes';
export const NEWRECOMMENDATIONS = '/novasrecomendacoes';
export const CUSTOM_RECOMMENDATIONS = '/recomendacoes-customizadas';
export const SOILRESEARCH = '/analises_de_solo';
// export const SOIL_ANALYSIS = '/soil-analysis';
export const SOIL_ANALYSIS = '/soil-analysis';
export const IMPORTORDERS = '/importarpedidos';
export const SMARTIQS = '/smartIQS';
export const MANAGEMENT_ZONES = '/zonas-manejo';
export const ZONES = '/zonas';
export const CULTURES = '/culturas';
export const HARVESTS = '/safras';
export const ELEMENTS = '/elementos';
export const SUPPLIESLIST = '/insumos-lista';
export const PACKAGES = '/pacotes';
export const COLLECTIONSLIST = '/coletas';
export const SOILORDERS = '/ordens-solo';
export const BIOMES = '/biomas';
export const LABORATORIES = '/laboratorios';

export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot';
export const REGISTER = '/register';
export const PF_REGISTERSTEP = '/register/pessoa-fisica';

// Rotas onde o token não é necessário
export const DONT_REQUIRE_TOKEN = {
  [LOGIN]: true,
  [FORGOT_PASSWORD]: true,
  [REGISTER]: true,
  [PF_REGISTERSTEP]: true,
};
