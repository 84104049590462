import React, { createContext, useState, useEffect } from 'react';

export const SoilAnalysisIdContext = createContext();

export const LOCAL_STORAGE_SOIL_ANALYSIS_ID = {
  CLIENT_ID: 'soil-analysis-client-id',
};

function getInitialState() {
  const clientIdStorage = localStorage.getItem(
    LOCAL_STORAGE_SOIL_ANALYSIS_ID.CLIENT_ID
  );
  return clientIdStorage ? JSON.parse(clientIdStorage) : 11924;
}

const SoilAnalysisIdProvider = ({ children }) => {
  const [soilAnalysisId, setSoilAnalysisId] = useState(getInitialState);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_SOIL_ANALYSIS_ID.CLIENT_ID,
      JSON.stringify(soilAnalysisId)
    );
  }, [soilAnalysisId]);

  return (
    <SoilAnalysisIdContext.Provider
      value={{ soilAnalysisId, setSoilAnalysisId }}
    >
      {children}
    </SoilAnalysisIdContext.Provider>
  );
};

export default SoilAnalysisIdProvider;
