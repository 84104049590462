import React, { useContext } from 'react';
import { RecommendationContext } from '@context/Recommendation';

const useRecommendation = () => {
  const context = useContext(RecommendationContext);

  return context;
};

export default useRecommendation;
