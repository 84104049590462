import React from 'react';

// Style
import { Wrapper } from './style';

const Container = ({ children, hasScroll = true, ...props }) => {
  return (
    <Wrapper
      {...props}
      exit={{ opacity: 0, x: 0, y: 0 }}
      initial={{ opacity: 0, x: 0, y: 0 }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      transition={{
        delayChildren: 1,
        opacity: { ease: 'linear' },
        layout: { duration: 0.1 },
      }}
      hasScroll={hasScroll}
    >
      {children}
    </Wrapper>
  );
};

export default Container;
