import styled from 'styled-components';

// Assets
import { ReactComponent as Picture } from '@assets/svg/picture.svg';

export const Wrapper = styled.div`
  width: ${(props) => props.width};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.fadedText};
  font-size: ${(props) => props.fontSizeLabel};
  margin-bottom: 10px;
  font-weight: 400;
`;

export const StyledInput = styled.input`
  font-size: ${(props) => props.fontSize};
  background: ${(props) => props.background};
  width: 100%;
  opacity: 0;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.soilOrange};
  font-size: ${(props) => props.fontSizeLabel};
  margin-bottom: 10px;
  font-weight: 500;
`;

export const InputWrapper = styled.label`
  border: ${(props) => (props.hasBorder ? '1px solid #d9d9d9' : 'none')};
  border-radius: 8px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  transition: all 0.2s ease-in-out;

  :hover {
    opacity: 1;
  }
`;

export const StyledPicture = styled(Picture)`
  & path {
    fill: ${({ theme }) => theme.colors.translucentText};
  }
`;

export const TextInput = styled.span`
  color: ${({ theme }) => theme.colors.border};
  font-size: 1rem;
  line-height: 20px;
  width: 80%;
`;

export const ImageName = styled.p`
  color: ${({ theme }) => theme.colors.border};
  font-size: 1rem;
  margin-bottom: 10px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;
