import styled from 'styled-components';

export const Wrapper = styled.form`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  padding-top: 90px;

  @media (max-width: 480px) {
    height: calc(100% - 70px);
  }
`;

export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const Username = styled.p`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 700;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; */
`;
