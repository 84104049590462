import styled from 'styled-components';
import Button from '../Button';

export const StyledMap = styled.div`
  border-radius: 12px;
  height: ${({ height }) => height};
  z-index: 0;
  position: relative;

  & .mapboxgl-canvas {
    height: 100% !important;
    width: 100% !important;
    border-radius: 12px;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  z-index: 1;
  margin-bottom: 11px;
  padding: 5px;
  background: ${({ theme }) => theme.colors.darkWhite};
  padding: 10px;
  border-radius: 12px;

  @media (max-width: 480px) {
    margin-bottom: 80px;
    padding: 6px;
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 480px) {
    margin-right: 5px;
  }
`;

export const OptionImage = styled.div`
  border: 2px solid
    ${({ checked }) =>
      ({ theme }) =>
        checked ? theme.colors.soilOrange : theme.colors.darkWhite};
  border-radius: 12px;
  width: 50px;
  height: 50px;
  background-image: ${({ image }) => `url(${image})`};

  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
`;

export const Description = styled.label`
  font-size: 14px;
`;

export const StyledInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ButtonToNewRecommendations = styled(Button)`
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  z-index: 1;
`;

export const ButtonToCollections = styled(Button)`
  position: absolute;
  bottom: 3rem;
  right: calc(4rem + 13%);
  z-index: 1;
`;
