import React from 'react';

// Theme
import theme from '@styles/theme';

const CustomizedAxisYTick = ({ x, y, unit, payload }) => {
  return (
    <g>
      <text
        x={x}
        y={y}
        textAnchor="end"
        fontWeight="700"
        fill={theme.colors.inactiveText}
      >
        {payload.value}
        {unit}
      </text>
    </g>
  );
};

CustomizedAxisYTick.defaultProps = {
  x: 0,
  y: 0,
  unit: '',
  payload: {
    value: '',
  },
};

export default CustomizedAxisYTick;
