export const getMaxLenght = {
  zipcode: 8,
  telephone: 15,
  cpf: 11,
  cnpj: 14,
};

export function ZipCodeMask(e) {
  let value = e;
  const regex = /^([0-9]{5})?([0-9]{3})$/;
  value = value.replace(/\D/g, '');
  value = value.replace(regex, '$1-$2');

  if (value.replace(/\D/g, '').length <= getMaxLenght.zipcode) return value;
  return false;
}

export function TelephoneMask(e) {
  let value = e;

  value = value.replace(/\D/g, '');
  const regex = /^([0-9]{2})?([0-9]{1})?([0-9]{4})?([0-9]{4})$/;

  // CASO 1: 44 9 9999-9999
  if (value.length > 10) {
    value = value.substring(0, 11);
    value = value.replace(regex, '($1) $2 $3-$4');
  }

  // CASO 2: 44 3030-3030
  if (value.length === 10) {
    value = value.replace(regex, '($1) $3-$4');
  }

  if (value.replace(/\D/g, '').length <= getMaxLenght.telephone) return value;
  return false;
}

export function CPFMask(e) {
  const regex = /^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/;

  let value = e;
  value = value.replace(/\D/g, '');
  value = value.replace(regex, '$1.$2.$3-$4');

  if (value.replace(/\D/g, '').length <= getMaxLenght.cpf) return value;
  return false;
}

export function CPNJMask(e) {
  const regex = /^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})$/;
  let value = e;
  value = value.replace(/\D/g, '');

  value = value.replace(regex, '$1.$2.$3/$4-$5');

  if (value.replace(/\D/g, '').length <= getMaxLenght.cnpj) return value;
  return false;
}

export function DecimalMask(e) {
  let value = e;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  return value;
}
