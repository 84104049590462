import styled from 'styled-components';

export const UserWrapper = styled.div`
  overflow: hidden;
  display: flex;
`;

export const UserPhoto = styled.div``;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 8px;
`;

export const Photo = styled.img`
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 12px;
`;

export const WrapperSubTexts = styled.div`
  display: flex;
  align-items: center;
  div {
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.fadedText};
    border-radius: 50%;
    margin: 0 7px;
  }
`;

export const PositionWrapper = styled.div`
  margin-top: 20px;
  /* display: flex;
  justify-content: center; */
`;

export const WrapperNameTitle = styled.div`
  display: inline-grid;
`;
export const Divider = styled.div`
  margin-top: 16px;
  height: 2px;
  width: 100%;
  margin-bottom: 8px;
  background: ${({ theme }) => theme.colors.lightGray};
`;

export const Wrapper = styled.div`
  padding: ${(props) => (props.hasTitle ? '24px 24px 0 24px' : '0 24px')};
`;

export const IconsWrappper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 8px;
`;

export const Icons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;
